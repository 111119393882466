import PhoneModalComponent from "../update/phone/PhoneModalComponent";
import DeleteUserModalComponent from "./DeleteUserModalComponent";


export default function DeleteUserModal(props){

    const {open, close, header,data,sectionType } = props;

    return(
        <>
            <div className={open ? 'openModal modal' : 'modal'}>
                {open ? (
                    <>
                        {sectionType === "mobile" ? (
                            <>
                                <sectionMobile style={{marginTop:50}}>
                                    <header>
                                        {header}
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                    </header>
                                    <main style={{alignItems: "center"}}><DeleteUserModalComponent style={{alignItems: "center"}}
                                                                                                   data={data} sectionType="mobile" /></main>
                                    <footer>
                                        <button className="close" onClick={close}>
                                            닫기
                                        </button>
                                    </footer>
                                </sectionMobile>
                            </>
                        ):(
                            <>
                                <section style={{marginTop:50}}>
                                    <header>
                                        {header}
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                    </header>
                                    <main style={{alignItems: "center"}}><DeleteUserModalComponent style={{alignItems: "center"}} data={data}/></main>
                                    <footer>
                                        <button className="close" onClick={close}>
                                            닫기
                                        </button>
                                    </footer>
                                </section>
                            </>
                        ) }

                    </>
                ) : null}
            </div>
        </>
    )

}