import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {useEffect, useState} from "react";
import Today from "./graph/Today";
import Week from "./graph/Week";
import Month from "./graph/Month";
import {Typography} from "@mui/material";
import Logo from "../../../picture/fastStroke.png";
import {Desktop, Mobile, Tablet} from "../../../Responsive";




export default function Index(){

    const [currentClick,setCurrentClick] = useState(null)
    const [prevClick, setPrevClick] = useState(null)
    const [day, setDay] = useState(false)
    const [week, setWeek] = useState(false)
    const [month, setMonth] = useState(false)

    const getClick = (e)=>{
        e.preventDefault()
        setCurrentClick(e.target.id);
    }

    const homeClick = (e)=>{
        e.preventDefault()
        window.location.replace('/reports')
    }

    useEffect((e)=>{
        if(currentClick !== null){
            let current = document.getElementById(currentClick)
            current.style.color = '#bebcbc';
            current.style.backgroundColor = '#c7ebff'
            if(current.id === 'today'){
                setDay(true)
                setWeek(false)
                setMonth(false)
            }else{
                if(current.id === 'week'){
                    setDay(false)
                    setWeek(true)
                    setMonth(false)
                }else if(current.id === 'month'){
                    setDay(false)
                    setWeek(false)
                    setMonth(true)
                }else{

                }
            }
        }
        if(prevClick !== null){
            let prev = document.getElementById(prevClick)
            prev.style.color = 'white'
            prev.style.backgroundColor = '#1976D2'
        }
        setPrevClick(currentClick)
    },[currentClick])


    return(
        <>
            <Desktop>
                <div style={{alignItems:'center',display:'flex',flexDirection:'column', marginTop:40}}>
                    <Button variant="contained" onClick={homeClick}> Report Home </Button>
                    <br/><br/>

                    <div style={{marginTop:10}}>
                        <ButtonGroup variant="contained" aria-label="outlined primary button group">
                            <Button id='today' onClick={getClick}>일간 뇌졸증 수치</Button>
                            <Button id='week' onClick={getClick}>주간 뇌졸증 수치</Button>
                            <Button id='month' onClick={getClick}>월간 뇌졸증 수치</Button>
                        </ButtonGroup>
                    </div>

                    <div style={{alignItems:'center',display:'flex',flexDirection:'column',marginTop:50}}>
                        {day ? (
                            <>
                                <Today />
                            </>
                        ):(<>
                            {week ? (
                                <>
                                    <Week />
                                </>
                            ):(
                                <>
                                    {month ? (
                                        <>
                                            <Month />
                                        </>
                                    ):(
                                        <>
                                            <Typography component="h1" variant="h5" sx={{marginTop:1}}>
                                                뇌졸증 수치 Report
                                            </Typography><br/>

                                            <img alt="No Images" src={Logo} style={{marginTop:30,width:850,height:500}}/>
                                            <br/><br/><br/>
                                        </>
                                    )}

                                </>
                            )}
                        </>)}
                    </div>
                </div>
            </Desktop>
            <Tablet>
                <div style={{alignItems:'center',display:'flex',flexDirection:'column', marginTop:40}}>
                    <Button variant="contained" onClick={homeClick}> Report Home </Button>
                    <br/><br/>

                    <div style={{marginTop:10}}>
                        <ButtonGroup variant="contained" aria-label="outlined primary button group">
                            <Button id='today' onClick={getClick}>일간 뇌졸증 수치</Button>
                            <Button id='week' onClick={getClick}>주간 뇌졸증 수치</Button>
                            <Button id='month' onClick={getClick}>월간 뇌졸증 수치</Button>
                        </ButtonGroup>
                    </div>

                    <div style={{alignItems:'center',display:'flex',flexDirection:'column',marginTop:50}}>
                        {day ? (
                            <>
                                <Today />
                            </>
                        ):(<>
                            {week ? (
                                <>
                                    <Week />
                                </>
                            ):(
                                <>
                                    {month ? (
                                        <>
                                            <Month />
                                        </>
                                    ):(
                                        <>
                                            <Typography component="h1" variant="h5" sx={{marginTop:1}}>
                                                뇌졸증 수치 Report
                                            </Typography><br/>

                                            <img alt="No Images" src={Logo} style={{marginTop:30,width:560,height:400}}/>
                                        <br/><br/>
                                        </>
                                    )}

                                </>
                            )}
                        </>)}
                    </div>

                </div>
            </Tablet>
            <Mobile>
                <div style={{alignItems:'center',display:'flex',flexDirection:'column', marginTop:40}}>
                    <Button variant="contained" onClick={homeClick}> Report Home </Button>
                    <br/><br/>

                    <div style={{marginTop:10}}>
                        <ButtonGroup variant="contained" aria-label="outlined primary button group">
                            <Button id='today' onClick={getClick}>일간 수치</Button>
                            <Button id='week' onClick={getClick}>주간 수치</Button>
                            <Button id='month' onClick={getClick}>월간 수치</Button>
                        </ButtonGroup>
                    </div>

                    <div style={{alignItems:'center',display:'flex',flexDirection:'column',marginTop:50}}>
                        {day ? (
                            <>
                                <Today />
                            </>
                        ):(<>
                            {week ? (
                                <>
                                    <Week />
                                </>
                            ):(
                                <>
                                    {month ? (
                                        <>
                                            <Month />
                                        </>
                                    ):(
                                        <>
                                            <Typography component="h1" variant="h5" sx={{marginTop:1}}>
                                                뇌졸증 수치 Report
                                            </Typography><br/>

                                            <img alt="No Images" src={Logo} style={{marginTop:30,width:300,height:250}}/>
                                            <br/><br/>
                                        </>
                                    )}

                                </>
                            )}
                        </>)}
                    </div>

                </div>
            </Mobile>

        </>
    )
}