import React from "react";
import Background from "../../picture/back.jpeg";
import {Button, Typography} from "@mui/material";

export default function RequiredAdmin(){
    return(
        <>
            <div style={{backgroundImage:`url(${Background})`,
                backgroundSize:"cover", padding:360}}>
                <div style={{display:"flex",alignItems:'center',flexDirection:'column'}}>
                    <Typography color="black" component="h2" variant="h7" align="left" sx={{
                        display: 'flex',
                        float: 'left',

                    }}>
                        잘못된 경로 입니다....
                    </Typography><br/>
                    <Button
                        variant='contained'
                        href='/'
                    >
                        <Typography color="whitesmoke" component="h2" variant="h5" align="left" sx={{
                            marginTop:0.5,
                            display: 'flex',
                            float: 'left'
                        }}>
                            홈으로
                        </Typography>
                    </Button>
                </div>
            </div>
        </>
    )
}