import AdminUpdateModalComponent from "../AdminUpdate/AdminUpdateModalComponent";
import React from "react";
import StartupUpdateModalComponent from "./StartupUpdateModalComponent";


export default function StartupUpdateModal(props){

    const {open, close, header,data,sizeType } = props;

    return(
        <>
            <div className={open ? 'openModal modal' : 'modal'}>
                {open ? (
                    <>
                        {sizeType === 'mobile' ? (
                            <>
                                <sectionMobile style={{marginTop:50}}>
                                    <header>
                                        {header}
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                    </header>
                                    <main style={{alignItems: "center"}}><StartupUpdateModalComponent style={{alignItems: "center"}}
                                                                                                      data={data} sizeType="mobile"/>
                                    </main>
                                    <footer>
                                        <button className="close" onClick={close}>
                                            닫기
                                        </button>
                                    </footer>
                                </sectionMobile>
                            </>
                        ):(
                            <>
                                <section style={{marginTop:50}}>
                                    <header>
                                        {header}
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                    </header>
                                    <main style={{alignItems: "center"}}><StartupUpdateModalComponent style={{alignItems: "center"}} data={data}/></main>
                                    <footer>
                                        <button className="close" onClick={close}>
                                            닫기
                                        </button>
                                    </footer>
                                </section>
                            </>
                        )}

                    </>
                ) : null}
            </div>
        </>
    )
}