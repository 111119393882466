import React, {useState} from "react";
import {Button} from "@mui/material";
import StartupUpdateModal from "../../modals/StartUpUpdate/StartupUpdateModal";


export default function AdminStartupChanger(props){

    const {selectedData,sizeType }= props

    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }

    return(
        <>

            <Button
                variant="contained"
                size="large"
                onClick={openModal}
                sx={{marginRight:2}}
                disabled={selectedData.length < 1}
            >
                개통변경
            </Button>
            {modalOpen && <>
                {sizeType === 'mobile' ? (
                    <>
                        <StartupUpdateModal open={modalOpen} close={closeModal} header="개통변경"
                                            data={selectedData} sizeType="mobile" />
                    </>
                ):(
                    <>
                        <StartupUpdateModal open={modalOpen} close={closeModal} header="개통변경" data={selectedData} />
                    </>
                )}
            </>}
        </>
    )
}