import React from 'react'
import '../../../../../../styles/modalStyle.css'
import UserIdModalComponent from "./UserIdModalComponent";




export default function UpdateUserIdModal(props){

    const {open, close, header,data,sectionType } = props;

    return(
        <>
            <div className={open ? 'openModal modal' : 'modal'}>
                {open ? (
                    <>
                        {sectionType === 'mobile' ? (
                            <>
                                <sectionMobile style={{marginTop:50}}>
                                    <header>
                                        {header}
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                    </header>
                                    <main style={{alignItems: "center"}}><UserIdModalComponent style={{alignItems: "center"}}
                                                                                               data={data} sectionType="mobile" />
                                    </main>
                                    <footer>
                                        <button className="close" onClick={close}>
                                            닫기
                                        </button>
                                    </footer>
                                </sectionMobile>
                            </>
                        ):(
                            <>
                                <section style={{marginTop:50}}>
                                    <header>
                                        {header}
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                    </header>
                                    <main style={{alignItems: "center"}}><UserIdModalComponent style={{alignItems: "center"}} data={data}/></main>
                                    <footer>
                                        <button className="close" onClick={close}>
                                            닫기
                                        </button>
                                    </footer>
                                </section>
                            </>
                        )}

                    </>
                ) : null}
            </div>
        </>
    )
}