import React, {useContext} from 'react'
import styled from "styled-components";
import {Typography} from "@mui/material";
import {MainContext} from "../../context";
import {Desktop, Mobile, Tablet} from "../../Responsive";


const Common = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  background-color: whitesmoke;
  flex-direction: column;
`


export default function Footer(){

    const { toggleClose } = useContext(MainContext)

    return(
        <>
            <Desktop>
            <Common>
                <div style={{display: 'flex',
                    alignItems: "center"}} onClick={toggleClose}>
                    <img alt="No Images" src="images/new_blaubit.png"
                         style={{
                             alignItems:'center', display:'flex',padding:5,width:250
                         }}/>
                    <div>
                        <Typography  component="h5" variant="h7" color="grey" style={{padding:10}}>
                            대표전화 031-605-7906
                        </Typography>
                        <Typography  component="h5" variant="h7" color="grey" style={{padding:10}}>
                            주소: 경기도 성남시 수정구 창업로 54, 704호,705호
                        </Typography>
                    </div>
                </div>
            </Common>
            </Desktop>
            <Tablet>
                <div style={{display: 'flex',
                    alignItems: "center"}} onClick={toggleClose}>
                    <img alt="No Images" src="images/new_blaubit.png"
                         style={{
                             alignItems:'center', display:'flex',padding:20,width:250
                         }}/>
                    <div>
                        <Typography  component="h5" variant="h7" color="grey" style={{padding:10}}>
                            대표전화 031-605-7906
                        </Typography>
                        <Typography  component="h5" variant="h7" color="grey" style={{padding:10}}>
                            주소: 경기도 성남시 수정구 창업로 54, 704호,705호
                        </Typography>
                    </div>
                </div>
            </Tablet>
            <Mobile>
                <div style={{display: 'flex',
                    alignItems: "center",flexDirection:'column'}} onClick={toggleClose}>
                    <img alt="No Images" src="images/new_blaubit.png"
                         style={{
                             alignItems:'center', display:'flex',padding:5,width:150
                         }}/>
                    <div>
                        <Typography  component="h5" variant="h8" color="grey" style={{padding:10}}>
                            대표전화 031-605-7906
                        </Typography>
                        <Typography  component="h5" variant="h8" color="grey" style={{padding:10}}>
                            주소: 경기도 성남시 수정구 창업로 54, 704호,705호
                        </Typography>
                    </div>
                </div>
            </Mobile>
        </>
    )
}