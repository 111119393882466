import {Button} from "@mui/material";
import React, {useState} from "react";
import InfoUpdateModal from "../../modals/update/infoUpdateModal";
import AdminUpdateModal from "../../modals/AdminUpdate/AdminUpdateModal";


export default function AdminChanger(props){

    const {selectedData,sizeType }= props

    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }

    return(
        <>

                <>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={openModal}
                        sx={{marginRight:2}}
                        disabled={selectedData.length < 1}
                    >
                        관리자변경
                    </Button>
                    {modalOpen && <>
                        {sizeType === 'mobile' ? (
                            <>
                                <AdminUpdateModal open={modalOpen} close={closeModal} header="관리자변경"
                                                  data={selectedData} sizeType="mobile" />
                            </>
                        ):(
                            <>
                                <AdminUpdateModal open={modalOpen} close={closeModal} header="관리자변경"
                                                  data={selectedData} />
                            </>
                        )}
                    </> }
                </>


        </>
    )
}