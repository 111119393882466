import React, {useEffect, useRef, useState} from "react";
import {Box, Typography} from "@mui/material";
import {Desktop, Mobile, Tablet} from "../../../Responsive";


export default function Count(props){

    const {count,setCount} =props

    let [startCount, setStartCount] = useState(5)

    const savedCallback = useRef()

    function callback(){
        setStartCount(startCount - 1)
    }
    useEffect(()=>{
        savedCallback.current = callback;
    })
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        let id = setInterval(tick, 1000);

        if(startCount <= -1){
            clearInterval(id);
        }

    }, []);
    useEffect(()=>{
        if(startCount <= -1){
            setCount(true)
        }
    },[startCount])

    return(
        <>
            <Desktop>
                <Box
                    sx={{
                        backgroundColor:'white',
                        width:850,
                        border:1,
                        borderRadius:'2rem',
                        margin:5,
                        display:'flex',
                        alignItems:'center',
                        flexDirection:'column'
                    }}>
                    <Typography component="h1" variant="h1" sx={{padding:30}}>
                        {startCount}
                    </Typography>
                </Box>
            </Desktop>
            <Tablet>
                <Box
                    sx={{
                        backgroundColor:'white',
                        width:500,
                        border:1,
                        borderRadius:'2rem',
                        margin:5,
                        display:'flex',
                        alignItems:'center',
                        flexDirection:'column'
                    }}>
                    <Typography component="h1" variant="h1" sx={{padding:20}}>
                        {startCount}
                    </Typography>
                </Box>
            </Tablet>
            <Mobile>
                <Box
                    sx={{
                        backgroundColor:'white',
                        width:330,
                        border:1,
                        borderRadius:'2rem',
                        margin:5,
                        display:'flex',
                        alignItems:'center',
                        flexDirection:'column'
                    }}>
                    <Typography component="h1" variant="h1" sx={{padding:20}}>
                        {startCount}
                    </Typography>
                </Box>
            </Mobile>

        </>
    )
}