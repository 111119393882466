import {Button} from "@mui/material";
import React from "react";
import * as XLSX from "xlsx";
import moment from "moment-timezone";
import {excelDownload} from "../../../../api";


export default function ExcelDownload(props){

    const {selectedData} =props

    const onDownloadButton = (e) => {
        e.preventDefault()

        excelDownload(selectedData)
            .then(res=>{
                const downData = res.data
                const getCurrentTime = ()=>{
                    const m =moment().tz("Asia/Seoul")
                    return m.format("YYYY.MM.DD")
                }
                const ws= XLSX.utils.json_to_sheet(downData);
                const wb= XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb,ws,`SleepCore`);
                XLSX.writeFile(wb, `SleepCore ${getCurrentTime()}.xlsx`)

            })
            .catch(err=>{
                alert(err)
                window.location.replace('/adminPage')
            })

    }

    return(
        <>
            <Button
                onClick={onDownloadButton}
                variant="contained"
                size="large"
                sx={{marginRight:2}}
                disabled={selectedData.length < 1}
            >
                다운로드(Excel)
            </Button>
        </>
        )
}