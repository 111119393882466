import React, {useContext, useEffect, useState} from 'react'
import Background from "../../picture/back.jpeg";
import Layout from "../../pages/containers/Layout";
import {Box, Button, Typography} from "@mui/material";
import Logo from "../../picture/Logo.png";
import {DataGrid} from "@mui/x-data-grid";
import { getAdminData, loginCheck, tokenDelete} from "../../api";
import RequiredAdmin from "../Required/RequiredAdmin";
import AdminInfoUpdate from "./service/Crud/AdminInfoUpdate";
import Find from "./service/Crud/Find";
import AdminUserDel from "./service/Crud/AdminUserDel";
import AdminRegister from "./service/Crud/AdminRegister";
import ExcelUpload from "./service/Excel/ExcelUpload";
import ExcelDownload from "./service/Excel/ExcelDownload";
import AdminChanger from "./service/Crud/AdminChanger";
import AdminStartupChanger from "./service/Crud/AdminStartupChanger";
import RequiredLogin from "../Required/RequiredLogin";
import Store from "../../context/store";
import {MainContext} from "../../context";
import {Desktop, Mobile, Tablet} from "../../Responsive";
import Login from "../../pages/Login";


const columns = [
    {field:'name', headerName: '이름', width:100},
    {field:'birth', headerName: '생년월일', width:120},
    {field:'id', headerName: '아이디', width:250},
    {field:'phone', headerName: '전화번호', width:150},
    {field:'admin', headerName: '관리자', width:80},
    {field:'start_up', headerName: '개통여부', width:80}
]



export default function Index(){

    const [data,setData]=useState([])
    const [selectedData,setSelectedData]=useState('')
    const [isAdmin, setIsAdmin] = useState(false)


    const { storeLogin,toggleClose } = useContext(MainContext)


    const onRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => data.find((row) => row.id === id ));
        setSelectedData(selectedRowsData)
    };



    const inputData = ()=>{
        if(storeLogin === true){
            const storeData = Store().getLoginData()
            if(storeData.admin === true){
                setIsAdmin(true)
                getAdminData()
                    .then(res=>{
                        let inputData = res.data.map((rowData)=>({
                                id:rowData.userId,
                                birth:rowData.birth,
                                name:rowData.name,
                                phone:rowData.phone,
                                admin:(rowData.admin === true) ? 'O':'X',
                                start_up: (rowData.start_up === true) ? 'O':'X'
                            })
                        )
                        return setData(data.concat(inputData))
                    })

                    .catch((err)=>{
                        alert(err.response.data)
                    })

            }else{
                setIsAdmin(false)
            }
        }else{
            return;
        }

        Store().delStorage('modifyInfo')

        tokenDelete()
            .then(res=>{
                console.log(res.data)
            })
            .catch(err=>{
                console.log(JSON.stringify(err.response.data))
            })

    }

    useEffect(inputData,[])





    return(
        <Layout>
            {storeLogin  ? (
                <>
                    {isAdmin ? (
                        <>
                            <Desktop>
                                <div style={{backgroundImage:`url(${Background})`,
                                    backgroundSize:'cover'}} onClick={toggleClose}>
                                <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:20}}>
                                    <Box
                                        sx={{
                                            backgroundColor:'white',
                                            width:1050,
                                            border:1,
                                            borderRadius:'2rem',
                                            margin:3,
                                            display:'flex',
                                            alignItems:'center',
                                            flexDirection:'column',

                                        }}
                                    >
                                        <img alt="No Images" src={Logo} style={{width:330,height:110,paddingTop:30}}/>
                                        <Typography component="h1" variant="h5" sx={{paddingTop:1}}>
                                            관리자 페이지
                                        </Typography><br/>
                                        <div>
                                            <Find setData={setData}/><br/>

                                            <AdminChanger selectedData={selectedData}/>

                                            <AdminStartupChanger selectedData={selectedData}/>

                                            <AdminRegister selectedData={selectedData}/>

                                            <AdminInfoUpdate selectedData={selectedData}/>

                                            <AdminUserDel selectedData={selectedData}/>

                                            <ExcelUpload />

                                            <ExcelDownload selectedData={selectedData}/>

                                        </div><br/>

                                        <div style={{ height: 450, width: '80%' }}>
                                            <DataGrid
                                                rows={data}
                                                columns={columns}
                                                pageSize={20}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                                            />
                                        </div>
                                        <br/><br/>
                                    </Box>
                                </div>
                                </div>
                            </Desktop>
                            <Tablet>
                                <div style={{backgroundImage:`url(${Background})`,
                                    backgroundSize:'contain'}} onClick={toggleClose}>
                                <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingTop:50,paddingBottom:50}}>
                                    <Box
                                        sx={{
                                            backgroundColor:'white',
                                            width:720,
                                            border:1,
                                            borderRadius:'2rem',
                                            margin:3,
                                            display:'flex',
                                            alignItems:'center',
                                            flexDirection:'column',

                                        }}
                                    >
                                        <img alt="No Images" src={Logo} style={{width:330,height:110,paddingTop:30}}/>
                                        <Typography component="h1" variant="h5" sx={{paddingTop:1}}>
                                            관리자 페이지
                                        </Typography><br/>
                                        <div>
                                            <Find setData={setData}/><br/>

                                            <AdminChanger selectedData={selectedData}/>

                                            <AdminStartupChanger selectedData={selectedData}/>

                                            <AdminRegister selectedData={selectedData}/>

                                            <AdminInfoUpdate selectedData={selectedData}/>

                                            <AdminUserDel selectedData={selectedData}/>
                                        </div><br/>
                                        <div>
                                            <ExcelUpload />

                                            <ExcelDownload selectedData={selectedData}/>
                                        </div><br/>




                                        <div style={{ height: 450, width: '80%' }}>
                                            <DataGrid
                                                rows={data}
                                                columns={columns}
                                                pageSize={20}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                                            />
                                        </div>
                                        <br/><br/>
                                    </Box>
                                </div>
                                </div>
                            </Tablet>
                            <Mobile>
                                <div style={{backgroundImage:`url(${Background})`,
                                    backgroundSize:'contain'}} onClick={toggleClose}>
                                <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:20}}>
                                    <Box
                                        sx={{
                                            backgroundColor:'white',
                                            width:350,
                                            border:1,
                                            borderRadius:'2rem',
                                            margin:3,
                                            display:'flex',
                                            alignItems:'center',
                                            flexDirection:'column',

                                        }}
                                    >
                                        <img alt="No Images" src={Logo} style={{width:270,height:70,paddingTop:20}}/>
                                        <Typography component="h4" variant="h7" sx={{paddingTop:1}}>
                                            관리자 페이지
                                        </Typography><br/>
                                        <div style={{display:'flex',alignItems:"center",flexDirection:'column'}}>
                                            <Find setData={setData} sizeType="mobile" /><br/>
                                            <div style={{marginBottom:10}}>
                                            <AdminChanger selectedData={selectedData} sizeType="mobile" />
                                            <AdminStartupChanger selectedData={selectedData} sizeType="mobile" />
                                            </div>

                                            <div style={{marginBottom:10}}>
                                            <AdminRegister selectedData={selectedData} sizeType="mobile" />

                                            <AdminInfoUpdate selectedData={selectedData} sizeType="mobile" />

                                            <AdminUserDel selectedData={selectedData} sizeType="mobile" />
                                            </div>
                                            <div style={{marginBottom:10}}>
                                            <ExcelUpload />

                                            <ExcelDownload selectedData={selectedData}/>
                                            </div>
                                        </div><br/>

                                        <div style={{ height: 450, width: '90%' }}>
                                            <DataGrid
                                                rows={data}
                                                columns={columns}
                                                pageSize={20}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                                            />
                                        </div>
                                        <br/><br/>
                                    </Box>
                                </div>
                                </div>
                            </Mobile>

                        </>
                    ):(
                        <RequiredAdmin />
                    )}
                </>
                ):(
                <Login />
                )}


        </Layout>
    )
}