import Background from "../picture/adminLogin.jpeg";
import {Box, Button, Divider, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Logo from "../picture/Logo.png";
import CompanyLogo from "../picture/new_blaubit.png"
import {login, loginCheck, logout, tokenDelete} from "../api";
import Store from "../context/store";
import {Desktop, Mobile, Tablet} from "../Responsive";
import moment from "moment-timezone";


export default function Login(){

    let loginData = Store().getLoginData()

    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");

    const [EmailMessage, setEmailMessage] = useState("")
    const [PwMessage, setPwMessage] = useState("")

    const [isEmail, setIsEmail] = useState(false)
    const [isPw, setIsPw] = useState(false)

    let storeLogin = Store().getLogin() === 'true'

    let findData = Store().getFindData()


    useEffect(()=>{
        if(findData !== null){
            setUserId(findData)
            setIsEmail(true)
        }
    },[])


    const onChangeEmail = (e) => {
        const currentEmail = e.currentTarget.value;
        setUserId(currentEmail)
        const emailRegExp =
            /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;

        if (!emailRegExp.test(currentEmail)) {
            setEmailMessage("이메일의 형식이 올바르지 않습니다!");
            setIsEmail(false);
        } else {
            setEmailMessage("");
            setIsEmail(true);
        }
    };

    const onChangePassword = (e) => {
        const currentPw = e.currentTarget.value;
        setPassword(currentPw);
        const passwordRegExp =
            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
        if (!passwordRegExp.test(currentPw)) {
            setPwMessage(
                "숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"
            );
            setIsPw(false);
        } else {
            setPwMessage("");
            setIsPw(true);
        }
    };

    const onSubmitHandler = (e)=>{
        e.preventDefault()

        let data ={
            userId:userId.trim(),
            password:password.trim()
        }

        login(data)
            .then(res=>{
                alert('로그인 성공')
                Store().setLogin(true)
                Store().setLoginData(res.data)
                window.location.replace('/')
            })
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
            })

    }



    return(
        <>
            <Desktop>
            <div style={{backgroundImage:`url(${Background})`,
                backgroundSize:'cover',opacity:7}}>
                <div style={{alignItems:'revert-layer',display:'inline-table',flexDirection:'column',paddingBottom:400
                ,position:'sticky',paddingLeft:100}}>

                    <div style={{display:'flex',alignItems:'flex-end',flexDirection:"column"}}>
                        <Box
                            component="form"
                            sx={{
                                marginTop: 10,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                width: '450px',
                                borderRadius:'0.5rem',
                                border:1,
                                borderColor:'grey.500',
                                opacity:0.8,

                            }}><br/>
                            <img alt="No Images" src={Logo} style={{width:350,height:110,paddingRight:30}}/><br/>
                            <Typography component="h1" variant="h5" sx={{paddingBottom:2}}>
                                로그인
                            </Typography>
                            <Divider color="#696969" sx={{height: 2, width: '360px'}}></Divider><br/>

                                    <TextField
                                        value={userId}
                                        margin="normal"
                                        required
                                        sx={{width:360}}
                                        label="ID(E-Mail)"
                                        name="userId"
                                        autoFocus
                                        onChange={onChangeEmail}
                                    />
                            {findData !== null ? (
                                <>
                                    <TextField
                                        autoFocus
                                        value={password}
                                        margin="normal"
                                        required
                                        sx={{width:360}}
                                        label="Password"
                                        type="password"
                                        onChange={onChangePassword}
                                    />
                                </>
                            ):(
                                <>
                                    <TextField
                                        value={password}
                                        margin="normal"
                                        required
                                        sx={{width:360}}
                                        label="Password"
                                        type="password"
                                        onChange={onChangePassword}
                                    />
                                </>
                            )}


                            <Button
                                onClick={onSubmitHandler}
                                disabled={ isEmail === false || isPw === false }
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{ mt: 3, mb: 2,
                                    width:360,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                로그인
                            </Button>

                            <div style={{alignItems:'center',display:'flex',margin:5,paddingBottom:10}}>
                                <Button
                                    type="submit"
                                    variant="text"
                                    sx={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width:"120px"}}
                                    size="small"
                                    href='/findId'
                                    style={{backgroundColor:"white"}}
                                >
                                    아이디찾기
                                </Button>

                                <Button
                                    type="submit"
                                    variant="text"
                                    sx={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width:"120px"}}
                                    size="small"
                                    href="/findPwSelect"
                                    style={{backgroundColor:"white"}}
                                >
                                    비밀번호찾기
                                </Button>

                                <Button
                                    type="submit"
                                    variant="text"
                                    sx={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width:"120px"}}
                                    size="small"
                                    href="/joinAgree"
                                    style={{backgroundColor:"white"}}
                                >
                                    회원가입
                                </Button>
                            </div>

                            <img alt="No Images" src={CompanyLogo} style={{width:250,height:75}}/><br/>
                        </Box>
                    </div>

                </div>

            </div>
            </Desktop>

            <Tablet>
                <div style={{backgroundImage:`url(${Background})`,
                    backgroundSize:'contain',opacity:7}}>
                    <div style={{alignItems:'revert-layer',display:'inline-table',flexDirection:'column',paddingBottom:600
                        ,position:'sticky',paddingLeft:80}}>
                        <div style={{display:'flex',alignItems:'flex-end',flexDirection:"column"}}>
                            <Box
                                component="form"
                                sx={{
                                    marginTop: 6,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    width: '300px',
                                    borderRadius:'0.5rem',
                                    border:1,
                                    borderColor:'grey.500',
                                    opacity:0.8,

                                }}><br/>
                                <img alt="No Images" src={Logo} style={{width:250,height:80,paddingRight:30}}/><br/>
                                <Typography component="h1" variant="h5" sx={{paddingBottom:2}}>
                                    로그인
                                </Typography>
                                <Divider color="#696969" sx={{height: 2, width: '250px'}}></Divider><br/>

                                <TextField
                                    value={userId}
                                    margin="normal"
                                    required
                                    sx={{width:250}}
                                    label="ID(E-Mail)"
                                    name="userId"
                                    autoFocus
                                    onChange={onChangeEmail}
                                />
                                {findData !== null ? (
                                    <>
                                        <TextField
                                            autoFocus
                                            value={password}
                                            margin="normal"
                                            required
                                            sx={{width:250}}
                                            label="Password"
                                            type="password"
                                            onChange={onChangePassword}
                                        />
                                    </>
                                ):(
                                    <>
                                        <TextField
                                            value={password}
                                            margin="normal"
                                            required
                                            sx={{width:250}}
                                            label="Password"
                                            type="password"
                                            onChange={onChangePassword}
                                        />
                                    </>
                                )}


                                <Button
                                    onClick={onSubmitHandler}
                                    disabled={ isEmail === false || isPw === false }
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mt: 3, mb: 2,
                                        width:250,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    로그인
                                </Button>

                                <div style={{alignItems:'center',display:'flex',margin:2,paddingBottom:10}}>
                                    <Button
                                        type="submit"
                                        variant="text"
                                        sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width:"80px"}}
                                        size="small"
                                        href='/findId'
                                        style={{backgroundColor:"white"}}
                                    >
                                        아이디찾기
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="text"
                                        sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width:"100px"}}
                                        size="small"
                                        href="/findPwSelect"
                                        style={{backgroundColor:"white"}}
                                    >
                                        비밀번호찾기
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="text"
                                        sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width:"80px"}}
                                        size="small"
                                        href="/joinAgree"
                                        style={{backgroundColor:"white"}}
                                    >
                                        회원가입
                                    </Button>
                                </div>

                                <img alt="No Images" src={CompanyLogo} style={{width:220,height:70}}/><br/>
                            </Box>
                        </div>

                    </div>
                </div>
            </Tablet>
            <Mobile>
                <div style={{backgroundImage:`url(${Background})`,
                    backgroundSize:'contain',opacity:7}}>
                    <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:500
                        ,position:'static'}}>
                        <div style={{display:'flex',alignItems:'flex-end',flexDirection:"column"}}>
                            <Box
                                component="form"
                                sx={{
                                    marginTop: 6,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    width: '280px',
                                    borderRadius:'0.5rem',
                                    border:1,
                                    borderColor:'grey.500',
                                    opacity:0.8,

                                }}><br/>
                                <img alt="No Images" src={Logo} style={{width:250,height:80,paddingRight:10}}/>
                                <Typography component="h1" variant="h5" sx={{paddingBottom:1}}>
                                    로그인
                                </Typography>
                                <Divider color="#696969" sx={{height: 2, width: '250px'}}></Divider><br/>

                                <TextField
                                    value={userId}
                                    margin="normal"
                                    size="small"
                                    required
                                    sx={{width:250}}
                                    label="ID(E-Mail)"
                                    name="userId"
                                    autoFocus
                                    onChange={onChangeEmail}
                                />
                                {findData !== null ? (
                                    <>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            value={password}
                                            margin="normal"
                                            required
                                            sx={{width:250}}
                                            label="Password"
                                            type="password"
                                            onChange={onChangePassword}
                                        />
                                    </>
                                ):(
                                    <>
                                        <TextField
                                            size="small"
                                            value={password}
                                            margin="normal"
                                            required
                                            sx={{width:250}}
                                            label="Password"
                                            type="password"
                                            onChange={onChangePassword}
                                        />
                                    </>
                                )}


                                <Button
                                    onClick={onSubmitHandler}
                                    disabled={ isEmail === false || isPw === false }
                                    type="submit"
                                    variant="contained"
                                    size="medium"
                                    sx={{ mt: 3, mb: 2,
                                        width:250,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    모바일 로그인
                                </Button>

                                <div style={{alignItems:'center',display:'flex',margin:2,paddingBottom:10}}>
                                    <Button
                                        type="submit"
                                        variant="text"
                                        sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width:"80px"}}
                                        size="small"
                                        href='/findId'
                                        style={{backgroundColor:"white"}}
                                    >
                                        아이디찾기
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="text"
                                        sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width:"100px"}}
                                        size="small"
                                        href="/findPwSelect"
                                        style={{backgroundColor:"white"}}
                                    >
                                        비밀번호찾기
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="text"
                                        sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width:"80px"}}
                                        size="small"
                                        href="/joinAgree"
                                        style={{backgroundColor:"white"}}
                                    >
                                        회원가입
                                    </Button>
                                </div>

                                <img alt="No Images" src={CompanyLogo} style={{width:200,height:60}}/><br/>
                            </Box>
                        </div>

                    </div>
                </div>
            </Mobile>
        </>
    )
}