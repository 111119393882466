import React, {useContext, useEffect, useState} from 'react'
import Background from '../picture/back.jpeg'
import Layout from "./containers/Layout";
import {authLoginCheck, loginCheck, logout, tokenDelete} from "../api";
import Login from "./Login";
import Store from "../context/store";
import {Desktop, Mobile, Tablet} from "../Responsive";
import {MainContext} from "../context";
import moment from "moment-timezone";



export default function Home(){

    const [isAuth,setIsAuth] = useState(false)
    let storeLogin = Store().getLogin() === 'true'
    let StoreData

    if(storeLogin === true){
        StoreData = Store().getLoginData()
    }

    let loginData = Store().getLoginData()
    let [isLogin,setIsLogin] = useState(false)

    const { toggleClose } = useContext(MainContext)


    useEffect(()=>{
        tokenDelete()
            .then(res=>{
                console.log(res.data)
            })
            .catch(err=>{
                console.log(JSON.stringify(err.response.data))
            })

        loginCheck()
            .then(res=>{
                Store().setLogin(true)
                setIsLogin(true)
            })
            .catch(err=>{
                console.log(err)
            })

    },[])


    return(
        <>
            {storeLogin === true ? (
                <>
                    <Layout>
                        <Desktop>
                        <div style={{backgroundImage:`url(${Background})`,
                            backgroundSize:"cover", paddingBottom:900}} onClick={toggleClose}>
                        </div>
                        </Desktop>
                        <Tablet>
                            <div style={{backgroundImage:`url(${Background})`,
                                backgroundSize:"contain", paddingBottom:1000}} onClick={toggleClose}>
                            </div>
                        </Tablet>
                        <Mobile>
                            <div style={{backgroundImage:`url(${Background})`,
                                backgroundSize:"contain", paddingBottom:720}} onClick={toggleClose}>
                            </div>
                        </Mobile>
                    </Layout>
                </>
            ):(
                <>
                    <Login />
                </>
            )}
        </>




    )
}