import {Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Text from "./text";
import moment from "moment-timezone";
import {getWeekCore} from "../../../../../api";
import {Desktop, Mobile, Tablet} from "../../../../../Responsive";

export const CustomTooltip = ({ active, payload, label}) => {

    let sumScore =0;

    payload.map((e)=>(
        sumScore = sumScore + e.value
    ))


    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label" style={{backgroundColor:"#4375DB",color:'whitesmoke'}}>{`${label} : ${payload[0].value}`}</p>
                <div style={{backgroundColor:"white"}}>
                    <div style={{backgroundColor:'whitesmoke'}} >총합 점수: {sumScore}</div>
                    {payload.map((pld) => (
                        <div style={{ display: "inline-block", padding: 10 }}>
                            <div style={{ color: pld.fill }}>{pld.value}</div>
                            <div>{pld.dataKey}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return null;
};
const CustomCursor = props => {
    const { x, y, width, height, stroke } = props;
    return <Rectangle fill="#00ff0000" stroke="#4375DB" x={x} y={y} width={width} height={height}/>;
};


export default function Index(){

    const [database, setDataBase] = useState([])

    let weekReport = {
        numerical:0,
        stuttering:0,
        pronunciation:0
    }

    let scoreData = []

    database.map(items=>{
        weekReport = {
            numerical: weekReport.numerical+ items.numerical,
            stuttering: weekReport.stuttering+ items.stuttering,
            pronunciation: weekReport.pronunciation+ items.pronunciation
        }

        let numericalScore;
        let stutteringScore;
        let pronunciationScore;

            if(items.stuttering >= 10){
                stutteringScore = 20
            }else if(items.sleepTime >= 7){
                stutteringScore = 30
            }else if(items.sleepTime >= 5){
                stutteringScore = 60
            }else if(items.sleepTime >= 3){
                stutteringScore = 80
            }else{
                stutteringScore = 100
            }

        if(items.pronunciation >= 10){
            pronunciationScore = 20
        }else if(items.pronunciation >= 7){
            pronunciationScore = 30
        }else if(items.pronunciation >= 5){
            pronunciationScore = 60
        }else if(items.pronunciation >= 3){
            pronunciationScore = 80
        }else{
            pronunciationScore = 100
        }

            let totalscore;

            if(database.length === 0){
                totalscore = 0
                pronunciationScore = 0
                stutteringScore = 0
            }else{
                totalscore = (pronunciationScore + stutteringScore)
            }

            scoreData.push(
                {
                    date:items.date,
                    totalscore:totalscore,
                    '말 버벅임':pronunciationScore,
                    '발음':stutteringScore
                })

    })


    const stuttering = Number(weekReport.stuttering)
    const pronunciation = Number(weekReport.pronunciation)



    let now = moment().tz('Asia/Seoul').format('YYYY-MM-DD')

    let start = moment(now).subtract("6", "d").format('YYYY년 MM월 DD일');

    let end = moment().tz('Asia/Seoul').format('YYYY년 MM월 DD일')



    let scores = 0

    scoreData.map(e=>{
        scores = e.totalscore + scores
    })



    useEffect(()=>{
        getWeekCore()
            .then(res=>{
                setDataBase(res.data)
            })
            .catch(err=>{
                console.log(JSON.stringify(err))
            })

    },[])

    return(
        <>
            <Desktop>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>

                    <Typography component="h1" variant="h5" >
                        주간 뇌졸증 수치 평균 점수는 {Math.round(scores / 7 / 2 , 0)} 점 입니다.
                    </Typography><br/>
                    <Typography component="h1" variant="h6" >
                        {start} ~ {end}
                    </Typography><br/>

                    <div style={{width:800,height:350,marginTop:5}}>

                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={500}
                                height={300}
                                data={scoreData}
                                margin={{
                                    top: 10,
                                    left: 3,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis domain={[0, 100]}/>
                                <Tooltip content={<CustomTooltip />} cursor={<CustomCursor />} />
                                <Legend/>
                                <Bar dataKey="말 버벅임" stackId="a" name="말 버벅임" barSize={50} fill="#4375DB" />
                                <Bar dataKey="발음" stackId="a" name="발음" barSize={50} fill="#0ABAB5" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div><br/><br/>

                    <Text
                        numerical={Math.round(scores / 7 / 2 , 0)} stuttering={Math.round(stuttering / 7 , 0)} pronunciation={Math.round(pronunciation / 7 , 0)} />
                    <br/><br/>
                </div>
            </Desktop>
            <Tablet>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>

                    <Typography component="h1" variant="h5" >
                        주간 뇌졸증 수치 평균 점수는 {Math.round(scores / 7 / 2 , 0)} 점 입니다.
                    </Typography><br/>
                    <Typography component="h1" variant="h6" >
                        {start} ~ {end}
                    </Typography><br/>

                    <div style={{width:680,height:350,marginTop:5,marginRight:15}}>

                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={700}
                                height={300}
                                data={scoreData}
                                margin={{
                                    top: 10,
                                    left: 3,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis domain={[0, 100]}/>
                                <Tooltip content={<CustomTooltip />} cursor={<CustomCursor />} />
                                <Legend/>
                                <Bar dataKey="말 버벅임" stackId="a" name="말 버벅임" barSize={40} fill="#4375DB" />
                                <Bar dataKey="발음" stackId="a" name="발음" barSize={40} fill="#0ABAB5" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div><br/><br/>

                    <Text
                        numerical={Math.round(scores / 7 / 2 , 0)} stuttering={Math.round(stuttering / 7 , 0)} pronunciation={Math.round(pronunciation / 7 , 0)} />
                    <br/><br/>
                </div>
            </Tablet>
            <Mobile>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>

                    <Typography component="h5" variant="h8" >
                        주간 뇌졸증 수치 평균 점수는 {Math.round(scores / 7 / 2 , 0)} 점 입니다.
                    </Typography><br/>
                    <Typography component="h5" variant="h8" >
                        {start} ~ {end}
                    </Typography><br/>

                    <div style={{width:350,height:300,marginTop:5,marginRight:25}}>

                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={500}
                                height={300}
                                data={scoreData}
                                margin={{
                                    top: 10,
                                    left: 3,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis domain={[0, 100]}/>
                                <Tooltip content={<CustomTooltip />} cursor={<CustomCursor />} />
                                <Legend/>
                                <Bar dataKey="말 버벅임" stackId="a" name="말 버벅임" barSize={20} fill="#4375DB" />
                                <Bar dataKey="발음" stackId="a" name="발음" barSize={20} fill="#0ABAB5" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div><br/><br/>

                    <Text
                        numerical={Math.round(scores / 7 / 2 , 0)} stuttering={Math.round(stuttering / 7 , 0)} pronunciation={Math.round(pronunciation / 7 , 0)} />
                    <br/><br/>
                </div>
            </Mobile>


        </>
    )
}