import Logo from "../../../../picture/Logo.png";
import {Button, Typography} from "@mui/material";
import React from "react";
import {changeAdmin, changeStartUp} from "../../../../api";


export default function StartupUpdateModalComponent(props){

    const { data,sizeType }=props

    let nameMap = data.map(e=>e.name).join(', ')

    const StartUpTrue = ()=>{
        let sendData = {
            StartUpChange: true,
            selectedData: data
        }

        if(window.confirm(`${nameMap} 님을 개통처리 하시겠습니까?`)){
            changeStartUp(sendData)
                .then(res=>{
                    alert(res.data)
                    window.location.replace('/adminPage')
                })
                .catch(err=>{
                    console.log(err)
                })
        }else {
            return;
        }



    }
    const StartUpFalse = ()=>{
        let sendData = {
            StartUpChange: false,
            selectedData: data
        }

        if(window.confirm(`${nameMap} 님을 개통취소처리 하시겠습니까?`)){
            changeStartUp(sendData)
                .then(res=>{
                    alert(res.data)
                    window.location.replace('/adminPage')
                })
                .catch(err=>{
                    console.log(err)
                })
        }else {
            return;
        }
    }

    return(
        <>
            <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
                {sizeType === 'mobile' ? (
                    <>
                        <img alt="No Images" src={Logo} style={{width:270}}/><br/>

                        <Typography component="h4" variant="h5">
                            개통변경
                        </Typography><br/><br/>

                        <Typography component="h4" variant="h0" >
                            변경하시려는 분의 성함: {nameMap}님
                        </Typography><br/><br/><br/>


                        <Button
                            onClick={StartUpTrue}
                            variant="contained"
                            size="large"
                            sx={{}}
                        >
                            개통
                        </Button><br/>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={StartUpFalse}
                            sx={{}}
                        >
                            개통취소
                        </Button>
                    </>
                ):(
                    <>
                        <img alt="No Images" src={Logo} style={{width:350}}/><br/>

                        <Typography component="h4" variant="h5">
                            개통변경
                        </Typography><br/>

                        <Typography component="h4" variant="h0" >
                            변경하시려는 분의 성함: {nameMap}님
                        </Typography><br/>


                        <Button
                            onClick={StartUpTrue}
                            variant="contained"
                            size="large"
                            sx={{}}
                        >
                            개통
                        </Button><br/>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={StartUpFalse}
                            sx={{}}
                        >
                            개통취소
                        </Button><br/>
                    </>
                )}

            </div>
        </>
    )
}