import React, {useState} from "react";
import {Button} from "@mui/material";
import AdminRegisterModal from "../../modals/create/AdminRegisterModal";



export default function AdminRegister(props){

    const {selectedData,sizeType} =props

    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }

    return(
        <>
            <Button
                variant="contained"
                size="large"
                onClick={openModal}
                sx={{marginRight:2}}
            >
                신규
            </Button>
            {modalOpen && <>
                {sizeType === 'mobile' ? (
                    <>
                        <AdminRegisterModal open={modalOpen} close={closeModal} header="신규 가입"
                                            sizeType="mobile" />
                    </>
                ):(
                    <>
                        <AdminRegisterModal open={modalOpen} close={closeModal} header="신규 가입" />
                    </>
                )}
            </>}
        </>
    )
}