import React, {useContext, useEffect} from 'react'
import Layout from "../../pages/containers/Layout";
import Background from '../../picture/back.jpeg'
import {Box, Typography} from "@mui/material";
import {tokenDelete} from "../../api";
import Products from "../../picture/product.jpeg";
import Logo from "../../picture/Logo.png";
import {Desktop, Mobile, Tablet} from "../../Responsive";
import {MainContext} from "../../context";
import Store from "../../context/store";


export default function Product(){
    const { toggleClose } = useContext(MainContext)

    useEffect(()=>{
        Store().delStorage('modifyInfo')
        tokenDelete()
            .then(res=>{
                console.log(res.data)
            })
            .catch(err=>{
                console.log(JSON.stringify(err.response.data))
            })
    },[])

    return(
        <Layout>
            <Desktop>
                <div style={{backgroundImage:`url(${Background})`,
                    backgroundSize:'cover'}} onClick={toggleClose}>
                    <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
                        <Box
                            sx={{
                                backgroundColor:'white',
                                width:1000,
                                border:1,
                                borderRadius:'2rem',
                                marginTop:7,
                                marginBottom:7,
                                display:'flex',
                                alignItems:'center',
                                flexDirection:'column',

                            }}>
                            <img alt="No Images" src={Logo} style={{marginTop:30,width:350,height:120}}/>
                            <Typography component="h1" variant="h5" sx={{paddingTop:2}}>
                                뇌졸증 소개
                            </Typography>
                            <img alt="No Images" src={Products} style={{marginTop:30,width:850,height:500}}/>
                            <br/><br/>
                        </Box>
                    </div>
                </div>
            </Desktop>
            <Tablet>
                <div style={{backgroundImage:`url(${Background})`,
                    backgroundSize:'contain',paddingTop:100,paddingBottom:200}} onClick={toggleClose}>
                    <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
                        <Box
                            sx={{
                                backgroundColor:'white',
                                width:650,
                                border:1,
                                borderRadius:'2rem',
                                margin:3,
                                display:'flex',
                                alignItems:'center',
                                flexDirection:'column',

                            }}>
                            <img alt="No Images" src={Logo} style={{marginTop:20,width:270,height:90,paddingRight:40}}/>
                            <Typography component="h1" variant="h5" sx={{paddingTop:2}}>
                                뇌졸증 소개
                            </Typography>
                            <img alt="No Images" src={Products} style={{marginTop:30,width:640,height:400}}/>
                            <br/><br/>
                        </Box>
                    </div>
                </div>
            </Tablet>
            <Mobile>
                <div style={{backgroundImage:`url(${Background})`,
                    backgroundSize:'cover'}} onClick={toggleClose}>
                    <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
                        <Box
                            sx={{
                                backgroundColor:'white',
                                width:350,
                                border:1,
                                borderRadius:'2rem',
                                margin:3,
                                display:'flex',
                                alignItems:'center',
                                flexDirection:'column',

                            }}>
                            <img alt="No Images" src={Logo} style={{marginTop:30,width:220,height:80}}/>
                            <Typography component="h1" variant="h5" sx={{paddingTop:1}}>
                                뇌졸증 소개
                            </Typography>
                            <img alt="No Images" src={Products} style={{marginTop:20,width:330,height:250}}/>
                            <br/><br/>
                        </Box>
                    </div>
                </div>
            </Mobile>

        </Layout>
    )
}