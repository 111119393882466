import {Box, Typography} from "@mui/material";
import React from "react";
import {Desktop, Mobile, Tablet} from "../../../../../Responsive";


export default function Text(props){

    const {numerical, stuttering, pronunciation} =props



    return(
        <>
            <Desktop>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Box
                        sx={{
                            backgroundColor: 'lightgrey',
                            width: 240,
                            height: 80,
                            borderRadius: '1rem',
                            marginBottom: 2,
                            display:'flex',
                            alignItems:'center'
                        }}>
                        <Typography component="h4" variant="h8" sx={{paddingLeft:4}}>
                            뇌졸증 수치
                        </Typography>
                        <Typography component="h5" variant="h6" color='white' sx={{paddingLeft:7}}>
                            {numerical} 점
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: 'lightgrey',
                            width: 240,
                            height: 80,
                            borderRadius: '1rem',
                            marginBottom: 2,
                            marginLeft:3,
                            display:'flex',
                            alignItems:'center'
                        }}>
                        <Typography component="h4" variant="h8" sx={{paddingLeft:4}}>
                            말 버벅임
                        </Typography>
                        <Typography component="h5" variant="h6" color='white' sx={{paddingLeft:8}}>
                            {stuttering} 회
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: 'lightgrey',
                            width: 240,
                            height: 80,
                            borderRadius: '1rem',
                            marginBottom: 2,
                            marginLeft:3,
                            display:'flex',
                            alignItems:'center'
                        }}>
                        <Typography component="h4" variant="h8" sx={{paddingLeft:5}}>
                            발음
                        </Typography>
                        <Typography component="h5" variant="h6" color='white' sx={{paddingLeft:10}}>
                            {pronunciation} 회
                        </Typography>
                    </Box>
                </div>
            </Desktop>
            <Tablet>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Box
                        sx={{
                            backgroundColor: 'lightgrey',
                            width: 200,
                            height: 80,
                            borderRadius: '1rem',
                            marginBottom: 2,
                            display:'flex',
                            alignItems:'center'
                        }}>
                        <Typography component="h4" variant="h8" sx={{paddingLeft:3}}>
                            뇌졸증 수치
                        </Typography>
                        <Typography component="h5" variant="h6" color='white' sx={{paddingLeft:4}}>
                            {numerical} 점
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: 'lightgrey',
                            width: 200,
                            height: 80,
                            borderRadius: '1rem',
                            marginBottom: 2,
                            marginLeft:2,
                            display:'flex',
                            alignItems:'center'
                        }}>
                        <Typography component="h4" variant="h8" sx={{paddingLeft:3}}>
                            말 버벅임
                        </Typography>
                        <Typography component="h5" variant="h6" color='white' sx={{paddingLeft:7}}>
                            {stuttering} 회
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: 'lightgrey',
                            width: 200,
                            height: 80,
                            borderRadius: '1rem',
                            marginBottom: 2,
                            marginLeft:2,
                            display:'flex',
                            alignItems:'center'
                        }}>
                        <Typography component="h4" variant="h8" sx={{paddingLeft:3}}>
                            발음
                        </Typography>
                        <Typography component="h5" variant="h6" color='white' sx={{paddingLeft:11}}>
                            {pronunciation} 회
                        </Typography>
                    </Box>
                </div>
            </Tablet>
            <Mobile>
                <div style={{display: 'flex', alignItems: 'center',flexDirection:'column'}}>
                    <Box
                        sx={{
                            backgroundColor: 'lightgrey',
                            width: 200,
                            height: 80,
                            borderRadius: '1rem',
                            marginBottom: 2,
                            display:'flex',
                            alignItems:'center'
                        }}>
                        <Typography component="h4" variant="h8" sx={{paddingLeft:4}}>
                            뇌졸증 수치
                        </Typography>
                        <Typography component="h5" variant="h6" color='white' sx={{paddingLeft:3}}>
                            {numerical} 점
                        </Typography>
                    </Box>
                    <div style={{
                        display:'flex',
                        alignItems:'center'}}>
                        <Box
                            sx={{
                                backgroundColor: 'lightgrey',
                                width: 160,
                                height: 80,
                                borderRadius: '1rem',
                                marginBottom: 2,
                                display:'flex',
                                alignItems:'center'
                            }}>
                            <Typography component="h4" variant="h8" sx={{paddingLeft:3}}>
                                말 버벅임
                            </Typography>
                            <Typography component="h5" variant="h6" color='white' sx={{paddingLeft:3}}>
                                {stuttering} 회
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                backgroundColor: 'lightgrey',
                                width: 160,
                                height: 80,
                                borderRadius: '1rem',
                                marginBottom: 2,
                                marginLeft:1,
                                display:'flex',
                                alignItems:'center'
                            }}>
                            <Typography component="h4" variant="h8" sx={{paddingLeft:4}}>
                                발음
                            </Typography>
                            <Typography component="h5" variant="h6" color='white' sx={{paddingLeft:5}}>
                                {pronunciation} 회
                            </Typography>
                        </Box>
                    </div>
                </div>
            </Mobile>

        </>
    )
}