import React from "react";
import Background from "../../picture/back.jpeg";
import {Button, Typography} from "@mui/material";

export default function RequiredLogin(){
    return(
        <>
            <div style={{backgroundImage:`url(${Background})`,
                backgroundSize:"cover", padding:360}}>
                <div style={{display:"flex",alignItems:'center',flexDirection:'column'}}>
                <Typography color="white" component="h2" variant="h7" align="left" sx={{
                    display: 'flex',
                    float: 'left',
                }}>
                    로그인 후 사용 가능합니다.
                </Typography><br/>
                <Button
                variant='contained'
                href='/login'
                >
                    <Typography color="whitesmoke" component="h2" variant="h5" align="left" sx={{
                        marginTop:0.5,
                        display: 'flex',
                        float: 'left'
                    }}>
                        로그인 하러가기
                    </Typography>
                </Button>
                </div>
            </div>
        </>
    )
}