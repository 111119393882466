import axios from 'axios';
const SERVER = process.env.REACT_APP_SERVER
const LOCAL = process.env.REACT_APP_LOCALSERVER

//ctrl+r로 전체 바꿀수 있음 ${}


export const tokenDelete = () => axios.get(`${SERVER}/check/allTokenDel`,{withCredentials:true})
export const termsAgreeCheck = () => axios.get(`${SERVER}/check/termsAgreeCheck`, {withCredentials:true})
export const loginCheck = () => axios.get(`${SERVER}/check/loginCheck`, {withCredentials:true})
export const getAccessTokenData = () => axios.get(`${SERVER}/check/getAccessTokenData`, {withCredentials:true})
export const authInfoTokens = authInfoRequest => axios.post(`${SERVER}/check/authInfo`,authInfoRequest, {withCredentials:true})
export const authInfoCheck = () => axios.get(`${SERVER}/check/authInfoCheck`, {withCredentials:true})

export const registerAgree = registerAgreeReguest => axios.post(`${SERVER}/user/registerAgree`,registerAgreeReguest, {withCredentials:true})
export const login = loginRequest => axios.post(`${SERVER}/user/login`,loginRequest,{withCredentials:true})
export const logout = () => axios.get(`${SERVER}/user/logout`,{withCredentials:true})
export const register = registerReguest => axios.post(`${SERVER}/user/register`,registerReguest, {withCredentials:true})
export const findIdService = findIdServiceRequest => axios.post(`${SERVER}/user/findId`,findIdServiceRequest, {withCredentials:true})
export const findPwService = findPwServiceRequest => axios.post(`${SERVER}/user/findPw`,findPwServiceRequest, {withCredentials:true})
export const changePwService = changePwServiceRequest => axios.post(`${SERVER}/user/changePw`,changePwServiceRequest, {withCredentials:true})

export const findInfoSMS = findInfoSMSRequest => axios.post(`${SERVER}/send/findInfoSMS`,findInfoSMSRequest, {withCredentials:true})
export const registerSMS = RegisterSMSRequest => axios.post(`${SERVER}/send/sendRegisterSMS`,RegisterSMSRequest, {withCredentials:true})
export const modifyNameSMS = modifyNameSMSRequest => axios.post(`${SERVER}/send/modifyNameSMS`,modifyNameSMSRequest, {withCredentials:true})
export const modifyPasswordSMS = modifyPasswordSMSRequest => axios.post(`${SERVER}/send/modifyPasswordSMS`,modifyPasswordSMSRequest, {withCredentials:true})
export const modifyPhoneSMS = modifyPhoneSMSRequest => axios.post(`${SERVER}/send/modifyPhoneSMS`,modifyPhoneSMSRequest, {withCredentials:true})
export const modifyUserIdEmail = modifyUserIdEmailRequest =>axios.post(`${SERVER}/send/modifyUserIdEmail`,modifyUserIdEmailRequest, {withCredentials:true})
export const modifyBirthSMS = modifyBirthSMSRequest => axios.post(`${SERVER}/send/modifyBirthSMS`,modifyBirthSMSRequest,{withCredentials:true})
export const customerService = customerServiceRequest => axios.post(`${SERVER}/send/customer/service`,customerServiceRequest,{withCredentials:true})
//16
export const modifyName = modifyNameRequest => axios.post(`${SERVER}/modify/modifyName`,modifyNameRequest, {withCredentials:true})
export const modifyBirth = modifyBirthRequest => axios.post(`${SERVER}/modify/modifyBirth`,modifyBirthRequest, {withCredentials:true})
export const modifyUserId = modifyUserIdRequest => axios.post(`${SERVER}/modify/modifyUserId`,modifyUserIdRequest, {withCredentials:true})
export const modifyPassword = modifyPasswordRequest => axios.post(`${SERVER}/modify/modifyPassword`,modifyPasswordRequest, {withCredentials:true})
export const modifyPhone = modifyPhoneRequest => axios.post(`${SERVER}/modify/modifyPhone`,modifyPhoneRequest, {withCredentials:true})
export const deleteUser = deleteUserRequest => axios.post(`${SERVER}/modify/deleteUser`,deleteUserRequest, {withCredentials:true})
//22


export const getData = () => axios.get(`${SERVER}/fastStroke/getData`,{withCredentials:true})
export const getWeekData = ()=> axios.get(`${SERVER}/fastStroke/getWeekData`,{withCredentials:true})
export const getWeekCore = ()=> axios.get(`${SERVER}/fastStroke/weekcore`,{withCredentials:true})
export const getMonthCore = () => axios.get(`${SERVER}/fastStroke/monthCore`,{withCredentials:true})
//26

export const getAdminData = ()=> axios.get(`${SERVER}/admin/getData`,{withCredentials:true})
export const registerAdmin = createRequest => axios.post(`${SERVER}/admin/create`,createRequest,{withCredentials:true})
export const changeAdmin = changeAdminRequest => axios.post(`${SERVER}/admin/changeAdmin`,changeAdminRequest,{withCredentials:true})
export const updateOne = updateOneRequest => axios.post(`${SERVER}/admin/updateOne`,updateOneRequest,{withCredentials:true})
export const changeStartUp = changeStartUpRequest => axios.post(`${SERVER}/admin/changeStartUp`,changeStartUpRequest,{withCredentials:true})
export const searchData =searchRequest => axios.post(`${SERVER}/admin/search`,searchRequest,{withCredentials:true})
export const delAdminUser = delAdminUserRequest => axios.post(`${SERVER}/admin/delAdminUser`,delAdminUserRequest,{withCredentials:true})
export const excelDownload = excelDownloadRequest => axios.post(`${SERVER}/admin/excelDownload`,excelDownloadRequest,{withCredentials:true})
export const excelUpload = excelUploadRequest => axios.post(`${SERVER}/admin/excelUpload`,excelUploadRequest,{withCredentials:true})
//35

//37
export const authLogin = authLoginRequest => axios.post(`${SERVER}/check/authLogin`,authLoginRequest,{withCredentials:true})
export const authLoginCheck = () => axios.get(`${SERVER}/check/authLoginCheck`,{withCredentials:true})

export const recordFile = recordFileSend => axios.post(`${SERVER}/fastStroke/recordFiles`,recordFileSend,{withCredentials:true})
