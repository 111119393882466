import React from 'react'
import {Button, TextField, Typography} from "@mui/material";
import Logo from "../../../../picture/Logo.png";
import {changeAdmin} from "../../../../api";



export default function AdminUpdateModalComponent(props){

    const { data,sizeType }=props

    let nameMap = data.map(e=>e.name).join(', ')

    const adminChange = ()=>{
        let sendData = {
            adminChangeData: true,
            selectedData: data
        }

        if(window.confirm(`${nameMap} 님을 관리자로 변경하시겠습니까?`)){
            changeAdmin(sendData)
                .then(res=>{
                    alert(res.data)
                    window.location.replace('/adminPage')
                })
                .catch(err=>{
                    console.log(err)
                })
        }else {
            return;
        }



    }
    const userChange = ()=>{
        let sendData = {
            adminChangeData: false,
            selectedData: data
        }

        if(window.confirm(`${nameMap} 님을 일반유저로 변경하시겠습니까?`)){
            changeAdmin(sendData)
                .then(res=>{
                    alert(res.data)
                    window.location.replace('/adminPage')
                })
                .catch(err=>{
                    console.log(err)
                })
        }else {
            return;
        }
    }


    return(
        <>
            <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
                {sizeType === 'mobile' ? (
                    <>
                        <img alt="No Images" src={Logo} style={{width:270}}/><br/>

                        <Typography component="h4" variant="h5">
                            관리자 권한변경
                        </Typography><br/><br/>

                        <Typography component="h4" variant="h0" >
                            변경하시려는 분의 성함: {nameMap}님
                        </Typography><br/><br/><br/>


                        <Button
                            onClick={adminChange}
                            variant="contained"
                            size="large"
                            sx={{}}
                        >
                            관리자 권한으로 변경
                        </Button><br/>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={userChange}
                            sx={{}}
                        >
                            일반유저로 변경
                        </Button>
                    </>
                ):(
                    <>
                        <img alt="No Images" src={Logo} style={{width:350}}/><br/>

                        <Typography component="h4" variant="h5">
                            관리자 권한변경
                        </Typography><br/>

                        <Typography component="h4" variant="h0" >
                            변경하시려는 분의 성함: {nameMap}님
                        </Typography><br/>


                        <Button
                            onClick={adminChange}
                            variant="contained"
                            size="large"
                            sx={{}}
                        >
                            관리자 권한으로 변경
                        </Button><br/>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={userChange}
                            sx={{}}
                        >
                            일반유저로 변경
                        </Button><br/>
                    </>
                )}

            </div>
        </>
    )
}