import {Button, TextField, Typography} from "@mui/material";
import UpdateNameModal from "../modals/update/name/UpdateNameModal";
import {useState} from "react";
import {Desktop, Mobile, Tablet} from "../../../../Responsive";


export default function NameModify(props){

    const {data}=props

    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }

    return(
        <>
            <Desktop>
                <div style={{alignItems:'center',display:'flex', paddingBottom:20}}>

                    <TextField
                        defaultValue={data.name}
                        key={data.name}
                        required
                        fullWidth
                        type="text"
                        name="name"
                        label="이름"
                        disabled
                        sx={{width:250, paddingRight:2}}
                    />

                    <Button
                        onClick={openModal}
                        variant="outlined"
                        type="submit"
                        size="medium"
                        sx={{
                            width:'90px',
                            height:'58px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            수정
                        </Typography>
                    </Button>
                    {modalOpen && <UpdateNameModal open={modalOpen} close={closeModal} header="이름 변경" data={data} />}

                </div>
            </Desktop>
            <Tablet>
                <div style={{alignItems:'center',display:'flex', paddingBottom:20}}>

                    <TextField
                        defaultValue={data.name}
                        key={data.name}
                        required
                        fullWidth
                        type="text"
                        name="name"
                        label="이름"
                        disabled
                        sx={{width:250, paddingRight:2}}
                    />

                    <Button
                        onClick={openModal}
                        variant="outlined"
                        type="submit"
                        size="medium"
                        sx={{
                            width:'90px',
                            height:'58px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            수정
                        </Typography>
                    </Button>
                    {modalOpen && <UpdateNameModal open={modalOpen} close={closeModal} header="이름 변경" data={data} />}

                </div>
            </Tablet>
            <Mobile>
                <div style={{alignItems:'center',display:'flex', paddingBottom:20}}>

                    <TextField
                        defaultValue={data.name}
                        key={data.name}
                        required
                        fullWidth
                        type="text"
                        name="name"
                        label="이름"
                        disabled
                        sx={{width:220, paddingRight:2}}
                    />

                    <Button
                        onClick={openModal}
                        variant="outlined"
                        type="submit"
                        size="medium"
                        sx={{
                            width:'90px',
                            height:'58px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            수정
                        </Typography>
                    </Button>
                    {modalOpen && <UpdateNameModal open={modalOpen} close={closeModal} header="이름 변경" data={data}
                    sectionType="mobile" />}

                </div>
            </Mobile>


        </>
    )
}