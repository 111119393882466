import React, {useContext, useEffect, useState} from 'react'
import Layout from "../../pages/containers/Layout";
import Background from "../../picture/back.jpeg";
import {Box, Typography} from "@mui/material";
import {authLoginCheck, loginCheck, logout, tokenDelete} from "../../api";
import Logo from "../../picture/Logo.png";
import Complete from "./components/Complete";
import Component from "./components";
import Loading from "./components/Loading";
import Store from "../../context/store";

import moment from "moment-timezone";
import Login from "../../pages/Login";
import {MainContext} from "../../context";
import {Desktop, Mobile, Tablet} from "../../Responsive";


export default function Index(){

    const { toggleClose } = useContext(MainContext)

    const data = [{'1':15}, {'2':50}, {'3':30}, {'4':40},]

    let [startRecord,setStartRecord] = useState(false)

    let StoreLogin = Store().getLogin() === 'true'
    const storeData = Store().getLoginData()


    const open = moment().tz('Asia/Seoul')
    let wAge = ''
    let age = ''

    if(StoreLogin === true){
        wAge = open.format('YYYY') - storeData.birth.split('-')[0]
        if(open.format('MM-DD').split('-').join('') >= storeData.birth.split('-')[1]+storeData.birth.split('-')[2]){
            wAge = open.format('YYYY') - storeData.birth.split('-')[0]
        }else{
            wAge = open.format('YYYY') - storeData.birth.split('-')[0] - 1
        }

        age = open.format('YYYY') - storeData.birth.split('-')[0] + 1
    }else{
        alert('로그인 후 사용해 주세요.')
    }


    const [check,setCheck] = useState(false)
    const [stroke,setStroke] = useState(false)

    const checkStroke = (e) =>{
        e.preventDefault()
        setStroke(true)
    }




    useEffect(()=>{
        Store().delStorage('modifyInfo')
        tokenDelete()
            .then(res=>{
                console.log(res.data)
            })
            .catch(err=>{
                console.log(JSON.stringify(err.response.data))
            })

        loginCheck()
            .then(res=>{
                Store().setLogin(true)
            })
            .catch(err=>{
                Store().setLogin(false)
                alert('로그인 후 사용해주세요.')
                window.location.replace('/')
            })

    },[])

    return(
            <div onClick={toggleClose}>
                {StoreLogin ? (
                        <Layout>
                            <Desktop>
                                <div style={{
                                    backgroundImage: `url(${Background})`,
                                    backgroundSize: 'cover'
                                }}>
                                    <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                        <Box
                                            sx={{
                                                backgroundColor: 'white',
                                                width: 1000,
                                                border: 1,
                                                borderRadius: '2rem',
                                                marginTop: 10,
                                                marginBottom:20,
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}>
                                            <img alt="No Images" src={Logo}
                                                 style={{marginTop: 30, width: 350, height: 120}}/>
                                            <div style={{display: 'flex'}}>
                                                <Typography component="h4" variant="h8"
                                                            sx={{paddingTop: 3, paddingRight: 2}}>
                                                    이름 : {storeData.name}
                                                </Typography>
                                                <Typography component="h4" variant="h8"
                                                            sx={{paddingTop: 3, paddingRight: 2}}>
                                                    ||
                                                </Typography>
                                                <Typography component="h4" variant="h8"
                                                            sx={{paddingTop: 3, paddingRight: 2}}>
                                                    나이 (만 나이) : {age} ({wAge})
                                                </Typography>
                                                <Typography component="h4" variant="h8"
                                                            sx={{paddingTop: 3, paddingRight: 2}}>
                                                    ||
                                                </Typography>
                                                <Typography component="h4" variant="h8" sx={{paddingTop: 3}}>
                                                    생년월일 : {storeData.birth}
                                                </Typography>
                                            </div>
                                            {stroke ? (
                                                <>
                                                    <Complete data={data}/>
                                                </>
                                            ) : (
                                                <>
                                                    {check ? (
                                                        <>
                                                            <Loading checkStroke={checkStroke}/>
                                                        </>
                                                    ) : (
                                                        <>

                                                            <Component startRecord={startRecord}
                                                                   setStartRecord={setStartRecord}
                                                                       setCheck={setCheck}/>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                        <br/><br/>
                                    </div>
                                </div>
                            </Desktop>
                            <Tablet>
                                <div style={{
                                    backgroundImage: `url(${Background})`,
                                    backgroundSize: 'contain',
                                    paddingTop:100,
                                    paddingBottom:200
                                }}>
                                    <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                        <Box
                                            sx={{
                                                backgroundColor: 'white',
                                                width: 600,
                                                border: 1,
                                                borderRadius: '2rem',
                                                marginTop: 10,
                                                marginBottom:20,
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}>
                                            <img alt="No Images" src={Logo}
                                                 style={{marginTop: 30, width: 350, height: 120}}/>
                                            <div style={{display: 'flex'}}>
                                                <Typography component="h4" variant="h8"
                                                            sx={{paddingTop: 3, paddingRight: 2}}>
                                                    이름 : {storeData.name}
                                                </Typography>
                                                <Typography component="h4" variant="h8"
                                                            sx={{paddingTop: 3, paddingRight: 2}}>
                                                    ||
                                                </Typography>
                                                <Typography component="h4" variant="h8"
                                                            sx={{paddingTop: 3, paddingRight: 2}}>
                                                    나이 (만 나이) : {age} ({wAge})
                                                </Typography>
                                                <Typography component="h4" variant="h8"
                                                            sx={{paddingTop: 3, paddingRight: 2}}>
                                                    ||
                                                </Typography>
                                                <Typography component="h4" variant="h8" sx={{paddingTop: 3}}>
                                                    생년월일 : {storeData.birth}
                                                </Typography>
                                            </div>
                                            {stroke ? (
                                                <>
                                                    <Complete data={data}/>
                                                </>
                                            ) : (
                                                <>

                                                    {check ? (
                                                        <>
                                                            <Loading checkStroke={checkStroke}/>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Component setCheck={setCheck}
                                                                   startRecord={startRecord}
                                                                   setStartRecord={setStartRecord}/>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                        <br/><br/>
                                    </div>
                                </div>
                            </Tablet>
                            <Mobile>
                                <div style={{
                                    backgroundImage: `url(${Background})`,
                                    backgroundSize: 'cover'
                                }}>
                                    <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                        <Box
                                            sx={{
                                                backgroundColor: 'white',
                                                width: 350,
                                                border: 1,
                                                borderRadius: '2rem',
                                                margin: 3,
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}>
                                            <img alt="No Images" src={Logo}
                                                 style={{marginTop: 30, width: 220, height: 60}}/>

                                                <Typography component="h5" variant="h8"
                                                            sx={{paddingTop: 3}}>
                                                    이름 : {storeData.name}
                                                </Typography>

                                                <Typography component="h5" variant="h8"
                                                            sx={{paddingTop: 3}}>
                                                    나이(만 나이) : {age} ({wAge})
                                                </Typography>

                                                <Typography component="h5" variant="h8" sx={{paddingTop: 3}}>
                                                    생년월일 : {storeData.birth}
                                                </Typography>

                                            {stroke ? (
                                                <>
                                                    <Complete data={data}/>
                                                </>
                                            ) : (
                                                <>

                                                    {check ? (
                                                        <>
                                                            <Loading checkStroke={checkStroke}/>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Component setCheck={setCheck}
                                                                   startRecord={startRecord}
                                                                   setStartRecord={setStartRecord}/>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                        <br/><br/>
                                    </div>
                                </div>
                            </Mobile>
                    </Layout>
                ):(
                    <>
                        <Login />
                    </>
                )}
            </div>

    )
}