
import {Box, Button, Checkbox, Divider, FormControlLabel, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from 'react'
import Background from "../../../picture/adminLogin.jpeg";
import Logo from '../../../picture/Logo.png'
import {registerAgree} from "../../../api";
import Store from "../../../context/store";
import {Desktop, Mobile, Tablet} from "../../../Responsive";



export default function RegisterAgree(){

    const [checkList, setCheckList] = useState([]);


    const checkAll = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.checked
            ? setCheckList(["service","user"])
            : setCheckList([])
    }

    const check = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.checked
            ? setCheckList([...checkList, e.target.name])
            : setCheckList(checkList.filter((choice)=> choice !== e.target.name))
    }
    const isAllChecked = checkList.length ===2;
    const disabled = !isAllChecked


    const handleClick = ()=>{
        let data = {
            termsConditions:'Agree'
        }

        registerAgree(data)
            .then(res=>{
                alert(res.data)
                Store().setAgree(true)
                window.location.replace('/register')
            })
    }

    useEffect(()=>{
       Store().delStorage('agree')
    },[])



    return(
                <>
                    <Desktop>
                        <div style={{backgroundImage:`url(${Background})`,
                            backgroundSize:'cover'}}>
                            <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:400}}>
                                <Box
                                    sx={{
                                        marginTop: 20,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        backgroundColor: 'white',
                                        height: '500px',
                                        width: '400px',
                                        borderRadius:'0.5rem',
                                        opacity:0.9,
                                    }}
                                ><br/>
                                    <img alt="No Images" src={Logo} style={{width:350,height:110,paddingRight:30}}/>
                                    <Typography component="h1" variant="h5" sx={{paddingTop:1}}>
                                        약관동의
                                    </Typography><br/>
                                    <Divider color="#696969" sx={{ height: 2, width: '350px' }} />
                                    <Box component="form" noValidate sx={{mt:1}}>
                                        <br/>
                                        <FormControlLabel
                                            control={<Checkbox
                                                style={{fontWeight:2}}
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="all"
                                                onChange={checkAll}
                                                checked={checkList.length === 2 ? true : false}
                                                color="primary"/>}
                                            label={<Typography variant='body2' >이용약관 전체동의</Typography>}
                                        /><br/><br/>

                                        <FormControlLabel
                                            control={<Checkbox color="primary"
                                                               margin="normal"
                                                               required
                                                               fullWidth
                                                               name="service"
                                                               onChange={check}
                                                               checked={checkList.includes('service') ? true : false}/>}
                                            label={<Typography variant='body2' >서비스 이용약관에 동의합니다.(필수)</Typography>}
                                        /><br/>
                                        <FormControlLabel
                                            control={<Checkbox color="primary"
                                                               margin="normal"
                                                               required
                                                               fullWidth
                                                               name="user"
                                                               onChange={check}
                                                               checked={checkList.includes('user') ? true : false}/>}
                                            label={<Typography variant='body2' >개인정보 수집 및 이용에 동의합니다.(필수)</Typography>}
                                        /><br/><br/><br/>
                                        <Button disabled={disabled}
                                                autoFocus
                                                type="submit"
                                                onClick={handleClick}
                                                required
                                                href="/register"
                                                fullWidth
                                                style={
                                                    disabled
                                                        ?{backgroundColor:'#859594'}
                                                        :{backgroundColor:'#1e90ff'}
                                                }
                                        >
                                            <Typography variant='body2' color="primary.contrastText">
                                                다음</Typography>
                                        </Button><br/><br/>
                                        <Button
                                            href='/'
                                            required
                                            fullWidth
                                            style={{
                                                backgroundColor: '#1e90ff'
                                            }}
                                        >
                                            <Typography variant='body2' color="primary.contrastText">
                                                Home(뒤로가기)</Typography>
                                        </Button><br/><br/>

                                    </Box>

                                </Box>
                            </div>
                        </div>
                    </Desktop>
                    <Tablet>
                        <div style={{backgroundImage:`url(${Background})`,
                            backgroundSize:'contain'}}>
                            <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:600}}>
                                <Box
                                    sx={{
                                        marginTop: 20,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        backgroundColor: 'white',
                                        height: '500px',
                                        width: '400px',
                                        borderRadius:'0.5rem',
                                        opacity:0.9,
                                    }}
                                ><br/>
                                    <img alt="No Images" src={Logo} style={{width:350,height:110,paddingRight:30}}/>
                                    <Typography component="h1" variant="h5" sx={{paddingTop:1}}>
                                        약관동의
                                    </Typography><br/>
                                    <Divider color="#696969" sx={{ height: 2, width: '350px' }} />
                                    <Box component="form" noValidate sx={{mt:1}}>
                                        <br/>
                                        <FormControlLabel
                                            control={<Checkbox
                                                style={{fontWeight:2}}
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="all"
                                                onChange={checkAll}
                                                checked={checkList.length === 2 ? true : false}
                                                color="primary"/>}
                                            label={<Typography variant='body2' >이용약관 전체동의</Typography>}
                                        /><br/><br/>

                                        <FormControlLabel
                                            control={<Checkbox color="primary"
                                                               margin="normal"
                                                               required
                                                               fullWidth
                                                               name="service"
                                                               onChange={check}
                                                               checked={checkList.includes('service') ? true : false}/>}
                                            label={<Typography variant='body2' >서비스 이용약관에 동의합니다.(필수)</Typography>}
                                        /><br/>
                                        <FormControlLabel
                                            control={<Checkbox color="primary"
                                                               margin="normal"
                                                               required
                                                               fullWidth
                                                               name="user"
                                                               onChange={check}
                                                               checked={checkList.includes('user') ? true : false}/>}
                                            label={<Typography variant='body2' >개인정보 수집 및 이용에 동의합니다.(필수)</Typography>}
                                        /><br/><br/><br/>
                                        <Button disabled={disabled}
                                                autoFocus
                                                href="/register"
                                                type="submit"
                                                onClick={handleClick}
                                                required
                                                fullWidth
                                                style={
                                                    disabled
                                                        ?{backgroundColor:'#859594'}
                                                        :{backgroundColor:'#1e90ff'}
                                                }
                                        >
                                            <Typography variant='body2' color="primary.contrastText">
                                                다음</Typography>
                                        </Button><br/><br/>
                                        <Button
                                            href='/'
                                            required
                                            fullWidth
                                            style={{
                                                backgroundColor: '#1e90ff'
                                            }}
                                        >
                                            <Typography variant='body2' color="primary.contrastText">
                                                Home(뒤로가기)</Typography>
                                        </Button><br/><br/>

                                    </Box>

                                </Box>
                            </div>
                        </div>
                    </Tablet>
                    <Mobile>
                        <div style={{backgroundImage:`url(${Background})`,
                            backgroundSize:'contain'}}>
                            <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:400}}>
                                <Box
                                    sx={{
                                        marginTop: 20,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        backgroundColor: 'white',
                                        height: '500px',
                                        width: '350px',
                                        borderRadius:'0.5rem',
                                        opacity:0.9,
                                    }}
                                ><br/>
                                    <img alt="No Images" src={Logo} style={{width:280,paddingRight:30}}/>
                                    <Typography component="h1" variant="h5" sx={{paddingTop:1}}>
                                        약관동의
                                    </Typography><br/>
                                    <Divider color="#696969" sx={{ height: 2, width: '300px' }} />
                                    <Box component="form" noValidate sx={{mt:1}}>
                                        <br/>
                                        <FormControlLabel
                                            control={<Checkbox
                                                style={{fontWeight:2}}
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="all"
                                                onChange={checkAll}
                                                checked={checkList.length === 2 ? true : false}
                                                color="primary"/>}
                                            label={<Typography variant='body2' >이용약관 전체동의</Typography>}
                                        /><br/><br/>

                                        <FormControlLabel
                                            control={<Checkbox color="primary"
                                                               margin="normal"
                                                               required
                                                               fullWidth
                                                               name="service"
                                                               onChange={check}
                                                               checked={checkList.includes('service') ? true : false}/>}
                                            label={<Typography variant='body2' >서비스 이용약관에 동의합니다.(필수)</Typography>}
                                        /><br/>
                                        <FormControlLabel
                                            control={<Checkbox color="primary"
                                                               margin="normal"
                                                               required
                                                               fullWidth
                                                               name="user"
                                                               onChange={check}
                                                               checked={checkList.includes('user') ? true : false}/>}
                                            label={<Typography variant='body2' >개인정보 수집 및 이용에 동의합니다.(필수)</Typography>}
                                        /><br/><br/><br/><br/>
                                        <Button disabled={disabled}
                                                autoFocus
                                                href="/register"
                                                type="submit"
                                                onClick={handleClick}
                                                required
                                                fullWidth
                                                style={
                                                    disabled
                                                        ?{backgroundColor:'#859594'}
                                                        :{backgroundColor:'#1e90ff'}
                                                }
                                        >
                                            <Typography variant='body2' color="primary.contrastText">
                                                다음</Typography>
                                        </Button><br/><br/>
                                        <Button
                                            href='/'
                                            required
                                            fullWidth
                                            style={{
                                                backgroundColor: '#1e90ff'
                                            }}
                                        >
                                            <Typography variant='body2' color="primary.contrastText">
                                                Home(뒤로가기)</Typography>
                                        </Button><br/><br/>

                                    </Box>

                                </Box>
                            </div>
                        </div>
                    </Mobile>

                </>

    )
}