import {Box, Typography} from "@mui/material";
import Spinner from "../../../picture/spinner.gif";
import Button from "@mui/material/Button";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Desktop, Mobile, Tablet} from "../../../Responsive";
import {MainContext} from "../../../context";
import Count from "./Count";
import AudioRecord from "./Recording/AudioRecord";


export default function Index(props){

    const {startRecord,setStartRecord,setCheck} = props
    let [startCount, setStartCount] = useState(2)
    const [count,setCount] = useState(false)


    const savedCallback = useRef()

    function callback(){
        setStartCount(startCount - 1)
    }
    useEffect(()=>{
        savedCallback.current = callback;
    })
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        let id = setInterval(tick, 1000);

        if(startCount <= -1){
            clearInterval(id);
        }

    }, []);
    useEffect(()=>{
        if(startCount <= -1){
            setStartRecord(true)
        }
    },[startCount])



    return(
        <>
            <Desktop>
            {startRecord ? (
                <>
                    {count ? (
                        <>
                            <AudioRecord setCheck={setCheck} />
                        </>
                    ):(
                        <>
                            <Count count={count} setCount={setCount}/>
                        </>
                    )}

                </>
            ):(
                <>
                    <Typography component="h1" variant="h4" sx={{paddingTop:20}}>
                        잠시 후 녹음이 시작됩니다.
                    </Typography><br/><br/>
                    <Typography component="h1" variant="h4" sx={{paddingTop:5}}>
                        화면에 보이는 문장을
                    </Typography>
                    <Typography component="h1" variant="h4" sx={{paddingTop:5,paddingBottom:30}}>
                        또박또박 읽어주세요.
                    </Typography><br/><br/>
                </>
            )}

            </Desktop>
            <Tablet>
                {startRecord ? (
                    <>
                        {count ? (
                            <>
                                <AudioRecord setCheck={setCheck}/>
                            </>
                        ):(
                            <>
                                <Count count={count} setCount={setCount}/>
                            </>
                        )}

                    </>
                ):(
                    <>
                        <Typography component="h1" variant="h4" sx={{paddingTop:10}}>
                            잠시 후 녹음이 시작됩니다.
                        </Typography><br/>
                        <Typography component="h1" variant="h4" sx={{paddingTop:5}}>
                            화면에 보이는 문장을
                        </Typography>
                        <Typography component="h1" variant="h4" sx={{paddingTop:5,paddingBottom:10}}>
                            또박또박 읽어주세요.
                        </Typography><br/>
                    </>
                )}


            </Tablet>
            <Mobile>
                {startRecord ? (
                    <>
                        {count ? (
                            <>
                                <AudioRecord setCheck={setCheck} />
                            </>
                        ):(
                            <>
                                <Count count={count} setCount={setCount}/>
                            </>
                        )}

                    </>
                ):(
                    <>
                        <Typography component="h1" variant="h5" sx={{paddingTop:10}}>
                            잠시 후 녹음이 시작됩니다.
                        </Typography><br/>
                        <Typography component="h1" variant="h5" sx={{paddingTop:5}}>
                            화면에 보이는 문장을
                        </Typography>
                        <Typography component="h1" variant="h5" sx={{paddingTop:5,paddingBottom:10}}>
                            또박또박 읽어주세요.
                        </Typography><br/>
                    </>
                )}
            </Mobile>
        </>
    )
}