import React, {useState} from "react";
import styled from "styled-components";
import {Box, Button, FormHelperText, Grid, TextField, Typography} from "@mui/material";
import Logo from "../../../../../../picture/Logo.png";
import {logout, modifyUserId, modifyUserIdEmail} from "../../../../../../api";

const FormHelperTextsRED = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
  display: flex;
  align-items: center;
`;

const FormHelperTextsBLUE = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #0f27d9 !important;
`;


export default function UserIdModalComponent(props){


    const { data,sectionType }=props

    const [changeUserid, setChangeUserId] = useState("");
    const [EmailMessage, setEmailMessage] = useState("")
    const [IsEmail,setIsEmail] = useState(false)

    const onChangeEmail = (e) => {
        const currentEmail = e.currentTarget.value;
        setChangeUserId(currentEmail)
        const emailRegExp =
            /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;

        if (!emailRegExp.test(currentEmail)) {
            setEmailMessage("이메일의 형식이 올바르지 않습니다!");
            setIsEmail(false)
        } else {
            setEmailMessage("");
            setIsEmail(true)
        }
    };

    const [mail, setMail] = useState('')
    const onSendEmail = (e)=>{
        e.preventDefault()
        let newData = {
            emailSubject:'아이디(이메일) 변경',
            name:data.name,
            userId:data.userId,
            changeUserid:changeUserid
        }
        setMail(newData)
        modifyUserIdEmail(newData)
            .then(res=>{
                setMail(res.data)
                alert(res.data)
            })
            .catch(function (err){
                console.log(JSON.stringify(err.response.data))
                alert(err.response.data)
            })

    }

    const [signNum, setSignNum] = useState("");
    const onChangeSignHandler = (e) => {
        setSignNum(e.target.value);
    }



    const [res,setRes] = useState('')

    const [isLogout,setIsLogout] = useState(false)
    const [logoutUser,setLogoutUser] = useState({})

    const onSubmitHandler = (e) =>{
        e.preventDefault();
        let newData = {
            userId: data.userId,
            changeUserid: changeUserid,
            name:data.name,
            phone:data.phone,
            signNum:signNum
        }

        modifyUserId(newData)
            .then((res)=>{
                setRes(res.data)
                alert('이메일(아이디) 수정완료.')
                window.location.replace('/userInfo')

            })
            .catch(function (err){
                alert(JSON.stringify(err.response.data))
            })


    }



    return(
        <>
            {sectionType === 'mobile' ? (
                <>
                    <div style={{height: 350, width: '100%'}}>
                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                borderRadius: '1rem',
                            }}
                        ><br/>
                            <img alt="No Images" src={Logo} style={{width:270,height:70,paddingRight:30}}/><br/>

                            <Typography component="h4" variant="h0">
                                이메일 주소 변경
                            </Typography><br/><br/>

                                    <TextField
                                        required
                                        fullWidth
                                        type="text"
                                        name="userid"
                                        size="small"
                                        label='기존 이메일주소'
                                        defaultValue={data.userId}
                                        disabled
                                    />
                            <br/>
                                    <div style={{display:'flex'}}>
                                        <TextField
                                            autoFocus
                                            required
                                            fullWidth
                                            size="small"
                                            type="email"
                                            name="changeUserid"
                                            sx={{width:220}}
                                            label='변경하실 이메일주소(아이디)를 입력해주세요.'
                                            value={changeUserid}
                                            onChange={onChangeEmail}
                                            error={EmailMessage === "이메일의 형식이 올바르지 않습니다!" || false}
                                        />

                                        <Button
                                            onClick={onSendEmail}
                                            variant="contained"
                                            type="submit"
                                            size="small"
                                            sx={{
                                                marginLeft:1,
                                                width:'88px',
                                                height:'40px',
                                                border:3,
                                                "&.MuiButton-root:hover":{
                                                    color:'#008DDC',
                                                    backgroundColor:'#c7ebff',
                                                    borderColor:'#008DDC'
                                                }
                                            }}
                                            disabled={changeUserid.length<10}
                                        >
                                            인증받기
                                        </Button>
                                    </div>


                                <br/>

                                    <FormHelperTextsRED>{EmailMessage}</FormHelperTextsRED>

                                    <TextField
                                        required
                                        fullWidth
                                        size="small"
                                        type="text"
                                        name="signNum"
                                        label='인증번호'
                                        value={signNum}
                                        onChange={onChangeSignHandler}
                                    />
                            <br/>

                                    <Button
                                        onClick={onSubmitHandler}
                                        variant="contained"
                                        type="submit"
                                        size="small"
                                        fullWidth
                                        sx={{
                                            marginTop: 1,
                                            height: '45px',
                                            border: 3,
                                            "&.MuiButton-root:hover": {
                                                color: '#008DDC',
                                                backgroundColor: '#c7ebff',
                                                borderColor: '#008DDC'
                                            }
                                        }}
                                        disabled={signNum.length<6 || false}
                                    >
                                        수정하기
                                    </Button>

                        </Box>
                    </div>
                </>
            ):(
                <>
                    <div style={{height: 500, width: '100%'}}>
                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                height: '500px',
                                width: '530px',
                                borderRadius: '1rem',
                            }}
                        ><br/>
                            <img alt="No Images" src={Logo} style={{width:350,height:110,paddingRight:30}}/><br/>

                            <Typography component="h4" variant="h0">
                                이메일 주소 변경
                            </Typography><br/><br/>
                            <Grid container spacing={1}>
                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <TextField
                                        required
                                        fullWidth
                                        type="text"
                                        name="userid"
                                        label='기존 이메일주소'
                                        defaultValue={data.userId}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={1}/>

                                <Grid item xs={12}/>
                                <Grid item xs={1}/>
                                <Grid item xs={7.5}>
                                    <TextField
                                        autoFocus
                                        required
                                        fullWidth
                                        type="email"
                                        name="changeUserid"
                                        label='변경하실 이메일주소(아이디)를 입력해주세요.'
                                        value={changeUserid}
                                        onChange={onChangeEmail}
                                        error={EmailMessage === "이메일의 형식이 올바르지 않습니다!" || false}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        onClick={onSendEmail}
                                        variant="contained"
                                        type="submit"
                                        size="medium"
                                        sx={{
                                            marginTop:0,
                                            width:'105px',
                                            height:'55px',
                                            border:3,
                                            "&.MuiButton-root:hover":{
                                                color:'#008DDC',
                                                backgroundColor:'#c7ebff',
                                                borderColor:'#008DDC'
                                            }
                                        }}
                                        disabled={changeUserid.length<10}
                                    >
                                        인증받기
                                    </Button>
                                </Grid>
                                <br/>
                                <Grid item xs={1}/>
                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <FormHelperTextsRED>{EmailMessage}</FormHelperTextsRED>
                                </Grid>

                                <Grid item xs={1}/>
                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <TextField
                                        required
                                        fullWidth
                                        type="text"
                                        name="signNum"
                                        label='인증번호'
                                        value={signNum}
                                        onChange={onChangeSignHandler}
                                    />
                                </Grid>
                                <Grid item xs={1}/>


                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <Button
                                        onClick={onSubmitHandler}
                                        variant="contained"
                                        type="submit"
                                        size="medium"
                                        fullWidth
                                        sx={{
                                            marginTop: 1,
                                            height: '45px',
                                            border: 3,
                                            "&.MuiButton-root:hover": {
                                                color: '#008DDC',
                                                backgroundColor: '#c7ebff',
                                                borderColor: '#008DDC'
                                            }
                                        }}
                                        disabled={signNum.length<6 || false}
                                    >
                                        수정하기
                                    </Button>
                                </Grid>
                            </Grid>

                        </Box>
                    </div>
                </>
            )}

        </>
    )
}