import React from 'react'
import { useRoutes } from "react-router-dom"
import Home from './pages/Home'
import Product from './component/Product/Product'
import Analyze from "./component/Analyze";
import RegisterAgree from "./pages/Users/RegisterAgree/RegisterAgree";
import Register from './pages/Users/Register/Register'
import Report from './component/Report'
import Index from "./component/Admin";
import Main from "./component/users/Modify/main"
import RequiredLogin from "./component/Required/RequiredLogin";
import AuthModify from './component/users/Modify'
import Login from "./pages/Login";
import FindId from './pages/Users/Find/id'
import FindPw from './pages/Users/Find/pw'
import Customer from './component/Customer'


export default function App() {
  return useRoutes([
    //Main
    {path:'/',element:<Home />},
    {path:'product',element:<Product />},
    {path:'analyze',element:<Analyze />},
    {path:'joinAgree',element:<RegisterAgree />},
    {path:'register',element:<Register />},
    {path:'reports', element:<Report />},
    {path:'adminPage', element:<Index />},
    {path:'userInfo', element:<Main />},
    {path:'required', element:<RequiredLogin />},
    {path:'infoAuth', element:<AuthModify />},
    {path:'authHome', element:<Login />},
    {path:'findId', element:<FindId />},
    {path:'findPwSelect', element:<FindPw />},
    {path:'customer', element:<Customer />}
      ]);
}
