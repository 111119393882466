import React, {useContext, useEffect, useState} from "react";
import Layout from "../../../pages/containers/Layout";
import {authInfoCheck, getAccessTokenData, loginCheck} from "../../../api";
import Background from "../../../picture/back.jpeg";
import {Box, Typography} from "@mui/material";
import Logo from "../../../picture/Logo.png";
import NameModify from "./service/NameModify";
import UserIdModify from "./service/UserIdModify";
import PhoneModify from "./service/PhoneModify";
import PasswordModify from "./service/PasswordModify";
import WithdrawalUser from "./service/WithdrawalUser";
import Index from "./index";
import BirthModify from "./service/BirthModify";
import {MainContext} from "../../../context";
import {Desktop, Mobile, Tablet} from "../../../Responsive";
import Login from "../../../pages/Login";
import Store from "../../../context/store";



export default function Main(){

    const { toggleClose } = useContext(MainContext)
    let StoreLogin = Store().getLogin() === 'true'
    let data = Store().getLoginData()

    const AuthInfo = localStorage.getItem('modifyInfo') === 'true'


    useEffect(()=>{
        loginCheck()
            .then(res=>{
                Store().setLogin(true)
            })
            .catch(err=>{
                Store().setLogin(false)
            })

    },[])


    return(
        <>
            {StoreLogin ? (
                <Layout>
                    {AuthInfo ? (
                        <>
                            <Desktop>
                                <div style={{backgroundImage:`url(${Background})`,
                                    backgroundSize:'cover'}} onClick={toggleClose}>
                                <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:140}}>
                                    <Box
                                        sx={{
                                            marginTop: 8,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            backgroundColor: 'white',
                                            width: '450px',
                                            borderRadius:'0.5rem',
                                        }}
                                    ><br/>
                                        <img alt="No Images" src={Logo} style={{width:350,height:110,paddingRight:30}}/><br/>
                                        <div style={{paddingBottom: 30}}>
                                            <Typography component="h3" variant="h7">
                                                계약자 정보 수정 / 회원 탈퇴
                                            </Typography>
                                        </div>

                                        <NameModify data={data} />

                                        <BirthModify />

                                        <UserIdModify data={data} />

                                        <PasswordModify data={data} />

                                        <PhoneModify data={data} />

                                        <WithdrawalUser data={data}/>
                                        <br/><br/>

                                    </Box>
                                </div>
                                </div>
                            </Desktop>
                            <Tablet>
                                <div style={{backgroundImage:`url(${Background})`,
                                    backgroundSize:'contain'}} onClick={toggleClose}>
                                <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingTop:50,paddingBottom:200}}>
                                    <Box
                                        sx={{
                                            marginTop: 8,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            backgroundColor: 'white',
                                            width: '450px',
                                            borderRadius:'0.5rem',
                                        }}
                                    ><br/>
                                        <img alt="No Images" src={Logo} style={{width:350,height:110,paddingRight:30}}/><br/>
                                        <div style={{paddingBottom: 30}}>
                                            <Typography component="h3" variant="h7">
                                                계약자 정보 수정 / 회원 탈퇴
                                            </Typography>
                                        </div>

                                        <NameModify data={data} />

                                        <BirthModify />

                                        <UserIdModify data={data} />

                                        <PasswordModify data={data} />

                                        <PhoneModify data={data} />

                                        <WithdrawalUser data={data}/>
                                        <br/><br/>

                                    </Box>
                                </div>
                                </div>
                            </Tablet>
                            <Mobile>
                                <div style={{backgroundImage:`url(${Background})`,
                                    backgroundSize:'contain'}} onClick={toggleClose}>

                                <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:140}}>
                                    <Box
                                        sx={{
                                            marginTop: 8,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            backgroundColor: 'white',
                                            width: '350px',
                                            borderRadius:'0.5rem',
                                        }}
                                    ><br/>
                                        <img alt="No Images" src={Logo} style={{width:270,height:80,paddingRight:30}}/><br/>
                                        <div style={{paddingBottom: 30}}>
                                            <Typography component="h4" variant="h7">
                                                계약자 정보 수정 / 회원 탈퇴
                                            </Typography>
                                        </div>

                                        <NameModify data={data} />

                                        <BirthModify />

                                        <UserIdModify data={data} />

                                        <PasswordModify data={data} />

                                        <PhoneModify data={data} />

                                        <WithdrawalUser data={data}/>
                                        <br/><br/>

                                    </Box>
                                </div>
                                </div>
                            </Mobile>

                        </>
                    ):(
                        <Index />
                    )}

                </Layout>
            ):(
                <>
                    <Login />
                </>
            )}
        </>
    )
}