import {Box, Button, createTheme, FormHelperText, Typography} from "@mui/material";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis} from "recharts";
import React, {useEffect, useState} from "react";
import {Desktop, Mobile, Tablet} from "../../../Responsive";
import Logo from "../../../picture/Logo.png";
import Strokes from "../../../picture/Stroke.jpeg"
import styled from "styled-components";

const FormHelperTextsRED = styled(FormHelperText)`
  width: 100%;
  padding-left: 55px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`;

export default function Complete(props){

    const { data } = props

    const [random,setRandom] = useState('')
    const [stroke,setStroke] = useState(false)

    useEffect(()=>{
        const dd = Math.floor(Math.random()*(3-1) + 1)
        if(dd === 1){
            setRandom('정상 입니다.')
            setStroke(false)
        }else{
            setRandom('STROKE가 의심 됩니다.')
            setStroke(true)
        }
    },[])

    return (
        <>
            <Desktop>
                <Typography component="h1" variant="h4" sx={{paddingTop: 7}}>
                    뇌졸증 분석 완료
                </Typography><br/>
                {stroke ? (
                    <>
                        <img alt="No Images" src={Strokes} style={{width:500,height:250,marginTop:10}}/>
                    </>
                ):(
                    <>
                    </>
                )}
                <Box
                    sx={{
                        backgroundColor:'white',
                        width:600,
                        border:1,
                        borderRadius:'1rem',
                        margin:10,
                        display:'flex',
                        alignItems:'center',
                        flexDirection:'column'
                    }}>
                    {stroke ? (
                        <>
                            <div style={{display:'flex',alignItems:'center',padding:10}}>
                                <Typography component="h1" variant="h5" sx={{paddingRight:1}}>
                                    RESULT :
                                </Typography>
                                <Typography component="h1" variant="h5" sx={{ color: 'red' }}>
                                    {random}
                                </Typography>
                            </div>
                        </>
                    ):(
                        <>
                            <Typography component="h1" variant="h5" sx={{padding:2}}>
                                RESULT : {random}
                            </Typography>
                        </>
                    )}
                </Box>
                <div style={{display:'flex',alignItems:'center',paddingBottom:50}}>
                    <Button
                        href='/'
                        variant="outlined"
                        type="submit"
                        size="large"
                        sx={{
                            marginRight:5,
                            width: 200,
                            height:'70px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            처음으로
                        </Typography>
                    </Button>

                    <Button
                        href='/reports'
                        variant="outlined"
                        type="submit"
                        size="large"
                        sx={{
                            marginRight:5,
                            width: 200,
                            height:'70px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            레포트 보기
                        </Typography>
                    </Button>

                    <Button
                        href='/analyze'
                        variant="outlined"
                        type="submit"
                        size="large"
                        sx={{
                            width: 200,
                            height:'70px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                        >

                        <div style={{display:'flex',alignItems:'center'}}>
                            <Typography component="h3" variant="h7" sx={{paddingRight:1}}>
                                세부분석 하기
                            </Typography>
                            <Typography component="h3" variant="h3">
                               ➠
                            </Typography>
                        </div>

                    </Button>
                </div>

            </Desktop>
            <Tablet>
                <Typography component="h1" variant="h5" sx={{paddingTop: 7}}>
                    뇌졸증 분석 완료
                </Typography><br/>
                {stroke ? (
                    <>
                        <img alt="No Images" src={Strokes} style={{width:380,height:180}}/>
                    </>
                ):(
                    <>
                    </>
                )}
                <Box
                    sx={{
                        backgroundColor:'white',
                        width:420,
                        border:1,
                        borderRadius:'1rem',
                        margin:5,
                        display:'flex',
                        alignItems:'center',
                        flexDirection:'column'
                    }}>
                    {stroke ? (
                        <>
                            <div style={{display:'flex',alignItems:'center',padding:10}}>
                                <Typography component="h1" variant="h6" sx={{paddingRight:1}}>
                                    RESULT :
                                </Typography>
                                <Typography component="h1" variant="h5" sx={{ color: 'red' }}>
                                    {random}
                                </Typography>
                            </div>
                        </>
                    ):(
                        <>
                            <Typography component="h1" variant="h5" sx={{padding:2}}>
                                RESULT : {random}
                            </Typography>
                        </>
                    )}
                </Box>

                <div style={{display:'flex',alignItems:'center',paddingBottom:50}}>
                    <Button
                        href='/'
                        variant="outlined"
                        type="submit"
                        size="large"
                        sx={{
                            marginRight:2,
                            width: 150,
                            height:'70px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            처음으로
                        </Typography>
                    </Button>

                    <Button
                        href='/reports'
                        variant="outlined"
                        type="submit"
                        size="large"
                        sx={{
                            marginRight:2,
                            width: 150,
                            height:'70px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            레포트 보기
                        </Typography>
                    </Button>

                    <Button
                        href='/analyze'
                        variant="outlined"
                        type="submit"
                        size="large"
                        sx={{
                            width: 200,
                            height:'70px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >

                        <div style={{display:'flex',alignItems:'center'}}>
                            <Typography component="h3" variant="h7" sx={{paddingRight:1}}>
                                세부분석 하기
                            </Typography>
                            <Typography component="h3" variant="h3">
                                ➠
                            </Typography>
                        </div>

                    </Button>
                </div>

            </Tablet>
            <Mobile>
                <Typography component="h1" variant="h6" sx={{paddingTop: 3}}>
                    뇌졸증 분석 완료
                </Typography><br/>
                {stroke ? (
                    <>
                        <img alt="No Images" src={Strokes} style={{width:280,height:100}}/>
                    </>
                ):(
                    <>
                    </>
                )}
                <Box
                    sx={{
                        backgroundColor:'white',
                        width:280,
                        border:1,
                        borderRadius:'1rem',
                        margin:5,
                        display:'flex',
                        alignItems:'center',
                        flexDirection:'column'
                    }}>
                    {stroke ? (
                        <>
                            <div style={{display:'flex',alignItems:'center',padding:10}}>
                            <Typography component="h5" variant="h8" sx={{paddingRight:1}}>
                                RESULT :
                            </Typography>
                                <Typography component="h5" variant="h7" sx={{ color: 'red' }}>
                                    {random}
                                </Typography>
                            </div>
                        </>
                    ):(
                        <>
                            <Typography component="h4" variant="h7" sx={{padding:2}}>
                                RESULT : {random}
                            </Typography>
                        </>
                    )}

                </Box>

                <div style={{display:'flex',alignItems:'center',paddingBottom:20}}>
                    <Button
                        href='/'
                        variant="outlined"
                        type="submit"
                        size="medium"
                        sx={{
                            marginRight:2,
                            width: 130,
                            height:'50px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            처음으로
                        </Typography>
                    </Button>

                    <Button
                        href='/reports'
                        variant="outlined"
                        type="submit"
                        size="medium"
                        sx={{
                            width: 130,
                            height:'50px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            레포트 보기
                        </Typography>
                    </Button>

                </div>
                <div style={{alignItems:'center',display:'flex',marginBottom:20}}>
                    <Button
                        href='/analyze'
                        variant="outlined"
                        type="submit"
                        size="large"
                        sx={{
                            width: 200,
                            height:'50px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >

                        <div style={{display:'flex',alignItems:'center'}}>
                            <Typography component="h3" variant="h7" sx={{paddingRight:1}}>
                                세부분석 하기
                            </Typography>
                            <Typography component="h3" variant="h3">
                                ➠
                            </Typography>
                        </div>

                    </Button>
                </div>


            </Mobile>

        </>
    );
}