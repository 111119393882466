import Background from "../../../../picture/adminLogin.jpeg";
import {Box, Button, TextField, Typography} from "@mui/material";
import Logo from "../../../../picture/Logo.png";
import React, {useEffect, useState} from "react";
import {findIdService, findInfoSMS, findPwService} from "../../../../api";
import ChangePwModal from "./modal";
import Store from "../../../../context/store";
import {Desktop, Mobile, Tablet} from "../../../../Responsive";


export default function FindPw(){

    const [userId, setUserId] = useState("");
    const [phone, setPhone] = useState("");
    const [authNum, setAuthNum]= useState("")

    const [EmailMessage, setEmailMessage] = useState("")
    const [PhoneMessage, setPhoneMessage] = useState("")
    const [isEmail, setIsEmail] = useState(false)
    const [isPhone,setIsPhone] = useState(false)
    const [isAuthNum, setIsAuthNum]= useState(false)
    const [isSendAuthNum, setIsSendAuthNum]= useState(false)
    const [reSend,setResend] =useState(0)


    let findData = Store().getFindData()
    useEffect(()=>{
        if(findData !== null){
            setUserId(findData)
            setIsEmail(true)
        }
    },[])

    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
        setUserId('')
        setPhone('')
        setAuthNum('')
        setResend(0)
        setIsEmail(false)
        setIsPhone(false)
        setIsAuthNum(false)
        setIsSendAuthNum(false)
    }

    const onChangeEmail = (e) => {
        const currentEmail = e.currentTarget.value;
        setUserId(currentEmail)
        const emailRegExp =
            /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;

        if (!emailRegExp.test(currentEmail)) {
            setEmailMessage("이메일의 형식이 올바르지 않습니다!");
            setIsEmail(false);
        } else {
            setEmailMessage("");
            setIsEmail(true);
        }
    };

    const onChangePhone = (getNumber) => {
        const currentPhone = getNumber;
        setPhone(currentPhone);
        const phoneRegExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

        if (!phoneRegExp.test(currentPhone)) {
            setPhoneMessage("핸드폰 번호를 입력해 주세요!");
            setIsPhone(false);
        } else {
            setPhoneMessage("");
            setIsPhone(true);
        }
    };
    const addHyphen = (e) => {
        const currentNumber = e.currentTarget.value;
        setPhone(currentNumber);
        if (currentNumber.length === 3 || currentNumber.length === 8) {
            setPhone(currentNumber + "-");
            onChangePhone(currentNumber + "-");
        } else {
            onChangePhone(currentNumber);
        }
    };

    const onChangeAuthNum = (e) =>{
        const currentNumber = e.currentTarget.value;
        setAuthNum(currentNumber)
        if(currentNumber.length === 6){
            setIsAuthNum(true)
        }else{
            setIsAuthNum(false)
        }

    }


    const sendSmsHandler=(e)=>{
        e.preventDefault()
        let data ={
            userId:userId,
            phone:phone,
            phoneSubject:'비밀번호 찾기'
        }
        findInfoSMS(data)
            .then(res=>{
                alert(res.data)
                setIsSendAuthNum(true)
                setResend(1)
            })
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
                setIsSendAuthNum(false)
            })
    };
    const onSubmitHandler=(e)=>{
        e.preventDefault()
        let data = {
            authNum:authNum,
            userId:userId,
            phone:phone
        }
        findPwService(data)
            .then(res=>{
                setResend(0)
                openModal()
            })
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
            })

    }



    return(
        <>
            <Desktop>
                <div style={{backgroundImage:`url(${Background})`,
                    backgroundSize:'cover',opacity:7}}>
                    <div style={{alignItems:'revert-layer',display:'inline-table',flexDirection:'column',paddingBottom:400
                        ,position:'sticky',paddingLeft:100}}>

                        <div style={{display:'flex',alignItems:'flex-end',flexDirection:"column"}}>
                            <Box
                                component="form"
                                sx={{
                                    marginTop: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    width: '450px',
                                    borderRadius:'0.5rem',
                                    border:1,
                                    borderColor:'grey.500',
                                    opacity:0.8,

                                }}><br/>
                                <img alt="No Images" src={Logo} style={{width:350,height:110,paddingRight:30}}/><br/>
                                <Typography component="h1" variant="h5" sx={{paddingBottom:2}}>
                                    비밀번호 찾기
                                </Typography>

                                <TextField
                                    value={userId}
                                    disabled={isSendAuthNum === true}
                                    margin="normal"
                                    required
                                    sx={{width:360}}
                                    label="ID(E-Mail)"
                                    name="userId"
                                    autoFocus
                                    onChange={onChangeEmail}
                                    error={isEmail === false}
                                />

                                <div>
                                    <TextField
                                        value={phone}
                                        disabled={isSendAuthNum === true}
                                        onChange={addHyphen}
                                        margin="normal"
                                        name="phone"
                                        required
                                        sx={{width:220}}
                                        label="전화번호"
                                        error={isPhone === false}
                                    />
                                    {reSend === 1 ? (
                                        <>
                                            <Button
                                                disabled={isEmail === false || isPhone === false}
                                                onClick={sendSmsHandler}
                                                type="submit"
                                                variant="outlined"
                                                size="large"
                                                sx={{ mt: 2, mb: 2, ml:2,
                                                    width:130,
                                                    height:55,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                재전송
                                            </Button>
                                        </>
                                    ):(
                                        <>
                                            <Button
                                                disabled={isEmail === false || isPhone === false}
                                                onClick={sendSmsHandler}
                                                type="submit"
                                                variant="outlined"
                                                size="large"
                                                sx={{ mt: 2, mb: 2, ml:2,
                                                    width:130,
                                                    height:55,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                인증번호 전송
                                            </Button>
                                        </>
                                    )}
                                </div>

                                <TextField
                                    disabled={isSendAuthNum === false}
                                    onChange={onChangeAuthNum}
                                    value={authNum}
                                    margin="normal"
                                    required
                                    sx={{width:360}}
                                    label="인증번호"
                                    name="authNum"
                                />

                                <Button
                                    disabled={isAuthNum === false}
                                    onClick={onSubmitHandler}
                                    type="submit"
                                    variant="outlined"
                                    size="large"
                                    sx={{ mt: 2, mb: 2,
                                        width:360,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    비밀번호 찾기
                                </Button>
                                {modalOpen && <ChangePwModal open={modalOpen} close={closeModal} userId={userId} phone={phone} header="비밀번호 변경" />}

                                <Button
                                    href='/'
                                    variant="contained"
                                    required
                                    fullWidth
                                    size="large"
                                    style={{
                                        mt:3,
                                        mb:2,
                                        width:360,
                                        marginTop:2,
                                        marginBottom:10,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant='body2' color="primary.contrastText">
                                        Home (뒤로가기)</Typography>
                                </Button><br/>

                            </Box>
                        </div>
                    </div>
                </div>
            </Desktop>
            <Tablet>
                <div style={{backgroundImage:`url(${Background})`,
                    backgroundSize:'contain',opacity:7}}>
                    <div style={{alignItems:'revert-layer',display:'inline-table',flexDirection:'column',paddingBottom:600
                        ,position:'sticky',paddingLeft:100}}>

                        <div style={{display:'flex',alignItems:'flex-end',flexDirection:"column"}}>
                            <Box
                                component="form"
                                sx={{
                                    marginTop: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    width: '450px',
                                    borderRadius:'0.5rem',
                                    border:1,
                                    borderColor:'grey.500',
                                    opacity:0.8,

                                }}><br/>
                                <img alt="No Images" src={Logo} style={{width:350,height:110,paddingRight:30}}/><br/>
                                <Typography component="h1" variant="h5" sx={{paddingBottom:2}}>
                                    비밀번호 찾기
                                </Typography>

                                <TextField
                                    value={userId}
                                    disabled={isSendAuthNum === true}
                                    margin="normal"
                                    required
                                    sx={{width:360}}
                                    label="ID(E-Mail)"
                                    name="userId"
                                    autoFocus
                                    onChange={onChangeEmail}
                                    error={isEmail === false}
                                />

                                <div>
                                    <TextField
                                        value={phone}
                                        disabled={isSendAuthNum === true}
                                        onChange={addHyphen}
                                        margin="normal"
                                        name="phone"
                                        required
                                        sx={{width:220}}
                                        label="전화번호"
                                        error={isPhone === false}
                                    />
                                    {reSend === 1 ? (
                                        <>
                                            <Button
                                                disabled={isEmail === false || isPhone === false}
                                                onClick={sendSmsHandler}
                                                type="submit"
                                                variant="outlined"
                                                size="large"
                                                sx={{ mt: 2, mb: 2, ml:2,
                                                    width:130,
                                                    height:55,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                재전송
                                            </Button>
                                        </>
                                    ):(
                                        <>
                                            <Button
                                                disabled={isEmail === false || isPhone === false}
                                                onClick={sendSmsHandler}
                                                type="submit"
                                                variant="outlined"
                                                size="large"
                                                sx={{ mt: 2, mb: 2, ml:2,
                                                    width:130,
                                                    height:55,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                인증번호 전송
                                            </Button>
                                        </>
                                    )}
                                </div>

                                <TextField
                                    disabled={isSendAuthNum === false}
                                    onChange={onChangeAuthNum}
                                    value={authNum}
                                    margin="normal"
                                    required
                                    sx={{width:360}}
                                    label="인증번호"
                                    name="authNum"
                                />

                                <Button
                                    disabled={isAuthNum === false}
                                    onClick={onSubmitHandler}
                                    type="submit"
                                    variant="outlined"
                                    size="large"
                                    sx={{ mt: 2, mb: 2,
                                        width:360,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    비밀번호 찾기
                                </Button>
                                {modalOpen && <ChangePwModal open={modalOpen} close={closeModal} userId={userId} phone={phone} header="비밀번호 변경" />}

                                <Button
                                    href='/'
                                    variant="contained"
                                    required
                                    fullWidth
                                    size="large"
                                    style={{
                                        mt:3,
                                        mb:2,
                                        width:360,
                                        marginTop:2,
                                        marginBottom:10,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant='body2' color="primary.contrastText">
                                        Home (뒤로가기)</Typography>
                                </Button><br/>

                            </Box>
                        </div>
                    </div>
                </div>
            </Tablet>
            <Mobile>
                <div style={{backgroundImage:`url(${Background})`,
                    backgroundSize:'contain',opacity:7}}>
                    <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:500
                        }}>

                        <div style={{display:'flex',alignItems:'flex-end',flexDirection:"column"}}>
                            <Box
                                component="form"
                                sx={{
                                    marginTop: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    width: '350px',
                                    borderRadius:'0.5rem',
                                    border:1,
                                    borderColor:'grey.500',
                                    opacity:0.8,

                                }}><br/>
                                <img alt="No Images" src={Logo} style={{width:280,paddingRight:30}}/><br/>
                                <Typography component="h1" variant="h5" sx={{paddingBottom:2}}>
                                    비밀번호 찾기
                                </Typography>

                                <TextField
                                    value={userId}
                                    disabled={isSendAuthNum === true}
                                    margin="normal"
                                    required
                                    size='small'
                                    sx={{width:300}}
                                    label="ID(E-Mail)"
                                    name="userId"
                                    autoFocus
                                    onChange={onChangeEmail}
                                    error={isEmail === false}
                                />

                                <div>
                                    <TextField
                                        value={phone}
                                        disabled={isSendAuthNum === true}
                                        onChange={addHyphen}
                                        margin="normal"
                                        name="phone"
                                        required
                                        size='small'
                                        sx={{width:200}}
                                        label="전화번호"
                                        error={isPhone === false}
                                    />
                                    {reSend === 1 ? (
                                        <>
                                            <Button
                                                disabled={isEmail === false || isPhone === false}
                                                onClick={sendSmsHandler}
                                                type="submit"
                                                variant="outlined"
                                                size="small"
                                                sx={{ mt: 2, mb: 2, ml:2,
                                                    width:100,
                                                    height:40,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                재전송
                                            </Button>
                                        </>
                                    ):(
                                        <>
                                            <Button
                                                disabled={isEmail === false || isPhone === false}
                                                onClick={sendSmsHandler}
                                                type="submit"
                                                variant="outlined"
                                                size="small"
                                                sx={{ mt: 2, mb: 2, ml:1,
                                                    width:100,
                                                    height:40,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                인증번호 전송
                                            </Button>
                                        </>
                                    )}
                                </div>

                                <TextField
                                    disabled={isSendAuthNum === false}
                                    onChange={onChangeAuthNum}
                                    value={authNum}
                                    margin="normal"
                                    required
                                    size='small'
                                    sx={{width:300}}
                                    label="인증번호"
                                    name="authNum"
                                />

                                <Button
                                    disabled={isAuthNum === false}
                                    onClick={onSubmitHandler}
                                    type="submit"
                                    variant="outlined"
                                    size="small"
                                    sx={{ mt: 2, mb: 2,
                                        width:300,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    비밀번호 찾기
                                </Button>
                                {modalOpen && <ChangePwModal open={modalOpen} close={closeModal} userId={userId} phone={phone} header="비밀번호 변경" />}

                                <Button
                                    href='/'
                                    variant="contained"
                                    required
                                    fullWidth
                                    size="small"
                                    style={{
                                        mt:3,
                                        mb:2,
                                        width:300,
                                        marginTop:2,
                                        marginBottom:10,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant='body2' color="primary.contrastText">
                                        Home (뒤로가기)</Typography>
                                </Button><br/>

                            </Box>
                        </div>
                    </div>
                </div>
            </Mobile>

        </>
    )
}