import styled from "styled-components";
import {
    Box, Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel, MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import Logo from "../../../../picture/Logo.png";
import React, {useState} from "react";
import { registerAdmin} from "../../../../api";
import moment from "moment-timezone";

const FormHelperTextsRED = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
  display: flex;
  align-items: center;
`;

const FormHelperTextsBLUE = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #0f27d9 !important;
`;


export default function AdminRegisterModalComponent(props){

    const { sizeType }=props

    const checkList = ['O','X']

    const [name, setName] = useState('')
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [admin, setAdmin] = useState('')
    const [startUp, setStartUp] = useState('')


    const [NameMessage, setNameMessage] = useState('')
    const [EmailMessage, setEmailMessage] = useState("")
    const [PwMessage, setPwMessage] = useState("")
    const [PhoneMessage, setPhoneMessage] = useState("")


    const [isName, setIsName] = useState(false)
    const [isEmail, setIsEmail] = useState(false)
    const [isPw, setIsPw] = useState(false)
    const [isPhone,setIsPhone] = useState(false)
    const [isAdmin,setIsAdmin] = useState(false)
    const [isStartUp,setIsStartUp] = useState(false)


    const now = new Date();
    const open = moment().tz('Asia/Seoul')
    const formYear = open.format(`YYYY`)

    const [form, setForm] = useState({
        year: formYear,
        month: "01",
        day: "01",
    });

    let years = [];
    for (let y = now.getFullYear(); y >= 1900; y -= 1) {
        years.push(y);
    }

    let month = [];
    for (let m = 1; m <= 12; m += 1) {
        if (m < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 월에 0을 붙혀준다
            month.push("0" + m.toString());
        } else {
            month.push(m.toString());
        }
    }
    let days = [];
    let date = new Date(form.year, form.month, 0).getDate();
    for (let d = 1; d <= date; d += 1) {
        if (d < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 일에 0을 붙혀준다
            days.push("0" + d.toString());
        } else {
            days.push(d.toString());
        }
    }


    const onChangeName = (e) => {
        const currentName = e.currentTarget.value;
        setName(currentName)
        const nameRegExp = /^[가-힣a-zA-Z]+$/;

        if (!nameRegExp.test(currentName) || name.length<1) {
            setNameMessage('이름을 두 글자 이상 입력하세요!');
            setIsName(false)
        } else {
            setNameMessage('');
            setIsName(true)
        }
    }

    const onChangeEmail = (e) => {
        const currentEmail = e.currentTarget.value;
        setUserId(currentEmail)
        const emailRegExp =
            /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;

        if (!emailRegExp.test(currentEmail)) {
            setEmailMessage("이메일의 형식이 올바르지 않습니다!");
            setIsEmail(false)
        } else {
            setEmailMessage("");
            setIsEmail(true)
        }
    };

    const onChangePassword = (e) => {
        const currentPw = e.currentTarget.value;
        setPassword(currentPw);
        const passwordRegExp =
            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
        if (!passwordRegExp.test(currentPw)) {
            setPwMessage(
                "숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"
            );
            setIsPw(false);
        } else {
            setPwMessage("");
            setIsPw(true);
        }
    };


    const onChangePhone = (getNumber) => {
        const currentPhone = getNumber;
        setPhone(currentPhone);
        const phoneRegExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

        if (!phoneRegExp.test(currentPhone)) {
            setPhoneMessage("핸드폰 번호를 입력해 주세요!");
            setIsPhone(false);
        } else {
            setPhoneMessage("");
            setIsPhone(true);
        }
    };

    const addHyphen = (e) => {
        const currentNumber = e.currentTarget.value;
        setPhone(currentNumber);
        if (currentNumber.length === 3 || currentNumber.length === 8) {
            setPhone(currentNumber + "-");
            onChangePhone(currentNumber + "-");
        } else {
            onChangePhone(currentNumber);
        }
    };


    const onAdmin = (e)=>{
        setAdmin(e.target.value)
        setIsAdmin(true)
    }

    const onStartUp = (e)=>{
        setStartUp(e.target.value)
        setIsStartUp(true)
    }


    const onRegister = (e) =>{
        e.preventDefault()
        let data = {
            name:name,
            userId:userId,
            birth:`${form.year}-${form.month}-${form.day}`,
            password:password,
            phone:phone,
            admin:admin === 'O' ? true:false,
            start_up:startUp === 'O' ? true:false
        }

        registerAdmin(data)
            .then(res=>{
                alert('회원가입 성공')
                window.location.replace('/adminPage')
            })
            .catch(function (err){
                alert(JSON.stringify(err.response.data))
            })

    }



    return(
        <>
            {sizeType === 'mobile' ? (
                <>
                    <div style={{height: 350, width: '100%'}}>
                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                borderRadius: '1rem',
                            }}
                        >
                            <img alt="No Images" src={Logo} style={{width:180}}/>

                            <Typography component="h4" variant="h0">
                                신규가입(관리자용)
                            </Typography><br/>

                            <div style={{paddingLeft:10}}>
                                <FormControl sx={{marginTop: 0.1, minWidth: 70,paddingRight:3}} variant="standard">
                                    <InputLabel id="demo-simple-select-label">생년</InputLabel>
                                    <Select
                                        value={form.year}
                                        onChange={(e)=>
                                            setForm({ ...form, year:e.target.value})}
                                        labelId="demo-simple-select-label"
                                        autoWidth
                                        sx={{height: '31px', width: '70px'}}
                                    >
                                        <MenuItem value="">
                                            <em>생년</em>
                                        </MenuItem>
                                        {years.map((item) => (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                            >
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{marginTop: 0.1, minWidth: 70,paddingRight:3}} variant="standard">
                                    <InputLabel id="demo-simple-select-label">월</InputLabel>
                                    <Select
                                        value={form.month}
                                        onChange={(e)=>
                                            setForm({ ...form, month:e.target.value})}
                                        labelId="demo-simple-select-label"
                                        autoWidth
                                        sx={{height: '31px', width: '70px'}}
                                    >
                                        <MenuItem value="">
                                            <em>월</em>
                                        </MenuItem>
                                        {month.map((item) => (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                            >
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{marginTop: 0.1, minWidth: 100}} variant="standard">
                                    <InputLabel id="demo-simple-select-label">일</InputLabel>
                                    <Select
                                        value={form.day}
                                        onChange={(e)=>{
                                            setForm({ ...form, day:e.target.value})
                                        }}
                                        labelId="demo-simple-select-label"
                                        autoWidth
                                        sx={{height: '31px', width: '70px'}}
                                    >
                                        <MenuItem value="">
                                            <em>일</em>
                                        </MenuItem>
                                        {days.map((item) => (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                            >
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <TextField
                                onChange={onChangeName}
                                autoFocus
                                required
                                fullWidth
                                size="small"
                                type="text"
                                sx={{marginBottom:1,marginTop:2}}
                                name="name"
                                label='이름'
                                error={NameMessage === '이름을 두 글자 이상 입력하세요!'}
                            />

                            <FormHelperTextsRED>{NameMessage}</FormHelperTextsRED>



                                    <TextField
                                        onChange={onChangeEmail}
                                        required
                                        fullWidth
                                        size="small"
                                        sx={{marginBottom:1}}
                                        type="text"
                                        name="userId"
                                        label='아이디'
                                        error={EmailMessage==='이메일의 형식이 올바르지 않습니다!'}
                                    />

                                    <FormHelperTextsRED>{EmailMessage}</FormHelperTextsRED>

                                    <TextField
                                        onChange={onChangePassword}
                                        required
                                        fullWidth
                                        value={password}
                                        sx={{marginBottom:1}}
                                        type="password"
                                        size="small"
                                        name="password"
                                        label="비밀번호 (숫자+영문자+특수문자 8자리 이상)"
                                        error={PwMessage === '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!'}
                                    />

                                    <FormHelperTextsRED>{PwMessage}</FormHelperTextsRED>

                                    <TextField
                                        required
                                        value={phone}
                                        size="small"
                                        fullWidth
                                        sx={{marginBottom:1}}
                                        type="text"
                                        onChange={addHyphen}
                                        name="phone"
                                        label="전화번호( - 빼고 기입)"
                                        error={PhoneMessage === '핸드폰 번호를 입력해 주세요!'}
                                    />

                                    <FormHelperTextsRED>{PhoneMessage}</FormHelperTextsRED>

                                    <FormControl fullWidth >
                                        <InputLabel>관리자</InputLabel>
                                        <Select
                                            size="small"
                                            sx={{marginBottom:1}}
                                            onChange={onAdmin}
                                            rowsPerPageOptions
                                            name="admin"
                                            type='text'
                                            input={<OutlinedInput label="name" />}
                                        >
                                            {checkList.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth >
                                        <InputLabel>개통여부</InputLabel>
                                        <Select
                                            onChange={onStartUp}
                                            rowsPerPageOptions
                                            size="small"
                                            sx={{marginBottom:1}}
                                            name="startUp"
                                            type='text'
                                            input={<OutlinedInput label="name" />}
                                        >
                                            {checkList.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <Button
                                        disabled={ isName === false ||  isEmail === false || isPw === false ||
                                            isPhone === false  || isAdmin === false || isStartUp === false}
                                        onClick={onRegister}
                                        variant="contained"
                                        type="submit"
                                        size="medium"
                                        fullWidth
                                        sx={{
                                            marginTop: 2,
                                            height: '45px',
                                            border: 3,
                                            "&.MuiButton-root:hover": {
                                                color: '#008DDC',
                                                backgroundColor: '#c7ebff',
                                                borderColor: '#008DDC'
                                            }
                                        }}

                                    >
                                        신규가입
                                    </Button>


                        </Box>
                    </div>
                </>
            ):(
                <>
                    <div style={{height: 600, width: '100%'}}>
                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                height: '520px',
                                width: '530px',
                                borderRadius: '1rem',
                            }}
                        ><br/>
                            <img alt="No Images" src={Logo} style={{width:200}}/>

                            <Typography component="h4" variant="h0">
                                신규가입(관리자용)
                            </Typography><br/>

                            <Grid container spacing={1}>

                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <div style={{paddingLeft:10,alignItems:'center',display:'flex'}}>
                                        <FormControl sx={{marginTop: 0.1, minWidth: 70,paddingRight:5}} variant="standard">
                                            <InputLabel id="demo-simple-select-label">생년</InputLabel>
                                            <Select
                                                value={form.year}
                                                onChange={(e)=>
                                                    setForm({ ...form, year:e.target.value})}
                                                labelId="demo-simple-select-label"
                                                autoWidth
                                                sx={{height: '31px', width: '80px'}}
                                            >
                                                <MenuItem value="">
                                                    <em>생년</em>
                                                </MenuItem>
                                                {years.map((item) => (
                                                    <MenuItem
                                                        key={item}
                                                        value={item}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <FormControl sx={{marginTop: 0.1, minWidth: 70,paddingRight:5}} variant="standard">
                                            <InputLabel id="demo-simple-select-label">월</InputLabel>
                                            <Select
                                                value={form.month}
                                                onChange={(e)=>
                                                    setForm({ ...form, month:e.target.value})}
                                                labelId="demo-simple-select-label"
                                                autoWidth
                                                sx={{height: '31px', width: '80px'}}
                                            >
                                                <MenuItem value="">
                                                    <em>월</em>
                                                </MenuItem>
                                                {month.map((item) => (
                                                    <MenuItem
                                                        key={item}
                                                        value={item}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <FormControl sx={{marginTop: 0.1, minWidth: 100}} variant="standard">
                                            <InputLabel id="demo-simple-select-label">일</InputLabel>
                                            <Select
                                                value={form.day}
                                                onChange={(e)=>{
                                                    setForm({ ...form, day:e.target.value})
                                                }}
                                                labelId="demo-simple-select-label"
                                                autoWidth
                                                sx={{height: '31px', width: '80px'}}
                                            >
                                                <MenuItem value="">
                                                    <em>일</em>
                                                </MenuItem>
                                                {days.map((item) => (
                                                    <MenuItem
                                                        key={item}
                                                        value={item}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <br/>
                                <Grid item xs={1}/>

                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                </Grid>
                                <Grid item xs={1}/><br/>

                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <TextField
                                        onChange={onChangeName}
                                        autoFocus
                                        required
                                        fullWidth
                                        type="text"
                                        name="name"
                                        label='이름'
                                        error={NameMessage === '이름을 두 글자 이상 입력하세요!'}
                                    /><br/>
                                </Grid>
                                <br/>
                                <Grid item xs={1}/>

                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <FormHelperTextsRED>{NameMessage}</FormHelperTextsRED>
                                </Grid>
                                <Grid item xs={1}/>


                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <TextField
                                        onChange={onChangeEmail}
                                        required
                                        fullWidth
                                        type="text"
                                        name="userId"
                                        label='아이디'
                                        error={EmailMessage==='이메일의 형식이 올바르지 않습니다!'}
                                    /><br/>
                                </Grid>
                                <br/>
                                <Grid item xs={1}/>

                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <FormHelperTextsRED>{EmailMessage}</FormHelperTextsRED>
                                </Grid>
                                <Grid item xs={1}/>

                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <TextField
                                        onChange={onChangePassword}
                                        required
                                        fullWidth
                                        value={password}
                                        type="password"
                                        name="password"
                                        label="비밀번호 (숫자+영문자+특수문자 8자리 이상)"
                                        error={PwMessage === '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!'}
                                    />
                                </Grid>
                                <Grid item xs={1}/>

                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <FormHelperTextsRED>{PwMessage}</FormHelperTextsRED>
                                </Grid>
                                <Grid item xs={1}/>

                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <TextField
                                        required
                                        value={phone}
                                        fullWidth
                                        type="text"
                                        onChange={addHyphen}
                                        name="phone"
                                        label="전화번호( - 빼고 기입)"
                                        error={PhoneMessage === '핸드폰 번호를 입력해 주세요!'}
                                    /><br/>
                                </Grid>
                                <br/>
                                <Grid item xs={1}/>

                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <FormHelperTextsRED>{PhoneMessage}</FormHelperTextsRED>
                                </Grid>
                                <Grid item xs={1}/>

                                <Grid item xs={1}/>
                                <Grid item xs={5}>
                                    <FormControl fullWidth >
                                        <InputLabel>관리자</InputLabel>
                                        <Select
                                            onChange={onAdmin}
                                            rowsPerPageOptions
                                            name="admin"
                                            type='text'
                                            input={<OutlinedInput label="name" />}
                                        >
                                            {checkList.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={5}>
                                    <FormControl fullWidth >
                                        <InputLabel>개통여부</InputLabel>
                                        <Select
                                            onChange={onStartUp}
                                            rowsPerPageOptions
                                            name="startUp"
                                            type='text'
                                            input={<OutlinedInput label="name" />}
                                        >
                                            {checkList.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}/>

                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <Button
                                        disabled={ isName === false ||  isEmail === false || isPw === false ||
                                            isPhone === false  || isAdmin === false || isStartUp === false}
                                        onClick={onRegister}
                                        variant="contained"
                                        type="submit"
                                        size="medium"
                                        fullWidth
                                        sx={{
                                            marginTop: 1,
                                            height: '45px',
                                            border: 3,
                                            "&.MuiButton-root:hover": {
                                                color: '#008DDC',
                                                backgroundColor: '#c7ebff',
                                                borderColor: '#008DDC'
                                            }
                                        }}

                                    >
                                        신규가입
                                    </Button>
                                </Grid>
                            </Grid>

                        </Box>
                    </div>
                </>
            )}

        </>
    )
}