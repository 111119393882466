import {useEffect, useState} from "react";
import {getData} from "../../../../../api";
import {Box, Typography} from "@mui/material";
import React from 'react';
import { BarChart, Bar,  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Text from "./text";
import moment from "moment-timezone";
import {Desktop, Mobile, Tablet} from "../../../../../Responsive";


export default function Index(){

    const [database, setDataBase] = useState({})

    const numerical = Number(database.numerical)
    const stuttering = Number(database.stuttering)
    const pronunciation = Number(database.pronunciation)


    let numericalScore;
    let stutteringScore;
    let pronunciationScore;

    let now = moment().tz('Asia/Seoul').format('YYYY년 MM월 DD일')

    if(stuttering >= 10){
        stutteringScore = 30
    }else if(stuttering >= 6){
        stutteringScore = 60
    }else{
        stutteringScore = 100
    }

    if(pronunciation >= 10){
        pronunciationScore = 30
    }else if(pronunciation >= 6){
        pronunciationScore = 60
    }else{
        pronunciationScore = 100
    }

    numericalScore = (stutteringScore + pronunciationScore) / 2




    const data = [
        {
            subject: '뇌졸증 수치 (평균)',
            A: numericalScore,
            fullMark: 100,
        },
        {
            subject: '말 버벅임',
            A: stutteringScore,
            fullMark: 100,
        },
        {
            subject: '발음',
            A: pronunciationScore,
            fullMark: 100,
        },
    ];


    useEffect(()=>{
        getData()
            .then(res=>{
                setDataBase(res.data)
            })
            .catch(err=>{
                console.log(JSON.stringify(err))
            })

    },[])


    return(
        <>
            <Desktop>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <Typography component="h1" variant="h5" >
                        당일 뇌졸증 수치 점수는 {numericalScore}점 입니다. ( {now} )
                    </Typography><br/>

                    <div style={{width:750,height:380,marginTop:5}}>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                    top: 10,
                                    left: 3,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="subject" />
                                <YAxis domain={[0, 100]}/>
                                <Tooltip />
                                <Bar dataKey="A" name="score" barSize={50} fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div><br/><br/>
                    <Text numerical={numericalScore} stuttering={stuttering} pronunciation={pronunciation}/>
                    <br/><br/>

                </div>
            </Desktop>
            <Tablet>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <Typography component="h1" variant="h5" >
                        당일 뇌졸증 수치 점수는 {numericalScore}점 입니다. ( {now} )
                    </Typography><br/>

                    <div style={{width:600,height:350,marginTop:5}}>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                    top: 10,
                                    left: 2,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="subject" />
                                <YAxis domain={[0, 100]}/>
                                <Tooltip />
                                <Bar dataKey="A" name="score" barSize={50} fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div><br/><br/>
                    <Text numerical={numericalScore} stuttering={stuttering} pronunciation={pronunciation}/>
                    <br/>
                </div>
            </Tablet>
            <Mobile>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <Typography component="h5" variant="h8" >
                        당일 뇌졸증 수치 점수는 {numericalScore}점 입니다.
                    </Typography><br/>
                    <Typography component="h5" variant="h8" >
                         {now}
                    </Typography><br/>

                    <div style={{width:350,height:300,marginTop:5,marginRight:25}}>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={300}
                                height={300}
                                data={data}
                                margin={{
                                    top: 10,
                                    left: 3,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="subject" />
                                <YAxis domain={[0, 100]}/>
                                <Tooltip />
                                <Bar dataKey="A" name="score" barSize={50} fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div><br/><br/>
                    <Text numerical={numericalScore} stuttering={stuttering} pronunciation={pronunciation}/>
                    <br/>
                </div>
            </Mobile>

        </>

    )
}