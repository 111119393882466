import React, {useState} from "react";
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import Logo from "../../../../../../picture/Logo.png";
import styled from "styled-components";
import {modifyBirth, modifyBirthSMS} from "../../../../../../api";
import moment from "moment-timezone";
import Store from "../../../../../../context/store";

const FormHelperTextsRED = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
  display: flex;
  align-items: center;
`;

const FormHelperTextsBLUE = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #0f27d9 !important;
`;

export default function BirthModalComponent(props){

    const { sectionType }=props

    const storeData = Store().getLoginData()
    const [authNum, setAuthNum] = useState('')
    const [isPhone,setIsPhone] = useState(false)
    const [isAuthNum, setIsAuthNum]= useState(false)
    const [isSendAuth, setIsSendAuth]= useState(false)
    const [PhoneMessage, setPhoneMessage] = useState("")
    const [year,setYears] = useState(false)


    const now = new Date();
    const open = moment().tz('Asia/Seoul')
    const formYear = open.format(`YYYY`)

    const [form, setForm] = useState({
        year: storeData.birth.split('-')[0],
        month: storeData.birth.split('-')[1],
        day: storeData.birth.split('-')[2],
    });


    let years = [];
    for (let y = now.getFullYear(); y >= 1900; y -= 1) {
        years.push(y);
    }

    let month = [];
    for (let m = 1; m <= 12; m += 1) {
        if (m < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 월에 0을 붙혀준다
            month.push("0" + m.toString());
        } else {
            month.push(m.toString());
        }
    }
    let days = [];
    let date = new Date(form.year, form.month, 0).getDate();
    for (let d = 1; d <= date; d += 1) {
        if (d < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 일에 0을 붙혀준다
            days.push("0" + d.toString());
        } else {
            days.push(d.toString());
        }
    }


    const [sign, setSign] =useState("")
    const [isSign,setIsSign]= useState(false)

    const sendAuthSMS =(e)=>{
        e.preventDefault()
        setIsSendAuth(true)
        let data = {
            userId:storeData.userId,
            phone:storeData.phone,
            birth:`${form.year}-${form.month}-${form.day}`,
            phoneSubject:'생년월일 수정 핸드폰 인증'
        }
        modifyBirthSMS(data)
            .then((res)=>{
                alert(res.data)
                setIsAuthNum(true)
            })
            .catch(function (err){
                alert(err.response.data)
                setIsSendAuth(false)
                setIsAuthNum(false)
            })

    }

    const onChangeAuthNum = (e) =>{
        setAuthNum(e.target.value)
        setIsAuthNum(true)
    }


    const [res,setRes] = useState('')


    const onSubmitHandler = (e) => {
        e.preventDefault();
        let data = {
            userId:storeData.userId,
            name:storeData.name,
            phone:storeData.phone,
            birth:`${form.year}-${form.month}-${form.day}`,
            authNum: authNum,
        }
        modifyBirth(data)
            .then(res=>{
                alert('수정 완료')
                Store().setLoginData(res.data)
                window.location.replace('/userInfo')
            })
            .catch(err=>{
                alert(err.response.data)
            })

    }



    return(
        <>
            {sectionType === 'mobile' ? (
                <>
                    <div style={{height: 350, width: '100%'}}>
                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                borderRadius: '1rem',
                            }}
                        >
                            <img alt="No Images" src={Logo} style={{width:270,height:70,paddingRight:30}}/><br/>

                            <Typography component="h4" variant="h0">
                                생년월일 변경
                            </Typography><br/>
                            <div style={{paddingLeft:10}}>
                                <FormControl sx={{marginTop: 0.1, minWidth: 70,paddingRight:3}} variant="standard">
                                    <InputLabel id="demo-simple-select-label">생년</InputLabel>
                                    <Select
                                        disabled={isSendAuth === true}
                                        value={form.year}
                                        onChange={(e)=>
                                            setForm({ ...form, year:e.target.value})}
                                        labelId="demo-simple-select-label"
                                        autoWidth
                                        sx={{height: '31px', width: '80px'}}
                                    >
                                        <MenuItem value="">
                                            <em>생년</em>
                                        </MenuItem>
                                        {years.map((item) => (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                            >
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{marginTop: 0.1, minWidth: 70,paddingRight:3}} variant="standard">
                                    <InputLabel id="demo-simple-select-label">월</InputLabel>
                                    <Select
                                        disabled={isSendAuth === true}
                                        value={form.month}
                                        onChange={(e)=>
                                            setForm({ ...form, month:e.target.value})}
                                        labelId="demo-simple-select-label"
                                        autoWidth
                                        sx={{height: '31px', width: '80px'}}
                                    >
                                        <MenuItem value="">
                                            <em>월</em>
                                        </MenuItem>
                                        {month.map((item) => (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                            >
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{marginTop: 0.1, minWidth: 100}} variant="standard">
                                    <InputLabel id="demo-simple-select-label">일</InputLabel>
                                    <Select
                                        disabled={isSendAuth === true}
                                        value={form.day}
                                        onChange={(e)=>{
                                            setForm({ ...form, day:e.target.value})
                                        }}
                                        labelId="demo-simple-select-label"
                                        autoWidth
                                        sx={{height: '31px', width: '80px'}}
                                    >
                                        <MenuItem value="">
                                            <em>일</em>
                                        </MenuItem>
                                        {days.map((item) => (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                            >
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <br/>

                            <div style={{display:'flex'}}>
                                <TextField
                                    required
                                    defaultValue={storeData.phone}
                                    sx={{width:220, margin:1}}
                                    type="text"
                                    size="small"
                                    name="phone"
                                    label="전화번호"
                                    disabled
                                />

                                <Button
                                    disabled={isSendAuth === true}
                                    onClick={sendAuthSMS}
                                    variant="contained"
                                    type="submit"
                                    size="small"
                                    sx={{
                                        marginTop:1,
                                        width: 88,
                                        height:'40px',
                                        border:3,
                                        "&.MuiButton-root:hover":{
                                            color:'#008DDC',
                                            backgroundColor:'#c7ebff',
                                            borderColor:'#008DDC'
                                        }
                                    }}
                                >
                                    <Typography component="h3" variant="h7">
                                        인증받기
                                    </Typography>
                                </Button>
                            </div>


                            <TextField
                                disabled={isSendAuth === false}
                                value={authNum}
                                required
                                sx={{width:310, margin:1}}
                                id="sign"
                                size="small"
                                name="sign"
                                label="인증번호"
                                onChange={onChangeAuthNum}
                            />
                            <br/>

                            <Button
                                onClick={onSubmitHandler}
                                variant="contained"
                                type="submit"
                                size="medium"
                                fullWidth
                                sx={{
                                    marginTop: 1,
                                    border: 3,
                                    "&.MuiButton-root:hover": {
                                        color: '#008DDC',
                                        backgroundColor: '#c7ebff',
                                        borderColor: '#008DDC'
                                    }
                                }}
                                disabled={isAuthNum === false}
                            >
                                수정하기
                            </Button>
                        </Box>
                    </div>
                </>
            ):(
                <>
                    <div style={{height: 500, width: '100%'}}>
                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                height: '520px',
                                width: '530px',
                                borderRadius: '1rem',
                            }}
                        ><br/>
                            <img alt="No Images" src={Logo} style={{width:350,height:110,paddingRight:30}}/><br/>

                            <Typography component="h4" variant="h0">
                                생년월일 변경
                            </Typography><br/><br/>
                            <div style={{margin:5,paddingLeft:5}}>
                                <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:2}} variant="standard">
                                    <InputLabel id="demo-simple-select-label">생년</InputLabel>
                                    <Select
                                        disabled={isSendAuth === true}
                                        value={form.year}
                                        onChange={(e)=>
                                            setForm({ ...form, year:e.target.value})}
                                        labelId="demo-simple-select-label"
                                        autoWidth
                                        sx={{height: '31px', width: '100px'}}
                                    >
                                        <MenuItem value="">
                                            <em>생년</em>
                                        </MenuItem>
                                        {years.map((item) => (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                            >
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:2}} variant="standard">
                                    <InputLabel id="demo-simple-select-label">월</InputLabel>
                                    <Select
                                        disabled={isSendAuth === true}
                                        value={form.month}
                                        onChange={(e)=>
                                            setForm({ ...form, month:e.target.value})}
                                        labelId="demo-simple-select-label"
                                        autoWidth
                                        sx={{height: '31px', width: '100px'}}
                                    >
                                        <MenuItem value="">
                                            <em>월</em>
                                        </MenuItem>
                                        {month.map((item) => (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                            >
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:2}} variant="standard">
                                    <InputLabel id="demo-simple-select-label">일</InputLabel>
                                    <Select
                                        disabled={isSendAuth === true}
                                        value={form.day}
                                        onChange={(e)=>{
                                            setForm({ ...form, day:e.target.value})
                                        }}
                                        labelId="demo-simple-select-label"
                                        autoWidth
                                        sx={{height: '31px', width: '100px'}}
                                    >
                                        <MenuItem value="">
                                            <em>일</em>
                                        </MenuItem>
                                        {days.map((item) => (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                            >
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div>
                                <TextField
                                    required
                                    defaultValue={storeData.phone}
                                    sx={{width:230, margin:1}}
                                    type="text"
                                    name="phone"
                                    label="전화번호"
                                    disabled
                                />

                                <Button
                                    disabled={isSendAuth === true}
                                    onClick={sendAuthSMS}
                                    variant="contained"
                                    type="submit"
                                    size="medium"
                                    sx={{
                                        marginTop:1,
                                        width: 120,
                                        height:'56px',
                                        border:3,
                                        "&.MuiButton-root:hover":{
                                            color:'#008DDC',
                                            backgroundColor:'#c7ebff',
                                            borderColor:'#008DDC'
                                        }
                                    }}
                                >
                                    <Typography component="h3" variant="h7">
                                        인증받기
                                    </Typography>
                                </Button>
                            </div>


                            <TextField
                                disabled={isSendAuth === false}
                                value={authNum}
                                required
                                sx={{width:350, margin:1}}
                                id="sign"
                                name="sign"
                                label="인증번호"
                                onChange={onChangeAuthNum}
                            />

                            <Button
                                onClick={onSubmitHandler}
                                variant="contained"
                                type="submit"
                                size="medium"
                                fullWidth
                                sx={{
                                    marginTop: 1,
                                    height: '45px',
                                    width:'380px',
                                    border: 3,
                                    "&.MuiButton-root:hover": {
                                        color: '#008DDC',
                                        backgroundColor: '#c7ebff',
                                        borderColor: '#008DDC'
                                    }
                                }}
                                disabled={isAuthNum === false}
                            >
                                수정하기
                            </Button>

                        </Box>
                    </div>
                </>
            )}

        </>
    )
}