import React from 'react'
import '../../../../../styles/modalStyle.css'
import MainComponents from "./main";



export default function FindPwModal(props){

    const {open, close, header, userId, phone } = props;

    return(
        <>
            <div className={open ? 'openModal modal' : 'modal'}>
                {open ? (
                    <>

                        <section style={{marginTop:50}}>
                            <header>
                                {header}
                                <button className="close" onClick={close}>
                                    &times;
                                </button>
                            </header>
                            <main style={{alignItems: "center"}}><MainComponents style={{alignItems: "center"}} userId={userId} phone={phone} /></main>
                            <footer>
                                <button className="close" onClick={close}>
                                    닫기
                                </button>
                            </footer>
                        </section>
                    </>
                ) : null}
            </div>
        </>
    )
}