import '../../../../styles/modalStyle.css'
import React from 'react'
import InfoUpdateModalComponent from "./InfoUpdateModalComponent";




export default function InfoUpdateModal(props){

    const {open, close, header,data,sizeType } = props;

    return(
        <>
            <div className={open ? 'openModal modal' : 'modal'}>
                {open ? (
                    <>
                        {sizeType === 'mobile' ? (
                            <>
                                <sectionRegisterMobile style={{marginTop:50}}>
                                    <header>
                                        {header}
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                    </header>
                                    <main style={{alignItems: "center"}}><InfoUpdateModalComponent style={{alignItems: "center"}} data={data}
                                                                                                   sizeType="mobile" /></main>
                                    <footer>
                                        <button className="close" onClick={close}>
                                            닫기
                                        </button>
                                    </footer>
                                </sectionRegisterMobile>
                            </>
                        ):(
                            <>
                                <section style={{marginTop:50}}>
                                    <header>
                                        {header}
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                    </header>
                                    <main style={{alignItems: "center"}}><InfoUpdateModalComponent style={{alignItems: "center"}} data={data}/></main>
                                    <footer>
                                        <button className="close" onClick={close}>
                                            닫기
                                        </button>
                                    </footer>
                                </section>
                            </>
                        )}

                    </>
                ) : null}
            </div>
        </>
    )

}