import React, {useContext, useEffect, useState} from "react";
import { authInfoTokens, loginCheck, tokenDelete} from "../../../api";
import Background from "../../../picture/back.jpeg";
import Logo from "../../../picture/Logo.png";
import {Box, Button, TextField, Typography} from "@mui/material";
import Store from "../../../context/store";
import {MainContext} from "../../../context";
import {Desktop, Mobile, Tablet} from "../../../Responsive";
import Login from "../../../pages/Login";
import Layout from "../../../pages/containers/Layout";




export default function Index(){
    const { toggleClose } = useContext(MainContext)

    const [password, setPassword]= useState('');
    const [PwMessage, setPwMessage] = useState("")
    const[isPw,setIsPw] =useState(false)

    let StoreLogin = Store().getLogin() === 'true'

    const onChangePassword = (e) => {
        const currentPw = e.currentTarget.value;
        setPassword(currentPw);
        const passwordRegExp =
            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
        if (!passwordRegExp.test(currentPw)) {
            setPwMessage(
                "숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"
            );
        } else {
            setPwMessage("");
            setIsPw(true)
        }
    };

    const handleSubmit = (e)=>{
        e.preventDefault()
        let sendData ={
            password:password
        }
        authInfoTokens(sendData)
            .then(res=>{
                localStorage.setItem('modifyInfo',true)
                alert('인증성공')
                window.location.replace('/userInfo')
            })
            .catch(err=>{
                alert(err.response.data)
            })

    }

    useEffect(()=>{
        Store().delStorage('modifyInfo')
        loginCheck()
            .then(res=>{
                Store().setLogin(true)
            })
            .catch(err=>{
                Store().setLogin(false)
            })
    },[])


    return(
        <>
            {StoreLogin ? (
                <Layout>
                    <Desktop>
                        <div style={{backgroundImage:`url(${Background})`,
                            backgroundSize:'cover'}} onClick={toggleClose}>
                            <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:280}}>
                                <Box
                                    component="form"
                                    sx={{
                                        marginTop: 18,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        backgroundColor: 'white',
                                        height: '450px',
                                        width: '450px',
                                        borderRadius:'0.5rem',
                                    }}
                                ><br/>
                                    <img alt="No Images" src={Logo} style={{width:320,height:100,paddingRight:30}}/><br/><br/>
                                    <div style={{paddingBottom: 30}}>
                                        <Typography component="h3" variant="h7">
                                            고객정보 수정 전 비밀번호를 입력해주세요.
                                        </Typography>
                                    </div><br/>

                                    <TextField
                                        required
                                        autoFocus
                                        sx={{width:350}}
                                        type="password"
                                        name="password"
                                        label='비밀번호'
                                        value={password}
                                        onChange={onChangePassword}
                                        error={PwMessage==="숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"}
                                    /><br/><br/>


                                    <Button
                                        onClick={handleSubmit}
                                        variant="contained"
                                        type="submit"
                                        size="medium"
                                        sx={{
                                            width:350,
                                            marginTop: 1,
                                            height: '45px',
                                            border: 1,
                                            "&.MuiButton-root:hover": {
                                                color: '#008DDC',
                                                backgroundColor: '#c7ebff',
                                                borderColor: '#008DDC'
                                            }
                                        }}
                                        disabled={ isPw === false }
                                    >
                                        확인
                                    </Button>

                                </Box>
                            </div>
                        </div>
                    </Desktop>
                    <Tablet>
                        <div style={{backgroundImage:`url(${Background})`,
                            backgroundSize:'contain'}} onClick={toggleClose}>
                            <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingTop:100,paddingBottom:400}}>
                                <Box
                                    component="form"
                                    sx={{
                                        marginTop: 8,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        backgroundColor: 'white',
                                        height: '450px',
                                        width: '450px',
                                        borderRadius:'0.5rem',
                                    }}
                                ><br/>
                                    <img alt="No Images" src={Logo} style={{width:320,height:100,paddingRight:30}}/><br/><br/>
                                    <div style={{paddingBottom: 30}}>
                                        <Typography component="h3" variant="h7">
                                            고객정보 수정 전 비밀번호를 입력해주세요.
                                        </Typography>
                                    </div><br/>

                                    <TextField
                                        required
                                        sx={{width:350}}
                                        type="password"
                                        name="password"
                                        label='비밀번호'
                                        value={password}
                                        onChange={onChangePassword}
                                        error={PwMessage==="숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"}
                                    /><br/><br/>


                                    <Button
                                        onClick={handleSubmit}
                                        variant="contained"
                                        type="submit"
                                        size="medium"
                                        sx={{
                                            width:350,
                                            marginTop: 1,
                                            height: '45px',
                                            border: 1,
                                            "&.MuiButton-root:hover": {
                                                color: '#008DDC',
                                                backgroundColor: '#c7ebff',
                                                borderColor: '#008DDC'
                                            }
                                        }}
                                        disabled={ isPw === false }
                                    >
                                        확인
                                    </Button>

                                </Box>
                            </div>
                        </div>
                    </Tablet>
                    <Mobile>
                        <div style={{backgroundImage:`url(${Background})`,
                            backgroundSize:'contain'}} onClick={toggleClose}>
                            <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingTop:50,paddingBottom:280}}>
                                <Box
                                    component="form"
                                    sx={{
                                        marginTop: 8,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        backgroundColor: 'white',
                                        width: '350px',
                                        borderRadius:'0.5rem',
                                    }}
                                ><br/>
                                    <img alt="No Images" src={Logo} style={{width:270,height:80,paddingRight:30}}/><br/><br/>
                                    <div style={{paddingBottom: 30}}>
                                        <Typography component="h4" variant="h8">
                                            고객정보 수정 전 비밀번호를 입력해주세요.
                                        </Typography>
                                    </div>

                                    <TextField
                                        required
                                        sx={{width:300}}
                                        type="password"
                                        name="password"
                                        label='비밀번호'
                                        value={password}
                                        onChange={onChangePassword}
                                        error={PwMessage==="숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"}
                                    /><br/><br/>


                                    <Button
                                        onClick={handleSubmit}
                                        variant="contained"
                                        type="submit"
                                        size="medium"
                                        sx={{
                                            width:300,
                                            marginTop: 1,
                                            marginBottom:2,
                                            height: '45px',
                                            border: 1,
                                            "&.MuiButton-root:hover": {
                                                color: '#008DDC',
                                                backgroundColor: '#c7ebff',
                                                borderColor: '#008DDC'
                                            }
                                        }}
                                        disabled={ isPw === false }
                                    >
                                        확인
                                    </Button>
                                    <br/>

                                </Box>
                            </div>
                        </div>
                    </Mobile>
                </Layout>
                ):(
                <>
                    <Login />
                </>
                )}
        </>
    )
}