
export default function Store(){

    return {
        setAgree(boolean){
            localStorage.setItem('agree',boolean)
        },
        getAgree(){
            return localStorage.getItem('agree')
        },
        setLogin(boolean){
            localStorage.setItem('login',boolean)
        },
        getLogin(){
            return localStorage.getItem('login')
        },
        setLoginData(data){
            localStorage.setItem('loginData',JSON.stringify(data))
        },
        getLoginData(){
            return JSON.parse(localStorage.getItem('loginData'))
        },
        setFindData(data){
            localStorage.setItem('findData',data)
        },
        getFindData(){
            return localStorage.getItem('findData')
        },
        delStorage(set){
            if(set === 'all'){
                localStorage.clear()
            }else{
                localStorage.removeItem(set)
            }
        }

    }
}