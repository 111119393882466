import React, {useCallback, useEffect, useState} from "react";
import {recordFile} from "../../../../api";
import moment from "moment-timezone";
import {toFormData} from "axios";
import Store from "../../../../context/store";
import {Desktop, Mobile, Tablet} from "../../../../Responsive";
import {Box, Typography} from "@mui/material";
import Spinner from "../../../../picture/spinner.gif";
import Button from "@mui/material/Button";


export default function AudioRecord(props){

    const {setCheck} =props

    const storeData = Store().getLoginData()

    const [stream, setStream] = useState();
    const [media, setMedia] = useState();
    const [onRec, setOnRec] = useState(true);
    const [source, setSource] = useState();
    const [analyser, setAnalyser] = useState();
    const [audioUrl, setAudioUrl] = useState();

    const [startRec,setStartRec] = useState(true)


    const offRecAudio = () => {
        // dataavailable 이벤트로 Blob 데이터에 대한 응답을 받을 수 있음
        media.ondataavailable = function (e) {
            setAudioUrl(e.data);
            setOnRec(true);
            setStartRec(true)
        };

        // 모든 트랙에서 stop()을 호출해 오디오 스트림을 정지
        stream.getAudioTracks().forEach(function (track) {
            track.stop();
        });

        // 미디어 캡처 중지
        media.stop();

        // 메서드가 호출 된 노드 연결 해제
        analyser.disconnect();
        source.disconnect();
        onSubmitAudioFile()
        setCheck(true)
    };

    const onSubmitAudioFile = useCallback(async () => {
        const open = moment().tz('Asia/Seoul')
        const today = open.format(`YYYY-MM-DD.kk:mm:ss`)
        // File 생성자를 사용해 파일로 변환
        const sound = new File([audioUrl], `${today}.wav`,
            {
                lastModified: new Date().getTime(),
                type: "audio"
            });
        const formData = new FormData()
        formData.append('file',sound)
        formData.append('user',JSON.stringify(storeData))
        recordFile(formData)
            .then(res => {
                setStartRec(true)
                setOnRec(true)
            })
            .catch(err => {
                console.log(JSON.stringify(err))
            })
    }, [audioUrl]);

    useEffect(()=>{
        // 음원정보를 담은 노드를 생성하거나 음원을 실행또는 디코딩 시키는 일을 한다
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

        // 자바스크립트를 통해 음원의 진행상태에 직접접근에 사용된다.
        const analyser = audioCtx.createScriptProcessor(0, 1, 1);
        setAnalyser(analyser);

        function makeSound(stream) {
            // 내 컴퓨터의 마이크나 다른 소스를 통해 발생한 오디오 스트림의 정보를 보여준다.
            const source = audioCtx.createMediaStreamSource(stream);
            setSource(source);

            // AudioBufferSourceNode 연결
            source.connect(analyser);
            analyser.connect(audioCtx.destination);
        }

        // 마이크 사용 권한 획득 후 녹음 시작
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorder.start();
            setStream(stream);
            setMedia(mediaRecorder);
            makeSound(stream);
            // 음성 녹음이 시작됐을 때 onRec state값을 false로 변경
            analyser.onaudioprocess = function (e) {
                setOnRec(false);
                setStartRec(false)
            };
        });
    },[])

    return (
        <>
            <Desktop>
                <img alt="No Images" src={Spinner} style={{marginTop:2,width:120,height:120,paddingRight:40}}/>
                <Typography component="h1" variant="h6" sx={{paddingTop:2}}>
                    녹음 중... 문장을 다 읽은 후 음성녹음 종료버튼을 눌러주세요!
                </Typography>
                <Box
                    sx={{
                        backgroundColor:'white',
                        width:850,
                        border:1,
                        borderRadius:'2rem',
                        margin:5,
                        display:'flex',
                        alignItems:'center',
                        flexDirection:'column'
                    }}>
                    <Typography component="h1" variant="h4" sx={{paddingTop:5}}>
                        미운 정 고운 정
                    </Typography>
                    <Typography component="h1" variant="h4" sx={{paddingTop:2}}>
                        물에 빠진 생쥐
                    </Typography>
                    <Typography component="h1" variant="h4" sx={{paddingTop:2}}>
                        조만간 한 번 뵙겠습니다
                    </Typography>
                    <Typography component="h1" variant="h4" sx={{paddingTop:2}}>
                        정거장과 정저장 사이
                    </Typography>
                    <Typography component="h1" variant="h4" sx={{paddingTop:2,paddingBottom:2}}>
                        우리나라의 가을은 참으로 아름답다
                    </Typography>
                    <div>

                        <Button variant="contained" size="large" style={{marginTop:10,marginBottom:20}} onClick={offRecAudio}> 음성녹음 종료  </Button><br/>
                    </div>
                </Box>

            </Desktop>
            <Tablet>
                <img alt="No Images" src={Spinner} style={{marginTop:2,width:120,height:120}}/>
                <Typography component="h1" variant="h6" sx={{paddingTop:1}}>
                   녹음 중... 문장을 다 읽은 후 음성녹음 종료버튼을 눌러주세요!
                </Typography>
                <Box
                    sx={{
                        backgroundColor:'white',
                        width:560,
                        border:1,
                        borderRadius:'2rem',
                        margin:5,
                        display:'flex',
                        alignItems:'center',
                        flexDirection:'column'
                    }}>
                    <Typography component="h1" variant="h4" sx={{paddingTop:5}}>
                        미운 정 고운 정
                    </Typography>
                    <Typography component="h1" variant="h4" sx={{paddingTop:2}}>
                        물에 빠진 생쥐
                    </Typography>
                    <Typography component="h1" variant="h4" sx={{paddingTop:2}}>
                        조만간 한 번 뵙겠습니다
                    </Typography>
                    <Typography component="h1" variant="h4" sx={{paddingTop:2}}>
                        정거장과 정저장 사이
                    </Typography>
                    <Typography component="h1" variant="h4" sx={{paddingTop:2,paddingBottom:2}}>
                        우리나라의 가을은 참으로 아름답다
                    </Typography>
                    <div>
                        <Button variant="contained" size="large" style={{marginTop:10,marginBottom:20}} onClick={offRecAudio}> 음성녹음 종료  </Button><br/>
                    </div>
                </Box>
            </Tablet>
            <Mobile>
                <img alt="No Images" src={Spinner} style={{marginTop:2,width:120,height:120}}/>
                <Typography component="h4" variant="h8" sx={{paddingTop:1}}>
                    문장을 다 읽은 후 음성녹음 종료버튼을 눌러주세요!
                </Typography>
                <Box
                    sx={{
                        backgroundColor:'white',
                        width:330,
                        border:1,
                        borderRadius:'2rem',
                        margin:5,
                        display:'flex',
                        alignItems:'center',
                        flexDirection:'column'
                    }}>
                    <Typography component="h4" variant="h6" sx={{paddingTop:5}}>
                        미운 정 고운 정
                    </Typography>
                    <Typography component="h4" variant="h6" sx={{paddingTop:2}}>
                        물에 빠진 생쥐
                    </Typography>
                    <Typography component="h4" variant="h6" sx={{paddingTop:2}}>
                        조만간 한 번 뵙겠습니다
                    </Typography>
                    <Typography component="h4" variant="h6" sx={{paddingTop:2}}>
                        정거장과 정저장 사이
                    </Typography>
                    <Typography component="h4" variant="h6" sx={{paddingTop:2,paddingBottom:2}}>
                        우리나라의 가을은 참으로 아름답다
                    </Typography>
                        <Button variant="contained" size="large" style={{marginTop:10,marginBottom:20}} onClick={offRecAudio}> 음성녹음 종료  </Button><br/>
                </Box>
            </Mobile>
        </>
    );
}