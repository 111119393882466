import {useState} from "react";
import {Button, TextField, Typography} from "@mui/material";
import UpdatePhoneModal from "../modals/update/phone/UpdatePhoneModal";
import {Desktop, Mobile, Tablet} from "../../../../Responsive";


export default function PhoneModify(props){

    const {data}=props

    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }

    return(
        <>
            <Desktop>
                <div style={{alignItems:'center',display:'flex', paddingBottom:30}}>

                    <TextField
                        required
                        fullWidth
                        type="text"
                        name="phone"
                        label="핸드폰번호"
                        defaultValue={data.phone}
                        key={data.phone}
                        disabled
                        sx={{width:250, paddingRight:2}}
                    />

                    <Button
                        onClick={openModal}
                        variant="outlined"
                        type="submit"
                        size="medium"
                        sx={{
                            width:'90px',
                            height:'58px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            수정
                        </Typography>
                    </Button>
                    {modalOpen && <UpdatePhoneModal open={modalOpen} close={closeModal} header="핸드폰 번호 변경" data={data} />}

                </div>
            </Desktop>
            <Tablet>
                <div style={{alignItems:'center',display:'flex', paddingBottom:30}}>

                    <TextField
                        required
                        fullWidth
                        type="text"
                        name="phone"
                        label="핸드폰번호"
                        defaultValue={data.phone}
                        key={data.phone}
                        disabled
                        sx={{width:250, paddingRight:2}}
                    />

                    <Button
                        onClick={openModal}
                        variant="outlined"
                        type="submit"
                        size="medium"
                        sx={{
                            width:'90px',
                            height:'58px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            수정
                        </Typography>
                    </Button>
                    {modalOpen && <UpdatePhoneModal open={modalOpen} close={closeModal} header="핸드폰 번호 변경" data={data} />}

                </div>
            </Tablet>
            <Mobile>
                <div style={{alignItems:'center',display:'flex', paddingBottom:30}}>

                    <TextField
                        required
                        fullWidth
                        type="text"
                        name="phone"
                        label="핸드폰번호"
                        defaultValue={data.phone}
                        key={data.phone}
                        disabled
                        sx={{width:220, paddingRight:2}}
                    />

                    <Button
                        onClick={openModal}
                        variant="outlined"
                        type="submit"
                        size="medium"
                        sx={{
                            width:'90px',
                            height:'58px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            수정
                        </Typography>
                    </Button>
                    {modalOpen && <UpdatePhoneModal open={modalOpen} close={closeModal} header="핸드폰 번호 변경"
                                                    data={data} sectionType="mobile" />}

                </div>
            </Mobile>

        </>
    )
}