import React, {useEffect, useState} from 'react'
import Background from "../../../picture/adminLogin.jpeg";
import Logo from "../../../picture/Logo.png";
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import styled from "styled-components";
import {registerSMS, register, termsAgreeCheck} from "../../../api";
import moment from "moment-timezone";
import Store from "../../../context/store";
import {Desktop, Mobile, Tablet} from "../../../Responsive";


const FormHelperTextsRED = styled(FormHelperText)`
  width: 100%;
  padding-left: 55px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`;



export default function Register(){


    const [name, setName] = useState("");
    const [birth, setBirth] = useState('');
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const [PwConfirm, setPwConfirm] = useState("");
    const [phone, setPhone] = useState("");
    const [authNum, setAuthNum] = useState('')

    const [NameMessage, setNameMessage] = useState("")
    const [EmailMessage, setEmailMessage] = useState("")
    const [PwMessage, setPwMessage] = useState("")
    const [PwConfirmMessage, setPwConfirmMessage] =useState("")
    const [PhoneMessage, setPhoneMessage] = useState("")

    const [isName, setIsName] = useState(false)
    const [isEmail, setIsEmail] = useState(false)
    const [isPw, setIsPw] = useState(false)
    const [isPwConfirm, setIsPwConfirm] = useState(false)
    const [isPhone,setIsPhone] = useState(false)
    const [isAuthNum, setIsAuthNum]= useState(false)
    const [isSendAuth, setIsSendAuth]= useState(false)

    const [res,setRes] = useState('')

    const now = new Date();
    const open = moment().tz('Asia/Seoul')
    const formYear = open.format(`YYYY`)

    const [form, setForm] = useState({
        year: formYear,
        month: "01",
        day: "01",
    });


    let years = [];
    for (let y = now.getFullYear(); y >= 1900; y -= 1) {
        years.push(y);
    }

    let month = [];
    for (let m = 1; m <= 12; m += 1) {
        if (m < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 월에 0을 붙혀준다
            month.push("0" + m.toString());
        } else {
            month.push(m.toString());
        }
    }
    let days = [];
    let date = new Date(form.year, form.month, 0).getDate();
    for (let d = 1; d <= date; d += 1) {
        if (d < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 일에 0을 붙혀준다
            days.push("0" + d.toString());
        } else {
            days.push(d.toString());
        }
    }


    const onChangeName = (e) => {
        const currentName = e.currentTarget.value;
        setName(currentName)
        const nameRegExp = /^[가-힣a-zA-Z]+$/;

        if (!nameRegExp.test(currentName) || name.length<1) {
            setNameMessage('이름을 두 글자 이상 입력하세요!');
            setIsName(false);
        } else {
            setNameMessage('');
            setIsName(true);
        }

    }

    const onChangeEmail = (e) => {
        const currentEmail = e.currentTarget.value;
        setUserId(currentEmail)
        const emailRegExp =
            /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;

        if (!emailRegExp.test(currentEmail)) {
            setEmailMessage("이메일의 형식이 올바르지 않습니다!");
            setIsEmail(false);
        } else {
            setEmailMessage("");
            setIsEmail(true);
        }
    };

    const onChangePassword = (e) => {
        const currentPw = e.currentTarget.value;
        setPassword(currentPw);
        const passwordRegExp =
            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
        if (!passwordRegExp.test(currentPw)) {
            setPwMessage(
                "숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"
            );
            setIsPw(false);
        } else {
            setPwMessage("");
            setIsPw(true);
        }
    };
    const onChangePasswordConfirm = (e) => {
        const currentPasswordConfirm = e.currentTarget.value;
        setPwConfirm(currentPasswordConfirm);
        if (password !== currentPasswordConfirm) {
            setPwConfirmMessage("비밀번호가 일치하지 않습니다.");
            setIsPwConfirm(false);
        } else {
            setPwConfirmMessage("");
            setIsPwConfirm(true);
        }
    };

    const onChangePhone = (getNumber) => {
        const currentPhone = getNumber;
        setPhone(currentPhone);
        const phoneRegExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

        if (!phoneRegExp.test(currentPhone)) {
            setPhoneMessage("핸드폰 번호를 입력해 주세요!");
            setIsPhone(false);
        } else {
            setPhoneMessage("");
            setIsPhone(true);
        }
    };
    const addHyphen = (e) => {
        const currentNumber = e.currentTarget.value;
        setPhone(currentNumber);
        if (currentNumber.length === 3 || currentNumber.length === 8) {
            setPhone(currentNumber + "-");
            onChangePhone(currentNumber + "-");
        } else {
            onChangePhone(currentNumber);
        }
    };

    const sendAuthSMS =(e)=>{
        e.preventDefault()
        setIsSendAuth(true)
        let data = {
            phone:phone,
            phoneSubject:'회원가입 핸드폰 인증'
        }
        registerSMS(data)
            .then((res)=>{
                alert(res.data)
                setIsAuthNum(true)
            })
            .catch(function (err){
                alert(err.response.data)
                setIsAuthNum(false)
            })

    }

    const onChangeAuthNum = (e) =>{
        setAuthNum(e.target.value)
        setIsAuthNum(true)
    }


    const onSubmitHandler = (e)=>{
        e.preventDefault()
        let data = {
            name:name,
            birth:`${form.year}-${form.month}-${form.day}`,
            userId:userId,
            password:password,
            phone:phone,
            authNum:authNum,
            admin:false,
            start_up:false
        }
        const today = open.format('YYYY-MM-DD').split('-').join('')

        if(Number(today) <= Number(data.birth.split('-').join(''))){
            alert('생년월일이 현재 날짜와 동일하거나 높을 수 없습니다. 생년월일을 다시 한번 확인해 주세요.')
        }else{
            register(data)
                .then(res=>{
                    setRes(res.data)
                    alert('회원가입 성공 로그인페이지로 이동됩니다.')
                    window.location.replace('/')
                })
                .catch(function (err){
                    alert(JSON.stringify(err.response.data))
                })
        }


    }




    return(
        <>
            <Desktop>
                    <div style={{backgroundImage:`url(${Background})`,
                        backgroundSize:'cover'}}>
                        <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:140}}>
                            <Box
                                sx={{
                                    marginTop: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    width: '400px',
                                    borderRadius:'0.5rem',
                                    opacity:0.9
                                }}
                            ><br/>
                                <img alt="No Images" src={Logo} style={{width:350,height:110,paddingRight:30}}/>
                                <Typography component="h1" variant="h5" sx={{paddingTop:1}}>
                                    회원가입
                                </Typography><br/>
                                <Button
                                    href='/'
                                    required
                                    fullWidth
                                    size="large"
                                    style={{
                                        backgroundColor: '#1e90ff',
                                        width:350,
                                        mt:2
                                    }}
                                >
                                    <Typography variant='body2' color="primary.contrastText">
                                        Home으로 (로그인페이지)</Typography>
                                </Button><br/>

                                <TextField
                                    autoFocus
                                    onChange={onChangeName}
                                    required
                                    value={name}
                                    sx={{width:350,margin:1}}
                                    id="name"
                                    name="name"
                                    label="이름(성함)"
                                    error={NameMessage === '이름을 두 글자 이상 입력하세요!'}
                                />

                                <FormHelperTextsRED>{NameMessage}</FormHelperTextsRED>

                                <div style={{margin:5,paddingLeft:15}}>
                                    <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:2}} variant="standard">
                                        <InputLabel id="demo-simple-select-label">생년</InputLabel>
                                        <Select
                                            value={form.year}
                                            onChange={(e)=>
                                                setForm({ ...form, year:e.target.value})}
                                            labelId="demo-simple-select-label"
                                            autoWidth
                                            sx={{height: '31px', width: '100px'}}
                                        >
                                            <MenuItem value="">
                                                <em>생년</em>
                                            </MenuItem>
                                            {years.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:2}} variant="standard">
                                        <InputLabel id="demo-simple-select-label">월</InputLabel>
                                        <Select
                                            value={form.month}
                                            onChange={(e)=>
                                                setForm({ ...form, month:e.target.value})}
                                            labelId="demo-simple-select-label"
                                            autoWidth
                                            sx={{height: '31px', width: '100px'}}
                                        >
                                            <MenuItem value="">
                                                <em>월</em>
                                            </MenuItem>
                                            {month.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:2}} variant="standard">
                                        <InputLabel id="demo-simple-select-label">일</InputLabel>
                                        <Select
                                            value={form.day}
                                            onChange={(e)=>{
                                                setForm({ ...form, day:e.target.value})
                                            }}
                                            labelId="demo-simple-select-label"
                                            autoWidth
                                            sx={{height: '31px', width: '100px'}}
                                        >
                                            <MenuItem value="">
                                                <em>일</em>
                                            </MenuItem>
                                            {days.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <FormHelperTextsRED>{NameMessage}</FormHelperTextsRED>

                                <TextField
                                    onChange={onChangeEmail}
                                    required
                                    value={userId}
                                    sx={{width:350,margin:1}}
                                    id="userId"
                                    name="userId"
                                    label="아이디(E-Mail)"
                                    error={EmailMessage==='이메일의 형식이 올바르지 않습니다!'}
                                />

                                <FormHelperTextsRED>{EmailMessage}</FormHelperTextsRED>

                                <TextField
                                    onChange={onChangePassword}
                                    required
                                    sx={{width:350, margin:1}}
                                    type="password"
                                    value={password}
                                    name="password"
                                    label="비밀번호 (숫자+영문자+특수문자 8자리 이상)"
                                    error={PwMessage === '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!'}
                                />

                                <FormHelperTextsRED>{PwMessage}</FormHelperTextsRED>

                                <TextField
                                    required
                                    sx={{width:350, margin:1}}
                                    value={PwConfirm}
                                    onChange={onChangePasswordConfirm}
                                    type="password"
                                    name="PwConfirm"
                                    label="비밀번호 재입력"
                                    error={PwConfirmMessage === '비밀번호가 일치하지 않습니다.'}
                                />

                                <FormHelperTextsRED>{PwConfirmMessage}</FormHelperTextsRED>

                                <div>
                                    <TextField
                                        required
                                        value={phone}
                                        sx={{width:230, margin:1}}
                                        type="text"
                                        onChange={addHyphen}
                                        name="phone"
                                        label="전화번호( - 빼고 기입)"
                                        error={PhoneMessage === '핸드폰 번호를 입력해 주세요!'}
                                    />

                                    <Button
                                        onClick={sendAuthSMS}
                                        variant="outlined"
                                        type="submit"
                                        size="medium"
                                        sx={{
                                            marginTop:1,
                                            width: 120,
                                            height:'56px',
                                            border:3,
                                            "&.MuiButton-root:hover":{
                                                color:'#008DDC',
                                                backgroundColor:'#c7ebff',
                                                borderColor:'#008DDC'
                                            }
                                        }}
                                        disabled={isName === false || isEmail === false || isPw === false || isPwConfirm === false || isPhone === false}
                                    >
                                        <Typography component="h3" variant="h7">
                                            인증받기
                                        </Typography>
                                    </Button>
                                </div>

                                <FormHelperTextsRED>{PhoneMessage}</FormHelperTextsRED>

                                <TextField
                                    disabled={isSendAuth === false}
                                    value={authNum}
                                    required
                                    sx={{width:350, margin:1}}
                                    id="sign"
                                    name="sign"
                                    label="인증번호"
                                    onChange={onChangeAuthNum}
                                />

                                <Button
                                    onClick={onSubmitHandler}
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 2, mb: 1 ,
                                        width:350,
                                        justifyContent: 'center',
                                        alignItems: 'center' }}
                                    size="large"
                                    disabled={ isName === false ||  isEmail === false || isPw === false || isPwConfirm === false ||
                                        isPhone === false || isAuthNum === false || isSendAuth === false }
                                >
                                    회원가입
                                </Button>
                                <br/><br/>
                            </Box>
                        </div>
                    </div>
                {/*):(*/}
                {/*    <>*/}
                {/*        <div style={{backgroundImage:`url(${Background})`,*/}
                {/*            backgroundSize:'cover'}}>*/}
                {/*            <div style={{alignItems:'center',display:'flex',flexDirection:'column',padding:320}}>*/}
                {/*                이용약관 동의 후 사용해주세요.*/}
                {/*                <Button*/}
                {/*                    type="submit"*/}
                {/*                    variant="contained"*/}
                {/*                    sx={{ mt: 2, mb: 1 ,*/}
                {/*                        width:350,*/}
                {/*                        justifyContent: 'center',*/}
                {/*                        alignItems: 'center' }}*/}
                {/*                    size="large"*/}
                {/*                    href='/joinAgree'*/}
                {/*                >*/}
                {/*                    약관동의 페이지로...*/}
                {/*                </Button>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*)}*/}
            </Desktop>
            <Tablet>

                    <div style={{backgroundImage:`url(${Background})`,
                        backgroundSize:'contain'}}>
                        <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:300}}>
                            <Box
                                sx={{
                                    marginTop: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    width: '400px',
                                    borderRadius:'0.5rem',
                                    opacity:0.9
                                }}
                            ><br/>
                                <img alt="No Images" src={Logo} style={{width:350,height:110,paddingRight:30}}/>
                                <Typography component="h1" variant="h5" sx={{paddingTop:1}}>
                                    회원가입
                                </Typography><br/>
                                <Button
                                    href='/'
                                    required
                                    fullWidth
                                    size="large"
                                    style={{
                                        backgroundColor: '#1e90ff',
                                        width:350,
                                        mt:2
                                    }}
                                >
                                    <Typography variant='body2' color="primary.contrastText">
                                        Home으로 (로그인페이지)</Typography>
                                </Button><br/>

                                <TextField
                                    autoFocus
                                    onChange={onChangeName}
                                    required
                                    value={name}
                                    sx={{width:350,margin:1}}
                                    id="name"
                                    name="name"
                                    label="이름(성함)"
                                    error={NameMessage === '이름을 두 글자 이상 입력하세요!'}
                                />

                                <FormHelperTextsRED>{NameMessage}</FormHelperTextsRED>

                                <div style={{margin:5,paddingLeft:15}}>
                                    <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:2}} variant="standard">
                                        <InputLabel id="demo-simple-select-label">생년</InputLabel>
                                        <Select
                                            value={form.year}
                                            onChange={(e)=>
                                                setForm({ ...form, year:e.target.value})}
                                            labelId="demo-simple-select-label"
                                            autoWidth
                                            sx={{height: '31px', width: '100px'}}
                                        >
                                            <MenuItem value="">
                                                <em>생년</em>
                                            </MenuItem>
                                            {years.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:2}} variant="standard">
                                        <InputLabel id="demo-simple-select-label">월</InputLabel>
                                        <Select
                                            value={form.month}
                                            onChange={(e)=>
                                                setForm({ ...form, month:e.target.value})}
                                            labelId="demo-simple-select-label"
                                            autoWidth
                                            sx={{height: '31px', width: '100px'}}
                                        >
                                            <MenuItem value="">
                                                <em>월</em>
                                            </MenuItem>
                                            {month.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:2}} variant="standard">
                                        <InputLabel id="demo-simple-select-label">일</InputLabel>
                                        <Select
                                            value={form.day}
                                            onChange={(e)=>{
                                                setForm({ ...form, day:e.target.value})
                                            }}
                                            labelId="demo-simple-select-label"
                                            autoWidth
                                            sx={{height: '31px', width: '100px'}}
                                        >
                                            <MenuItem value="">
                                                <em>일</em>
                                            </MenuItem>
                                            {days.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <FormHelperTextsRED>{NameMessage}</FormHelperTextsRED>

                                <TextField
                                    onChange={onChangeEmail}
                                    required
                                    value={userId}
                                    sx={{width:350,margin:1}}
                                    id="userId"
                                    name="userId"
                                    label="아이디(E-Mail)"
                                    error={EmailMessage==='이메일의 형식이 올바르지 않습니다!'}
                                />

                                <FormHelperTextsRED>{EmailMessage}</FormHelperTextsRED>

                                <TextField
                                    onChange={onChangePassword}
                                    required
                                    sx={{width:350, margin:1}}
                                    type="password"
                                    value={password}
                                    name="password"
                                    label="비밀번호 (숫자+영문자+특수문자 8자리 이상)"
                                    error={PwMessage === '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!'}
                                />

                                <FormHelperTextsRED>{PwMessage}</FormHelperTextsRED>

                                <TextField
                                    required
                                    sx={{width:350, margin:1}}
                                    value={PwConfirm}
                                    onChange={onChangePasswordConfirm}
                                    type="password"
                                    name="PwConfirm"
                                    label="비밀번호 재입력"
                                    error={PwConfirmMessage === '비밀번호가 일치하지 않습니다.'}
                                />

                                <FormHelperTextsRED>{PwConfirmMessage}</FormHelperTextsRED>

                                <div>
                                    <TextField
                                        required
                                        value={phone}
                                        sx={{width:230, margin:1}}
                                        type="text"
                                        onChange={addHyphen}
                                        name="phone"
                                        label="전화번호( - 빼고 기입)"
                                        error={PhoneMessage === '핸드폰 번호를 입력해 주세요!'}
                                    />

                                    <Button
                                        onClick={sendAuthSMS}
                                        variant="outlined"
                                        type="submit"
                                        size="medium"
                                        sx={{
                                            marginTop:1,
                                            width: 120,
                                            height:'56px',
                                            border:3,
                                            "&.MuiButton-root:hover":{
                                                color:'#008DDC',
                                                backgroundColor:'#c7ebff',
                                                borderColor:'#008DDC'
                                            }
                                        }}
                                        disabled={isName === false || isEmail === false || isPw === false || isPwConfirm === false || isPhone === false}
                                    >
                                        <Typography component="h3" variant="h7">
                                            인증받기
                                        </Typography>
                                    </Button>
                                </div>

                                <FormHelperTextsRED>{PhoneMessage}</FormHelperTextsRED>

                                <TextField
                                    disabled={isSendAuth === false}
                                    value={authNum}
                                    required
                                    sx={{width:350, margin:1}}
                                    id="sign"
                                    name="sign"
                                    label="인증번호"
                                    onChange={onChangeAuthNum}
                                />

                                <Button
                                    onClick={onSubmitHandler}
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 2, mb: 1 ,
                                        width:350,
                                        justifyContent: 'center',
                                        alignItems: 'center' }}
                                    size="large"
                                    disabled={ isName === false ||  isEmail === false || isPw === false || isPwConfirm === false ||
                                        isPhone === false || isAuthNum === false || isSendAuth === false }
                                >
                                    회원가입
                                </Button>
                                <br/><br/>
                            </Box>
                        </div>
                    </div>
                {/*):(*/}
                {/*    <>*/}
                {/*        <div style={{backgroundImage:`url(${Background})`,*/}
                {/*            backgroundSize:'cover'}}>*/}
                {/*            <div style={{alignItems:'center',display:'flex',flexDirection:'column',padding:320}}>*/}
                {/*                이용약관 동의 후 사용해주세요.*/}
                {/*                <Button*/}
                {/*                    type="submit"*/}
                {/*                    variant="contained"*/}
                {/*                    sx={{ mt: 2, mb: 1 ,*/}
                {/*                        width:350,*/}
                {/*                        justifyContent: 'center',*/}
                {/*                        alignItems: 'center' }}*/}
                {/*                    size="large"*/}
                {/*                    href='/joinAgree'*/}
                {/*                >*/}
                {/*                    약관동의 페이지로...*/}
                {/*                </Button>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*)}*/}
            </Tablet>
            <Mobile>

                    <div style={{backgroundImage:`url(${Background})`,
                        backgroundSize:'contain'}}>
                        <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:140}}>
                            <Box
                                sx={{
                                    marginTop: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    width: '350px',
                                    borderRadius:'0.5rem',
                                    opacity:0.9
                                }}
                            ><br/>
                                <img alt="No Images" src={Logo} style={{width:280,paddingRight:30}}/>
                                <Typography component="h1" variant="h5" sx={{paddingTop:1}}>
                                    회원가입
                                </Typography><br/>
                                <Button
                                    href='/'
                                    required
                                    fullWidth
                                    size="small"
                                    style={{
                                        backgroundColor: '#1e90ff',
                                        width:300,
                                        mt:2
                                    }}
                                >
                                    <Typography variant='body2' color="primary.contrastText">
                                        Home으로 (로그인페이지)</Typography>
                                </Button><br/>

                                <TextField
                                    autoFocus
                                    onChange={onChangeName}
                                    required
                                    value={name}
                                    size='small'
                                    sx={{width:300,margin:1}}
                                    id="name"
                                    name="name"
                                    label="이름(성함)"
                                    error={NameMessage === '이름을 두 글자 이상 입력하세요!'}
                                />

                                <FormHelperTextsRED>{NameMessage}</FormHelperTextsRED>

                                <div style={{margin:2,paddingLeft:20}}>
                                    <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:1}} variant="standard">
                                        <InputLabel id="demo-simple-select-label">생년</InputLabel>
                                        <Select
                                            value={form.year}
                                            onChange={(e)=>
                                                setForm({ ...form, year:e.target.value})}
                                            labelId="demo-simple-select-label"
                                            autoWidth
                                            sx={{height: '31px', width: '80px'}}
                                        >
                                            <MenuItem value="">
                                                <em>생년</em>
                                            </MenuItem>
                                            {years.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:1}} variant="standard">
                                        <InputLabel id="demo-simple-select-label">월</InputLabel>
                                        <Select
                                            value={form.month}
                                            onChange={(e)=>
                                                setForm({ ...form, month:e.target.value})}
                                            labelId="demo-simple-select-label"
                                            autoWidth
                                            sx={{height: '31px', width: '80px'}}
                                        >
                                            <MenuItem value="">
                                                <em>월</em>
                                            </MenuItem>
                                            {month.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:1}} variant="standard">
                                        <InputLabel id="demo-simple-select-label">일</InputLabel>
                                        <Select
                                            value={form.day}
                                            onChange={(e)=>{
                                                setForm({ ...form, day:e.target.value})
                                            }}
                                            labelId="demo-simple-select-label"
                                            autoWidth
                                            sx={{height: '31px', width: '80px'}}
                                        >
                                            <MenuItem value="">
                                                <em>일</em>
                                            </MenuItem>
                                            {days.map((item) => (
                                                <MenuItem
                                                    key={item}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <FormHelperTextsRED>{NameMessage}</FormHelperTextsRED>

                                <TextField
                                    onChange={onChangeEmail}
                                    required
                                    value={userId}
                                    size="small"
                                    sx={{width:300,margin:1}}
                                    id="userId"
                                    name="userId"
                                    label="아이디(E-Mail)"
                                    error={EmailMessage==='이메일의 형식이 올바르지 않습니다!'}
                                />

                                <FormHelperTextsRED>{EmailMessage}</FormHelperTextsRED>

                                <TextField
                                    onChange={onChangePassword}
                                    required
                                    size="small"
                                    sx={{width:300,margin:1}}
                                    type="password"
                                    value={password}
                                    name="password"
                                    label="비밀번호 (숫자+영문자+특수문자 8자리 이상)"
                                    error={PwMessage === '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!'}
                                />

                                <FormHelperTextsRED>{PwMessage}</FormHelperTextsRED>

                                <TextField
                                    required
                                    size="small"
                                    sx={{width:300,margin:1}}
                                    value={PwConfirm}
                                    onChange={onChangePasswordConfirm}
                                    type="password"
                                    name="PwConfirm"
                                    label="비밀번호 재입력"
                                    error={PwConfirmMessage === '비밀번호가 일치하지 않습니다.'}
                                />

                                <FormHelperTextsRED>{PwConfirmMessage}</FormHelperTextsRED>

                                <div>
                                    <TextField
                                        required
                                        value={phone}
                                        size="small"
                                        sx={{width:200,margin:1}}
                                        type="text"
                                        onChange={addHyphen}
                                        name="phone"
                                        label="전화번호( - 빼고 기입)"
                                        error={PhoneMessage === '핸드폰 번호를 입력해 주세요!'}
                                    />

                                    <Button
                                        onClick={sendAuthSMS}
                                        variant="outlined"
                                        type="submit"
                                        size="small"
                                        sx={{
                                            marginTop:1,
                                            width: 100,
                                            height:'40px',
                                            border:3,
                                            "&.MuiButton-root:hover":{
                                                color:'#008DDC',
                                                backgroundColor:'#c7ebff',
                                                borderColor:'#008DDC'
                                            }
                                        }}
                                        disabled={isName === false || isEmail === false || isPw === false || isPwConfirm === false || isPhone === false}
                                    >
                                        <Typography component="h3" variant="h7">
                                            인증받기
                                        </Typography>
                                    </Button>
                                </div>

                                <FormHelperTextsRED>{PhoneMessage}</FormHelperTextsRED>

                                <TextField
                                    disabled={isSendAuth === false}
                                    value={authNum}
                                    required
                                    size="small"
                                    sx={{width:300,margin:1}}
                                    id="sign"
                                    name="sign"
                                    label="인증번호"
                                    onChange={onChangeAuthNum}
                                />

                                <Button
                                    onClick={onSubmitHandler}
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 2, mb: 1 ,
                                        width:300,
                                        justifyContent: 'center',
                                        alignItems: 'center' }}
                                    size="small"
                                    disabled={ isName === false ||  isEmail === false || isPw === false || isPwConfirm === false ||
                                        isPhone === false || isAuthNum === false || isSendAuth === false }
                                >
                                    회원가입
                                </Button>
                                <br/><br/>
                            </Box>
                        </div>
                    </div>
                {/*):(*/}
                {/*    <>*/}
                {/*        <div style={{backgroundImage:`url(${Background})`,*/}
                {/*            backgroundSize:'cover'}}>*/}
                {/*            <div style={{alignItems:'center',display:'flex',flexDirection:'column',padding:320}}>*/}
                {/*                이용약관 동의 후 사용해주세요.*/}
                {/*                <Button*/}
                {/*                    type="submit"*/}
                {/*                    variant="contained"*/}
                {/*                    sx={{ mt: 2, mb: 1 ,*/}
                {/*                        width:350,*/}
                {/*                        justifyContent: 'center',*/}
                {/*                        alignItems: 'center' }}*/}
                {/*                    size="large"*/}
                {/*                    href='/joinAgree'*/}
                {/*                >*/}
                {/*                    약관동의 페이지로...*/}
                {/*                </Button>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*)}*/}
            </Mobile>



        </>
    )
}