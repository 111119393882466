import {Button} from "@mui/material";
import React from "react";
import {delAdminUser} from "../../../../api";


export default function AdminUserDel(props){

    const {selectedData} =props


    const delUser = ()=>{
        let nameMap = selectedData.map(e=>e.name)
        if(window.confirm(`${nameMap} 님을 탈퇴처리 하시겠습니까?`)){
            delAdminUser(selectedData)
                .then(res=>{
                    alert(res.data)
                    window.location.replace('/adminPage')
                })
                .catch(err=>{
                    alert(err)
                })
        }else {
            return;
        }

    }


    return(
        <>
            <Button
                variant="contained"
                size="large"
                disabled={selectedData.length < 1}
                onClick={delUser}
                sx={{marginRight:2}}
            >
                탈퇴
            </Button>
        </>
    )
}