import {Button, Typography} from "@mui/material";
import {useState} from "react";
import UpdatePhoneModal from "../modals/update/phone/UpdatePhoneModal";
import DeleteUserModal from "../modals/delete/DeleteUserModal";
import {Desktop, Mobile, Tablet} from "../../../../Responsive";


export default function WithdrawalUser(props){

    const {data}=props

    const [modalOpen, setModalOpen] = useState(false);


    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }



    return(
        <>
            <Desktop>
                <Button
                    onClick={openModal}
                    variant="contained"
                    type="submit"
                    size="medium"
                    sx={{
                        width:'360px',
                        height:'58px',
                        border:0,
                        "&.MuiButton-root:hover":{
                            color:'#008DDC',
                            backgroundColor:'#c7ebff',
                            borderColor:'#008DDC'
                        }
                    }}
                >
                    <Typography component="h3" variant="h7">
                        회원탈퇴
                    </Typography>
                </Button>

                {modalOpen && <DeleteUserModal open={modalOpen} close={closeModal} header="회원 탈퇴" data={data} />}
            </Desktop>
            <Tablet>
                <Button
                    onClick={openModal}
                    variant="contained"
                    type="submit"
                    size="medium"
                    sx={{
                        width:'360px',
                        height:'58px',
                        border:0,
                        "&.MuiButton-root:hover":{
                            color:'#008DDC',
                            backgroundColor:'#c7ebff',
                            borderColor:'#008DDC'
                        }
                    }}
                >
                    <Typography component="h3" variant="h7">
                        회원탈퇴
                    </Typography>
                </Button>

                {modalOpen && <DeleteUserModal open={modalOpen} close={closeModal} header="회원 탈퇴" data={data} />}
            </Tablet>
            <Mobile>
                <Button
                    onClick={openModal}
                    variant="contained"
                    type="submit"
                    size="medium"
                    sx={{
                        width:'330px',
                        height:'58px',
                        border:0,
                        "&.MuiButton-root:hover":{
                            color:'#008DDC',
                            backgroundColor:'#c7ebff',
                            borderColor:'#008DDC'
                        }
                    }}
                >
                    <Typography component="h3" variant="h7">
                        회원탈퇴
                    </Typography>
                </Button>

                {modalOpen && <DeleteUserModal open={modalOpen} close={closeModal} header="회원 탈퇴" data={data} sectionType="mobile" />}
            </Mobile>

        </>
    )
}