import {Typography} from "@mui/material";
import Spinner from "../../../picture/spinner.gif";
import Button from "@mui/material/Button";
import React from "react";
import {Desktop, Mobile, Tablet} from "../../../Responsive";


export default function Loading(props){

    const { checkStroke }=props

    return(
        <>
            <Desktop>
                <Typography component="h1" variant="h4" sx={{paddingTop:5}}>
                    데이터 분석 중...
                </Typography><br/>
                <img alt="No Images" src={Spinner} style={{marginTop:60,width:200,height:200}}/>
                <Button variant="contained" size="large" style={{marginTop:100,marginBottom:70}} onClick={checkStroke}> 분석 완료  </Button><br/>
            </Desktop>
            <Tablet>
                <div style={{display:'flex',alignItems:'center',flexDirection:'column',paddingBottom:30}}>
                <Typography component="h1" variant="h6" sx={{paddingTop:5}}>
                    데이터 분석 중...
                </Typography><br/>
                <img alt="No Images" src={Spinner} style={{marginTop:3,width:150,height:150}}/>
                <Button variant="contained" size="large" style={{marginTop:40}} onClick={checkStroke}> 분석 완료  </Button>
                <br/><br/><br/>
                </div>
            </Tablet>
            <Mobile>
                <Typography component="h1" variant="h6" sx={{paddingTop:5}}>
                    데이터 분석 중...
                </Typography><br/>
                <img alt="No Images" src={Spinner} style={{marginTop:3,width:150,height:150}}/>
                <Button variant="contained" size="large" style={{marginTop:40}} onClick={checkStroke}> 분석 완료  </Button><br/>
            </Mobile>

        </>
    )
}