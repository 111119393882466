
import {createContext, useContext, useEffect, useState} from "react"
import Store from "./store";

export const MainContext = createContext({})



export const MainProvider = ({children}) =>{

    const storeLogin = Store().getLogin() === 'true'
    let [errorCount,setErrorCount] = useState(5)

    const [toggleMenu, setToggleMenu] = useState(false)
    const [toggleBar, setToggleBar] = useState(true)


    const toggleChange = () => {
        setToggleMenu(!toggleMenu)
        setToggleBar(!toggleBar)
    }
    const toggleClose = (e)=>{
        e.stopPropagation()
        if(toggleMenu === true){
            setToggleMenu(false)
            setToggleBar(true)
        }
    }


    return(
        <MainContext.Provider value={{toggleMenu,toggleBar, storeLogin,errorCount,setErrorCount,toggleChange,toggleClose }}>
            {children}
        </ MainContext.Provider>
    )
}