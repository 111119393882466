import React, {useState} from "react";
import {Box, Button, FormHelperText, Grid, TextField, Typography} from "@mui/material";
import Logo from "../../../../../../picture/Logo.png";
import styled from "styled-components";
import {modifyName, modifyNameSMS} from "../../../../../../api";
import {Desktop, Mobile, Tablet} from "../../../../../../Responsive";

const FormHelperTextsRED = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
  display: flex;
  align-items: center;
`;

const FormHelperTextsBLUE = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #0f27d9 !important;
`;

export default function NameModalComponent(props){

    const { data, sectionType }=props

    const [name, setName] = useState('')
    const [NameMessage, setNameMessage] = useState('')


    const onChangeName = (e) => {
        const currentName = e.currentTarget.value;
        setName(currentName)
        const nameRegExp = /^[가-힣a-zA-Z]+$/;

        if (!nameRegExp.test(currentName) || name.length<1) {
            setNameMessage('이름을 두 글자 이상 입력하세요!');
        } else {
            setNameMessage('');
        }
    }


    const [sign, setSign] =useState("")
    const [isSign,setIsSign]= useState(false)

    const sendSMSHandler =(e)=>{
        e.preventDefault()
        let newData = {
            userId:data.userId,
            name:data.name,
            changeName:name,
            phoneNum:data.phone,
            phoneSubject: '고객 이름정보 수정'
        }
        modifyNameSMS(newData)
            .then((res)=>{
                setSign(res.data)
                alert(res.data)
            })
            .catch(function (err){
                alert(err.response.data)
            })
    }

    const [signNum, setSignNum] = useState("");

    const onChangeSignHandler = (e) => {
        setSignNum(e.target.value);
        setIsSign(true)
    }

    const [res,setRes] = useState('')


    const onSubmitHandler = (e) => {
        e.preventDefault();
        let newData = {
            userId: data.userId,
            name: name,
            phone:data.phone,
            authNum: signNum
        }
        modifyName(newData)
            .then((res) => {
                setRes(res.data)
                alert('수정 완료')
                window.location.replace('/userInfo')
            })
            .catch(function (err) {
                alert(err.response.data)
            })

    }



    return(
        <>
            {sectionType === 'mobile' ? (
                <>
                    <div style={{height: 350, width: '100%'}}>
                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                borderRadius: '1rem',
                            }}
                        >
                            <img alt="No Images" src={Logo} style={{width:270,height:70,paddingRight:30}}/>

                            <Typography component="h4" variant="h0">
                                이름 변경
                            </Typography><br/>
                                    <TextField
                                        autoFocus
                                        required
                                        fullWidth
                                        size="small"
                                        type="text"
                                        name="name"
                                        label='기존 이름(성함)'
                                        defaultValue={data.name}
                                        disabled
                                    /><br/>

                                    <TextField
                                        autoFocus
                                        required
                                        fullWidth
                                        size="small"
                                        type="text"
                                        name="name"
                                        label='수정하실 이름을 작성해주세요.'
                                        value={name}
                                        onChange={onChangeName}
                                        error={NameMessage === '이름을 두 글자 이상 입력하세요!' || false}
                                    />


                                    <FormHelperTextsRED>{NameMessage}</FormHelperTextsRED><br/>
                                    <div style={{display:"flex"}}>
                                    <TextField
                                        required
                                        sx={{width:220}}
                                        size="small"
                                        type="text"
                                        name="phone"
                                        label='전화번호("-" 하이픈 자동생성)'
                                        defaultValue={data.phone}
                                        disabled
                                    />

                                    <Button
                                        value={sign}
                                        variant="contained"
                                        type="submit"
                                        size="small"
                                        sx={{
                                            marginLeft:1,
                                            width:'88px',
                                            height:'40px',
                                            border:3,
                                            "&.MuiButton-root:hover":{
                                                color:'#008DDC',
                                                backgroundColor:'#c7ebff',
                                                borderColor:'#008DDC'
                                            }
                                        }}
                                        onClick={sendSMSHandler}
                                        disabled={NameMessage === '이름을 두 글자 이상 입력하세요!'}
                                    >
                                        인증받기
                                    </Button>
                                    </div>
                            <br/>

                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="인증번호"
                                        value={signNum}
                                        type="text"
                                        onChange={onChangeSignHandler}
                                    />


                                    <FormHelperTextsBLUE>{sign}</FormHelperTextsBLUE>
                            <br/>

                                    <Button
                                        onClick={onSubmitHandler}
                                        variant="contained"
                                        type="submit"
                                        size="medium"
                                        fullWidth
                                        sx={{
                                            marginTop: 1,
                                            height: '45px',
                                            border: 3,
                                            "&.MuiButton-root:hover": {
                                                color: '#008DDC',
                                                backgroundColor: '#c7ebff',
                                                borderColor: '#008DDC'
                                            }
                                        }}
                                        disabled={name.length < 1 || isSign === false}
                                    >
                                        수정하기
                                    </Button>


                        </Box>
                    </div>
                </>
            ):(
                <>
                    <div style={{height: 600, width: '100%'}}>
                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                height: '520px',
                                width: '530px',
                                borderRadius: '1rem',
                            }}
                        ><br/>
                            <img alt="No Images" src={Logo} style={{width:350,height:110,paddingRight:30}}/><br/>

                            <Typography component="h4" variant="h0">
                                이름 변경
                            </Typography><br/><br/>
                            <Grid container spacing={1}>
                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <TextField
                                        autoFocus
                                        required
                                        fullWidth
                                        type="text"
                                        name="name"
                                        label='기존 이름(성함)'
                                        defaultValue={data.name}
                                        disabled
                                    /><br/><br/>
                                </Grid>
                                <br/>
                                <Grid item xs={1}/>

                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <TextField
                                        autoFocus
                                        required
                                        fullWidth
                                        type="text"
                                        name="name"
                                        label='수정하실 이름을 작성해주세요.'
                                        value={name}
                                        onChange={onChangeName}
                                        error={NameMessage === '이름을 두 글자 이상 입력하세요!' || false}
                                    />
                                </Grid>
                                <br/>
                                <Grid item xs={1}/>
                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <FormHelperTextsRED>{NameMessage}</FormHelperTextsRED><br/>
                                </Grid>
                                <Grid item xs={1}/>

                                <Grid item xs={1}/>
                                <Grid item xs={7.5}>
                                    <TextField
                                        required
                                        fullWidth
                                        type="text"
                                        name="phone"
                                        label='전화번호("-" 하이픈 자동생성)'
                                        defaultValue={data.phone}
                                        disabled
                                    />
                                </Grid>
                                <br/>
                                <Grid item xs={2}>
                                    <Button
                                        value={sign}
                                        variant="contained"
                                        type="submit"
                                        size="medium"
                                        sx={{
                                            marginTop:0,
                                            width:'105px',
                                            height:'55px',
                                            border:3,
                                            "&.MuiButton-root:hover":{
                                                color:'#008DDC',
                                                backgroundColor:'#c7ebff',
                                                borderColor:'#008DDC'
                                            }
                                        }}
                                        onClick={sendSMSHandler}
                                        disabled={name.length<1}
                                    >
                                        인증받기
                                    </Button><br/><br/>
                                </Grid>
                                <Grid item xs={1.5}/>

                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <TextField
                                        fullWidth
                                        label="인증번호"
                                        value={signNum}
                                        type="text"
                                        onChange={onChangeSignHandler}
                                    />
                                </Grid>
                                <Grid item xs={1}/>
                                <Grid item xs={1}/>
                                <Grid item xs={10}>

                                    <FormHelperTextsBLUE>{sign}</FormHelperTextsBLUE>
                                </Grid>
                                <Grid item xs={1}/>

                                <Grid item xs={1}/>
                                <Grid item xs={10}>
                                    <Button
                                        onClick={onSubmitHandler}
                                        variant="contained"
                                        type="submit"
                                        size="medium"
                                        fullWidth
                                        sx={{
                                            marginTop: 1,
                                            height: '45px',
                                            border: 3,
                                            "&.MuiButton-root:hover": {
                                                color: '#008DDC',
                                                backgroundColor: '#c7ebff',
                                                borderColor: '#008DDC'
                                            }
                                        }}
                                        disabled={name.length < 1 || isSign === false}
                                    >
                                        수정하기
                                    </Button>
                                </Grid>
                            </Grid>

                        </Box>
                    </div>
                </>
            )}

        </>
    )
}