import React, {useContext, useEffect, useState} from 'react'
import styled from 'styled-components'
import { loginCheck, logout} from "../../api";
import Store from "../../context/store";
import {Desktop, Mobile, Tablet} from "../../Responsive";
import {Button} from "antd";
import {MenuFoldOutlined, MenuOutlined} from "@ant-design/icons";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import {MainContext} from "../../context";
import moment from "moment-timezone";


const Common = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: auto;
`

const Home = styled.a`
    display: flex;
    align-items:center;
    margin: 1px;
    text-decoration: none;
    color: black;
    font-weight: bold;
  padding-left: 30px;
  padding-bottom: 10px;
`
const MobileHome = styled.a`
    display: flex;
    align-items:center;
    margin: 1px;
    text-decoration: none;
    color: black;
    font-weight: bold;
  padding-left: 10px;
  padding-bottom: 3px;
`

const LogoImg = styled.img`
    width:270px;
  height: 80px;
  margin-top: 3px;
`
const MobileLogoImg = styled.img`
    width:200px;
  height: 65px;
  margin-top: 5px;
`

const ContlorBox = styled.div`
    display: flex;
    align-items:center;
    padding-right: 10px;
  margin-left: 50px;
`

const Contlor = styled.a`
    margin:10px;
    text-decoration: none;
    color: black;
    padding-right: 20px;
`


const NavTop = styled.div`
  display: flex;
  align-items: unset;
  justify-content: flex-end;
  padding-right: 30px;
  padding-top: -30px;
  button {
    background: #004097;
    border: none;
  }
`;

const styles = {
    width: '100%',
    maxWidth: '180px',
    bgcolor: '#f0f4fa',
    float: 'right',
    position:'sticky',
    zIndex:1
};




export default function Nav(){

    const storeLogin = Store().getLogin() === "true"

    const { toggleMenu,toggleBar, toggleChange } = useContext(MainContext)

    let StoreData

    if(storeLogin === true){
        StoreData = Store().getLoginData()
    }


    useEffect(()=>{
        loginCheck()
            .then(res=>{
                Store().setLogin(true)
            })
            .catch(err=>{
                console.log(err)
            })

    },[])

    useEffect(()=>{
        if(StoreData !== null){
            const timer = setInterval(()=>{
                let expiresDate = moment(StoreData.expiresDate)

                let now = new Date()
                let time = moment().tz('Asia/Seoul')

                const diff = expiresDate - now

                const diffHour = Math.floor(diff / (1000 * 60 * 60));
                const diffMin = Math.floor(diff / (1000 * 60));
                const diffSec = Math.floor(diff / 1000);


                const m = String(diffMin - diffHour * 60).padStart(2, "0");
                const s = String(diffSec - diffMin * 60 + 1).padStart(2, "0");


                let nowTime = moment(time.format('YYYY-MM-DD kk:mm:ss'))
                let durationTime = moment.duration(expiresDate.diff((nowTime))).asSeconds()
                if (durationTime <= 1) {
                    if (m === '00' && s === '01') {
                        logout()
                            .then(res => {
                                Store().setLogin(false)
                                Store().delStorage('all')
                                window.location.replace('/')
                            })
                            .catch(err => {

                            })
                    }else{
                        logout()
                            .then(res => {
                                Store().setLogin(false)
                                Store().delStorage('all')
                                window.location.replace('/')
                            })
                            .catch(err => {

                            })
                    }

                }

            },1000)
            return ()=>{
                clearInterval(timer)
            }
        }else{

        }

    },[])




    const logoutButton = (e)=>{
        e.preventDefault()
        logout()
            .then(res=>{
                alert('로그아웃 되었습니다.')
                Store().setLogin(false)
                Store().delStorage('all')
                window.location.replace('/')
            })
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
        })
    }




    return(
        <>
                <>
                    <Desktop>
                        <Common>
                            <>
                                <Home href='/'>
                                    <LogoImg href='/' src="images/Logo.png"/>
                                </Home>
                                <ContlorBox >
                                    <Contlor href='/customer'>고객 문의</Contlor>
                                    <Contlor href='/product'>뇌졸증 소개</Contlor>
                                    <Contlor href='/analyze'>뇌졸증 분석</Contlor>
                                    <Contlor href='/reports'>레포트</Contlor>
                                    {StoreData.admin === true ? (
                                        <>
                                            <Contlor href='/infoAuth'>고객정보</Contlor>
                                            <Contlor href='/adminPage'>관리자페이지</Contlor>
                                        </>
                                    ):(
                                        <>
                                            <Contlor href='/infoAuth'>고객정보</Contlor>
                                        </>
                                    )}
                                    <Contlor onClick={logoutButton} href='/'>로그아웃</Contlor>
                                </ContlorBox>
                            </>
                        </Common>
                    </Desktop>

                    <Tablet>
                        <Home href='/'>
                            <LogoImg href='/' src="images/Logo.png"/>
                        </Home>
                        <NavTop>
                            <Button type="primary" onClick={toggleChange} style={{ marginBottom: 10, marginTop:-30 }}>
                                { toggleBar ? <MenuOutlined /> : <MenuFoldOutlined /> }
                            </Button>
                        </NavTop>
                        {toggleMenu &&
                            <List sx={styles} component="nav" aria-label="mailbox folders">
                                <ListItemButton components='a' href='/customer'>
                                    <ListItemText primary="고객 문의" />
                                </ListItemButton>
                                <Divider />
                                <ListItemButton components='a' href='/product'>
                                    <ListItemText primary="뇌졸증 소개" />
                                </ListItemButton>
                                <Divider />
                                <ListItemButton components='a' href='/analyze'>
                                    <ListItemText primary="뇌졸증 분석" />
                                </ListItemButton>
                                <Divider light />
                                <ListItemButton components='a' href='/reports'>
                                    <ListItemText primary="레포트" />
                                </ListItemButton>
                                <Divider />
                                {StoreData.admin === true ? (
                                    <>
                                        <ListItemButton components='a' href='/infoAuth'>
                                            <ListItemText primary="고객정보" />
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton components='a' href='/adminPage'>
                                            <ListItemText primary="관리자페이지" />
                                        </ListItemButton>
                                        <Divider />
                                    </>
                                ):(
                                    <>
                                        <ListItemButton components='a' href='/infoAuth'>
                                            <ListItemText primary="정보수정" />
                                        </ListItemButton>
                                        <Divider />
                                    </>
                                )}

                                <ListItemButton onClick={logoutButton} components='a' href='/'>
                                    <ListItemText primary="로그아웃" />
                                </ListItemButton>
                            </List>
                        }
                    </Tablet>
                    <Mobile>
                        <MobileHome href='/'>
                            <MobileLogoImg href='/' src="images/Logo.png"/>
                        </MobileHome>
                        <NavTop>
                            <Button type="primary" onClick={toggleChange} style={{ marginBottom: 10, marginTop:-30 }}>
                                { toggleBar ? <MenuOutlined /> : <MenuFoldOutlined /> }
                            </Button>
                        </NavTop>
                        {toggleMenu &&
                            <List sx={styles} component="nav" aria-label="mailbox folders">
                                <ListItemButton components='a' href='/customer'>
                                    <ListItemText primary="고객 문의" />
                                </ListItemButton>
                                <Divider />
                                <ListItemButton components='a' href='/product'>
                                    <ListItemText primary="뇌졸증 소개" />
                                </ListItemButton>
                                <Divider />
                                <ListItemButton components='a' href='/analyze'>
                                    <ListItemText primary="뇌졸증 분석" />
                                </ListItemButton>
                                <Divider light />
                                <ListItemButton components='a' href='/reports'>
                                    <ListItemText primary="레포트" />
                                </ListItemButton>
                                <Divider />
                                {StoreData.admin === true ? (
                                    <>
                                        <ListItemButton components='a' href='/infoAuth'>
                                            <ListItemText primary="고객정보" />
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton components='a' href='/adminPage'>
                                            <ListItemText primary="관리자페이지" />
                                        </ListItemButton>
                                        <Divider />
                                    </>
                                ):(
                                    <>
                                        <ListItemButton components='a' href='/infoAuth'>
                                            <ListItemText primary="정보수정" />
                                        </ListItemButton>
                                        <Divider />
                                    </>
                                )}

                                <ListItemButton onClick={logoutButton} components='a' href='/'>
                                    <ListItemText primary="로그아웃" />
                                </ListItemButton>
                            </List>
                        }
                    </Mobile>
                </>
        </>
    )
}