import {useState} from "react";
import {Button, TextField, Typography} from "@mui/material";
import UpdatePasswordModal from "../modals/update/password/UpdatePasswordModal";
import {Desktop, Mobile, Tablet} from "../../../../Responsive";




export default function PasswordModify(props){

    const { data }=props

    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }

    return(
        <>
            <Desktop>
                <div style={{alignItems:'center',display:'flex', paddingBottom:20}}>

                    <TextField
                        required
                        fullWidth
                        type="password"
                        name="password"
                        label="비밀번호"
                        defaultValue="*********"
                        disabled
                        sx={{width:250, paddingRight:2}}
                    />

                    <Button
                        onClick={openModal}
                        variant="outlined"
                        type="submit"
                        size="medium"
                        sx={{
                            width:'90px',
                            height:'58px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            수정
                        </Typography>
                    </Button>
                    {modalOpen && <UpdatePasswordModal open={modalOpen} close={closeModal} header="비밀번호 변경" data={data} />}

                </div>
            </Desktop>
            <Tablet>
                <div style={{alignItems:'center',display:'flex', paddingBottom:20}}>

                    <TextField
                        required
                        fullWidth
                        type="password"
                        name="password"
                        label="비밀번호"
                        defaultValue="*********"
                        disabled
                        sx={{width:250, paddingRight:2}}
                    />

                    <Button
                        onClick={openModal}
                        variant="outlined"
                        type="submit"
                        size="medium"
                        sx={{
                            width:'90px',
                            height:'58px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            수정
                        </Typography>
                    </Button>
                    {modalOpen && <UpdatePasswordModal open={modalOpen} close={closeModal} header="비밀번호 변경" data={data} />}

                </div>
            </Tablet>
            <Mobile>
                <div style={{alignItems:'center',display:'flex', paddingBottom:20}}>

                    <TextField
                        required
                        fullWidth
                        type="password"
                        name="password"
                        label="비밀번호"
                        defaultValue="*********"
                        disabled
                        sx={{width:220, paddingRight:2}}
                    />

                    <Button
                        onClick={openModal}
                        variant="outlined"
                        type="submit"
                        size="medium"
                        sx={{
                            width:'90px',
                            height:'58px',
                            border:3,
                            "&.MuiButton-root:hover":{
                                color:'#008DDC',
                                backgroundColor:'#c7ebff',
                                borderColor:'#008DDC'
                            }
                        }}
                    >
                        <Typography component="h3" variant="h7">
                            수정
                        </Typography>
                    </Button>
                    {modalOpen && <UpdatePasswordModal open={modalOpen} close={closeModal} header="비밀번호 변경" data={data}
                                                       sectionType="mobile"/>}

                </div>
            </Mobile>

        </>
    )

}