import React, {useEffect, useState} from "react";
import {Button, FormHelperText, TextField, Typography} from "@mui/material";
import styled from "styled-components";
import {changePwService} from "../../../../../api";
import Store from "../../../../../context/store";

const FormHelperTextsRED = styled(FormHelperText)`
  width: 100%;
  padding-left: 160px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`;

export default function Main(props){

    const {userId, phone} = props

    const [password, setPassword] = useState("");
    const [rePw,setRePw] = useState('')
    const [PwMessage, setPwMessage] = useState("")
    const [isPw, setIsPw] = useState(false)
    const [RePwMessage, setRePwMessage] = useState("")
    const [isRePw, setIsRePw] = useState(false)



    const onChangePassword = (e) => {
        const currentPw = e.currentTarget.value;
        setPassword(currentPw);
        const passwordRegExp =
            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
        if (!passwordRegExp.test(currentPw)) {
            setPwMessage(
                "숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"
            );
            setIsPw(false);
        } else {
            setPwMessage("");
            setIsPw(true);
        }
    };

    const onChangeRePassword = (e) => {
        const currentPw = e.currentTarget.value;
        setRePw(currentPw);
        if (currentPw !== password) {
            setRePwMessage(
                "위와 동일한 비밀번호를 입력해주세요!"
            );
            setIsRePw(false);
        } else {
            setRePwMessage("");
            setIsRePw(true);
        }
    };

    const onSubmitHandler =(e)=>{
        e.preventDefault()
        let data ={
            userId:userId,
            phone:phone,
            password:password
        }
        changePwService(data)
            .then(res=>{
                alert(res.data)
                window.location.replace('/')
            })
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
            })
    }

    return(
        <div style={{display:"flex",alignItems:'center',flexDirection:'column'}}>
            <Typography component="h1" variant="h5" sx={{paddingBottom:2}}>
                비밀번호 변경
            </Typography>
            <TextField
                autoFocus
                error={isPw === false}
                value={password}
                margin="normal"
                required
                sx={{width:360}}
                label="새로운 Password"
                type="password"
                onChange={onChangePassword}
            />
            <FormHelperTextsRED>{PwMessage}</FormHelperTextsRED>

            <TextField
                value={rePw}
                margin="normal"
                error={isRePw === false}
                required
                sx={{width:360}}
                label="Password 재입력"
                type="password"
                onChange={onChangeRePassword}
            />

            <FormHelperTextsRED>{RePwMessage}</FormHelperTextsRED>

            <Button
                disabled={isPw === false || isRePw === false}
                onClick={onSubmitHandler}
                type="submit"
                variant="contained"
                size="large"
                sx={{ mt: 2, mb: 2,
                    width:360,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                비밀번호 변경하기
            </Button>
        </div>
    )
}